.node-agent-page{
   
    &.view-mode-full{


        .group-agent-carousel-wrapper{
            @include wrapper(relative, 100%);
            h2{
                @include wrapper(relative, 100%);
                padding: 0;
                margin: 0 0 $grid-gutter-width 0;
                color: $black;
                text-align: center;
            }
            padding-top: $grid-gutter-width;
            background: linear-gradient(180deg, rgba(196, 196, 196, 0.08) 0%, rgba(196, 196, 196, 0) 79.15%);
        }
    
        .slick{       
            @extend .slick-arrow-style;

            .slick-track{
                display: flex;
            }

            .slick__arrow{
                @include respond-above(tablet){
                    margin-top: -200px;
                }
            }
    
            .slick-slide{
                &.slick-center{                        
                    .slide__media{
                        .media{
                            position: relative;
                            width: 100%;                       
                            img{
                                width: 100%;                     
                                object-position: center center;
                                @include object-fit(contain);
                                //@include respond-above(mobile){
                                    //height: 500px;
                                //}
                             }
                        }                                       
                    }
                }
    
                &:not(.slick-center){
                  .slide__caption{
                    @include respond-above(tablet){
                      opacity: 0;
                    }
                  }  
                  .slide__media{
                    .media{
                        @include respond-above(wide){
                            padding: 0 25%;
                        }
                        @include respond-between(desktop, wide){
                            padding: 0 10%;
                        }
                        padding: 0 $grid-gutter-width/2;
                        
                    }
                  }
                }
    
                .slide__media{
                    .media{
                        @include flex-container(column, wrap, center, center, center);
                        position: relative;
                        width: 100%;
                        height: 300px; 
                        @include respond-above(wide){
                            height: 500px; 
                        }                    
                    }
                    img{
                        @include myanimated;
                        @include object-fit(contain);                    
                        @include respond-above(tablet){
                            width: 80%;
                        }
                        object-position: center top;
                    }
                }
    
    
                .slide__caption{
                    @include myanimated;
                    @include wrapper(relative, 100%);
                    opacity: 1;
                    color: $black;
                    .slide__title{
                        margin-bottom: 20px;
                    }
                }
    
                h2{
                    font-size: 40px;
                    text-align: center;
                }
                .slide__description{
                    text-align: center;
                }
    
                .field-name-field-promote-list{
                    max-width: 80%;
                    @include respond-above(mobile){
                        max-width: 60%;  
                    }
                }
    
                .slide__link{
                    @include flex-container(column, wrap, center, center, center);
                    @include wrapper(relative, 100%);
                    margin: 20px 0;
                    text-align: center;
                    .field-items{
                        position: relative;
                        .field-item{
                            position: relative;
                            text-align: left;
                            &::before{
                                content: "-";
                                position: absolute;
                                left: 0;
                                margin-left: -20px;
                            }
                        }
                    }
                }
    
            }
        }







    }

}