.switch-language-tabs {
  position: fixed !important;
  top: 200px;
  right: 0;
  width: auto;
  z-index: 50;
  text-align: left;

  .koda-block-container{
    position: relative;
    width: 100%;
  }

  .language-tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .language-tab {
    height: 37px;
    width: em(32);
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
    padding-top: 7px;
    margin-bottom: 9px;

    @include respond-above(tablet){
      width: em(40);
    }

    -webkit-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);
    
    &:before {
      margin-left: 8px;
    }

    &:hover{
      width: em(38);
      color: transparent;
      text-decoration: none;
      @include respond-above(tablet){
        width: em(44);
      }
    }

    &.chinese {     
      background: url(../images/icons/icon-chinese.svg) em(8) center no-repeat #DE2910;   
    }

    &.korean {     
      background: url(../images/icons/icon-korean.svg) em(8) center no-repeat #ffffff;     
    }
  }
}