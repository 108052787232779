.view-latest-deal{
    position: relative;
    display: block; 
    @include respond-below(mobile){
        padding-left: 0px;
        padding-right: 0px;   
    }
     //:--------------------------------------------------------------------------------------------------------  
     .view-content{
        @include wrapper(relative, 100%);

        .owl-carousel{
            width: 100% !important;
        }

     }
     .slick{
        position: relative;
        display: block;
        width: 100%;

        @extend .slick-arrow-style;
        .slick__arrow{
            @include respond-below(mobile){
                .slick-prev{
                    margin-left: -30px;
                }
                .slick-next{
                    margin-right: -30px;
                }
            }
        }

        
        .slick__slider{
            @include wrapper(relative, 100%);
            display: block;

            .slick-list{
                @include wrapper(relative, 100%);
                display: block;
            }
            .slick-track{
                position: relative;
                
                .slick{
                    position: relative;
                }

                //:--------------------------------------------------------------------------------------------

                .slide{
                    @include myanimated;
                    
                    &.slick-center{
                        @include respond-above(desktop){
                            -webkit-transform: scale(1.25);
                            -moz-transform: scale(1.25);
                            transform: scale(1.25);
                        }

                        .slide__content .node{
                            
                            img{
                                //height: 300px;
                                width: 100% !important;
                                @extend .img-responsive;
                                //@include object-fit(cover);
                            }
                            @include respond-above(desktop){
                                &.node-deal-model.node-teaser{
                                    height: 360px;

                                    .field-name-title{
                                        position: absolute;
                                        left: 0;
                                        bottom:0;
                                        margin-bottom: 40px;
                                    }
                                }
                            }


                        }

                        .field-name-title{
                            @include myanimated;
                            h2 a{
                                font-size: em(14);    
                                background: rgba(36, 36, 36, 0.6);
                                @include myanimated;
                                @include border-radius(54px);                             
                                @include respond-above(wide){
                                    font-size: em(18);
                                }
                                &:hover{
                                    color: $white;
                                    background: rgba(36, 36, 36, 0.9);
                                }
                            }             
                        }
                    }

                    .slide__content .node{
                        img{
                            height: 220px;
                            width: 100%;
                            @include myanimated(400ms);
                            @include object-fit(contain);
                            object-position: center center;
                            @include respond-above(mobile){
                                height: 160px;
                                width: 70% !important;
                            }
                        }
                    }

                  
                }

               

                //:--------------------------------------------------------------------------------------------
                .slide__content{

                    .node-deal-model.node-teaser.view-mode-teaser{
                        //min-height: 400px;
                        min-height: 360px;
                    }

                    .field-name-field-deal-base-image{
                        @include wrapper(relative, 100%);
                        .field-items,
                        .field-item{
                            @include wrapper(relative, 100%);
                        }

                        .field-item{
                            @include flex-container(column, wrap, center, stretch, center);
                        }
                        
                    }

                }
                //:--------------------------------------------------------------------------------------------
            }

        }
     }
    //:--------------------------------------------------------------------------------------------------------

    //: OWL CAROUSEL

    .owl-carousel{
       .owl-wrapper{
            @include flex-container(row, wrap, center, stretch, center);
            height: 100%;
            .owl-item{
                @include flex-container(row, wrap, center, stretch, center);
                height: 100%;
                > div{
                    @include flex-container(row, wrap, flex-start, stretch, flex-start);
                    height: 100%;
                }
            }

       } 
    }

    //: OWL CAROUSEL


    .view-header{
        h3{
            @include wrapper(relative, 100%);
            margin: 0;
            text-align: center;
            font-size: 30px;
            font-weight: normal;
            color: $white;
        }
    }

    
}
