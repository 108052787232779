
.view-six-column-tiles{  
    align-items: stretch;
    height: 100%;       
    @include wrapper(relative, 100%);  
    //:----------------------------------------------------------------------------------
    &.with-gap{
        > .field-items,
        .view-content{

            > .field-item,
            .views-row{
                margin-bottom: 20px;
                box-sizing: border-box;

                @include respond-above(normal){
                    
                    width: calc(16.67% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(6n+1),
                    &.views-row-first{ 
                        margin-left: 0;  
                    }                                     
                }
               
                @include respond-between(tablet, normal){
                    width: calc(33.3% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(3n+1),
                    &.views-row-first{ 
                        margin-left: 0;                                
                    }                                
                }
                
                
            }
            
        }
    }
     //:----------------------------------------------------------------------------------
   
    > .field-items,
    .view-content{

        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, stretch);
        height: 100%;
    
        > .field-item,
        .views-row{
        height: 100%;
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, flex-start, stretch);
            
            @include respond-above(max){
                @include wrapper(relative, 16.66%);
        
                &:first-child,
                &:nth-child(6n+1),
                &.views-row-first{ 
                    padding-left: 0;                                  
                }
                
                &:nth-child(5n),
                &.views-row-last{
                    padding-right: 0;                        
                }
            }

            @include respond-between(desktop, max){
                @include wrapper(relative, 33.3%);

                &:first-child,
                &:nth-child(3n+1),
                &.views-row-first{ 
                    padding-left: 0;                                  
                }
                
                &:nth-child(3n),
                &.views-row-last{
                    padding-right: 0;                        
                }
                
            }

            @include respond-between(tablet, desktop){
                @include wrapper(relative, 50%);

                &:first-child,
                &:nth-child(2n+1),
                &.views-row-first{ 
                    padding-left: 0;                                  
                }
                
                &:nth-child(2n),
                &.views-row-last{
                    padding-right: 0;                        
                }
                
            }


        //:----------------------------------------------------------------------------------------
        }
    }


}
