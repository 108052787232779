
@import './view-car-accessory-popup';
@import './view-accessory';
@import './view-latest-deal';
@import './view-new-cars';
@import './view-compare';
@import './view-blog';
@import './view-mode-build_your_mitsubishi';
@import './view-hero-image-layout';
@import './view-koda-home-slider';
@import './view-home-slider-carousel';
@import './view-nav-menu-pages';
.view{
    @include wrapper(relative, 100%);
}
