.page-user{

    .content-col{
        padding-top: $v-spacing*2; 

        #user-login{
            @include respond-above(tablet){
                width: 50%;
            }
        }

    }

}