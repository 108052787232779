.default-content{
    color: $default-color;

    .field-name-field-title{
        h2{
            @include wrapper(relative, 100%);
            font-size: 40px;
            margin-top: 0;
            color: $default-color;
            @extend .title-line;
            @include respond-above(mobile){
                font-size: 60px;
            }
        }
    }

}