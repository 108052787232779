// Prestige Cars
.prestige-car-refined-search{
    @include wrapper(relative, 100%);
    @include respond-between(mobile, wide){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    @include respond-above(tablet){
      .koda-block-container{
        width: 740px;
        margin: 0 auto;
      }

    }

    .prestige-cars-selection{
      @include wrapper(relative, 100%);
      list-style: none;
      margin: 0;
      padding: 0;

      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0.5rem;
      //:---------------------------------------------------------------
      @include respond-above(tablet){
        gap: 1rem;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        > li.any-type{
            grid-row: 1/3;
        }
      }
      @include respond-below(tablet){
        > li.any-type{
            grid-column: 1/3;
        }
      }
      //:---------------------------------------------------------------
      > li{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 15px;
        background: linear-gradient(119.96deg, #ffffff 0%, #ffffff 102.48%);
        &:hover {background: linear-gradient(119.96deg, #ffffff 0%, #e9e9e9 102.48%);}
      }

      a{
        @include myanimated;
        color: $white;
        span{
          margin-top: -10px; // Make label near the image
          display: block;
          text-align: center;
          padding: 10px 0;
          text-align: center;
          font-size: 1rem;
          font-weight: normal;
          color: #333;
          @include myanimated;
          @include respond-above(mobile){
            margin-top: -20px; // Make label near the image
            font-size: em(14);
            font-weight: bold;
          }
        }

        img{
          @include myanimated;
        }

        &:focus,
        &:active,
        &:hover{        
          span{
            text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
          }
        }
      }
    }

    form{
        @include wrapper(relative, 100%);
        &#koda-lucas-prestige-cars-search-top-page,
        &#koda-lucas-used-cars-search-top-page{
            @include wrapper(relative, 100%);
            > div{
                @include wrapper(relative, 100%);
                @include flex-container(row, wrap);
                @include respond-above(mobile){
                    padding-top: $grid-gutter-width;
                }
                padding-bottom: $grid-gutter-width;
            }
        }

        h3{
            @include wrapper(relative, 100%);
            @include respond-above(mobile){
                margin: 0 0 60px 0;
                text-align: center;
            }
            margin: $grid-gutter-width/2 0;
            text-align: center;
        }
    }

    // Depricated
    #form-block-left{

        #filter-type{
            @include wrapper(relative, 100%);
        }

        .form-group{
            @include wrapper(relative, 100%);

            #edit-type{
                @include wrapper(relative, 100%);
                @include flex-container(row, wrap);
                > .form-item{

                    position: relative;
                    display: flex;
                    //flex: 0 1 calc(50% - 8px);
                    width: calc(50% - 8px);
                    padding-bottom: calc(50% - 8px);
                    margin-left: 8px;
                    margin-bottom: 8px;
                    margin-top: 0;
                    @include myanimated(500ms);

                    @include respond-below(desktop){
                        &:first-child,
                        &:nth-child(2n+1){
                            margin-left: 0;
                        }
                    }

                    @include respond-above(desktop){
                        flex: 0 1 calc(25% - 8px);
                        width: calc(25% - 8px);
                        padding-bottom: calc(25% - 8px);
                        &:first-child,
                        &:nth-child(4n+1){
                            margin-left: 0;
                        }
                    }

                    &.selected{
                        label{
                            background: transparent !important;
                            //border: 6px solid #EB6215 !important;
                            @include myanimated;
                        }
                    }

                    label{
                        @include wrapper(absolute, 100%, 100%);
                        @include flex-container(column, wrap, center, stretch, center);
                        @include myanimated;
                        padding: 8px;
                        text-align: center;
                        font-size: em(14);
                        font-weight: bold;

                        &::before{
                            content: "";
                            background-size: contain !important;
                            position: relative;
                            width: 100%;
                            height: em(90);
                            @include respond-between(desktop, wide){
                                height: 60px;
                            }
                            @include respond-below(mobile){
                                height: em(50);
                            }
                        }

                        input[type=radio]{
                            opacity: 0;
                        }

                    }

                }
            }

        }

    } // // Depricated

     // Depricated
    #form-block-right{

        @include flex-container(row, wrap);
        @include respond-above(tablet){
            padding-left: $grid-gutter-width;
        }

        #filter-keyword,
        #filter-make,
        #filter-model,
        #filter-sort,
        #search-block{
            position: relative;
            width: 100%;
        }

        .form-item{

            @include flex-container(column, wrap, flex-start, center, center);
            @include respond-above(tablet){
                flex-direction: row;
                margin-bottom: em(18);
            }
            @include respond-below(tablet){
                width: 100%;
            }

            label{
                position: relative;
                display: flex;
                flex: 0 0 100px;
                width: auto;
                padding-right: em(10);
                font-size: em(12);
                font-weight: normal;

                @include respond-below(tablet){
                    width: 100%;
                    flex: 1;
                }
            }

            .form-control{
                position: relative;
                display: flex;
                flex: auto;
                max-width: 100%;
                width: 1%;
                font-weight: normal;
                border-radius: 0;
                font-size: em(12);
                background: transparent;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                outline: none;
                box-shadow: none;
                color: $white;

                @include respond-below(tablet){
                    width: 100%;
                    flex: 1;
                    padding-left: 0;
                    padding-right: 0;
                }

                &::-webkit-input-placeholder { /* Edge */
                    color: $white;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $white;
                }

                &::placeholder {
                    color: $white;
                }

                option{
                    color: $black;
                }

            }

            .chosen-container-single{

                padding-right: 0;
                @include respond-below(tablet){
                    padding-left: 0;
                    width: 100%;
                }

                .chosen-single{
                    background: transparent;
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    span{
                        color: $white;
                        background: transparent;
                    }
                }
            }

            .chosen-drop{
                color: $black;
            }

        }

        //.form-item-sort{
        //    width: 100%;
        //}

        .form-item-keyword,
        .form-item-make,
        .form-item-model{
            flex: 0 0 100%;
            width: 100%;
        }

        #edit-year,
        #edit-price{
            width: 50%;
            background: transparent;
            border-color: transparent;
            margin-bottom: 0;
            box-shadow: none;

            @include respond-below(tablet){
                width: 100%;
            }

            &.panel{
                border: none !important;
                border-radius: 0 !important;
            }

            .panel-body{

                border-radius: 0;
                border: none;

                .form-item{
                    label{
                        flex: 0 0 100px;
                        width: auto;
                        @include respond-below(tablet){
                            flex: 1;
                            width: 100%;
                        }
                    }
                    .form-control{
                        flex: 1;
                        width: auto;
                        max-width: 100%;
                        @include respond-below(tablet){
                            flex: 1;
                            width: 100%;
                        }
                    }
                }
            }
        }

        #edit-year{
            label{
                @include respond-above(tablet){
                    text-align: right;
                }
            }
        }

        #search-block{
            display: flex;
            margin-top: em(15);
            margin-bottom: em(40);

            @include respond-below(tablet){
                text-align: center;
                justify-content: center;
                align-items: center;
                align-content: center;
            }

            .btn{
                background-color: $black;
                color: $white;
                font-size: rem(18);
                padding-left: em(20);
                padding-right: em(20);
                @include myanimated;
                @include border-radius(50px);

                &:hover{
                    background-color: $primary-color;
                }
            }
        }

    } // form-block-right
    // Depricated



    #form-block-left,
    #form-block-right{
        @include flex-container(row, wrap);
        position: relative;
        flex: 0 0 100%;
        width: 100%;
        @include respond-above(tablet){
            flex: 0 0 auto;
            width: 50%;
        }
    }


}
