/*------------------------------------*\
  Top Image
\*------------------------------------*/
.region-topimg{
    overflow: hidden;
    background: linear-gradient(0deg, #171717 0%, #4D4D4D 100%);
    .views-element-container{
        .form-group{
            margin-bottom: 0;
        }
    }
    .file .content{
        position: relative;
        &::before{
            content: "";
            background-color: transparent;
            top: 0;
            left: 0;
            z-index: 10;
            @include wrapper(absolute, 100%, 100%);
        }
    }
    section{
        .paragraphs-item-koda-image-desc .par-content .par-inner-layer{
            @media screen and (max-width:320px) {
                width: 100%;
                overflow-x: hidden; // Fix overlapping issue 320 view
            }
        }
    }
}
// topimg
.topimg{
    position: relative;
    min-height: 70px;
    // Special Logo
    &--special-logo{
        position: absolute;
        bottom: 50px;
        right: 0;
        width: 100%;
        z-index: 20;
        padding: 1rem 2rem;
        img{
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }

    @include respond-above(tablet){
        background-image: url('../images/default-topimg-bg.jpg');
        background-position: center 0;
        background-repeat: no-repeat;
        background-size: cover; // 100%
        background-attachment: fill;
        min-height: 111px;
        &--special-logo{
            bottom: 0;
            right: 1rem;
            width: auto;
        }
    }
}
