.contact-tiles{
    @include wrapper(relative, 100%);
    //padding-top: em(40);
    padding-bottom: 0;

    @include respond-between(mobile, wide){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    .par-content{
        @include respond-above(wide){
            padding-left: em(60);
        }
    }

    .koda-header-wrapper{
        @include wrapper(relative, 100%);
        h2.global-title{
            text-align: left;
            color: $black;
            @include respond-above(mobile){
                font-size: em(40);
            }
        }
    }

    .koda-inner-column-paragraphs{
        @include wrapper(relative, 100%);
        margin-bottom: 4px;
        padding-left: 0;
        padding-right: 0;

        .paragraphs-items{
            @include wrapper(relative, 100%);
            align-content: flex-start !important;
            align-items: flex-start !important;
            height: auto !important;
        }

    }

    .paragraphs-item-koda-basic-paragraph{

        .group-paragraph-info{

            @include respond-above(tablet){
                padding-top: em(20);
                padding-bottom: em(20);
            }
        }

        h2{
            font-size: em(15) !important;
            font-weight: 600;
            margin: 0;
            padding: 0;
            line-height: 1.8em;
            letter-spacing: 0.02em;
            word-break: break-word;
        }

        p{
            margin-bottom: 0;
            position: relative;
            line-height: 1.8em;
            font-size: em(15);
            word-break: break-word;
            a{
                display: block;
                width: 100%;
                letter-spacing: 0.02em;
                word-break: break-word;

                &:hover{
                    text-decoration: underline;
                }

                &.phone{
                    color: $text-color;
                }

            }
        }
          
    }

}