//:-------------------------------------------------------------------------------------------------------------------------

.btn-red,
.btn-dark {
  display: inline-flex;
  justify-content: center;
  font-size: em(12);
  font-weight: 600;
  padding: 8px 1.2em;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  @include myanimated;
  @include border-radius(53px);

  @include respond-above(tablet) {
    font-size: em(15);
    padding: 8px $grid-gutter-width/2;
  }

  &:active,
  &:visited {
    color: $white !important;
    text-decoration: none;
    outline: none;
  }
}

.btn-red {
  background-color: $red-0;
  color: $white;
  border: 1px solid rgba(222, 26, 34, 0.3);

  &:hover {
    border-color: $black;
    background-color: $black !important;
    color: $white !important;
    text-decoration: none;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.btn-dark {
  background-color: $gray-8;
  color: $white;

  &:hover {
    background-color: $primary-color;
    color: $white !important;
  }

  &:active,
  &:visited {
    color: $white !important;
    text-decoration: none;
    outline: none;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.enquire-now {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  &::before {
    background: url('../images/icons/icon-mail.svg') center center no-repeat;
    background-size: 75% auto;
  }
}

.enquire-now-w {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  &::before {
    background: url('../images/icons/icon-mail-w.svg') center center no-repeat;
    background-size: 75% auto;
  }
}

.latest-deal {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  &::before {
    background: url('../images/icons/icon-latest-deals.svg') center center
      no-repeat;
    background-size: 75% auto;
  }
}

.latest-deal-w {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  &::before {
    background: url('../images/icons/icon-latest-deals-w.svg') center center
      no-repeat;
    background-size: 75% auto;
  }
}

.start-again {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: $black;
  &::before {
    background: url('../images/icons/icon-restart.png') center center no-repeat;
  }
}

.more-info {
  @include flex-container(column, wrap, center, center, center);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  &::before {
    background: url('../images/icons/icon-more-info.svg') center center
      no-repeat;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.btn-downloadb {
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  width: 150px;
  font-size: 1.6rem;
  @include flex-container(column, wrap, center, stretch, center);

  &::before {
    content: '';
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 15px;
    @include border-radius(100%);
    @include myanimated;
    border: 4px solid rgba(255, 255, 255, 0.3);
    background: url('../images/icons/icon-download-w.svg') center center
      no-repeat;
  }

  &:hover {
    &::before {
      border-color: $white;
    }
    color: $white;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.btn-compare {
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  width: 150px;
  font-size: 1.6rem;
  @include flex-container(column, wrap, center, stretch, center);

  &::before {
    content: '';
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 15px;
    @include border-radius(100%);
    @include myanimated;
    border: 4px solid rgba(255, 255, 255, 0.3);
    background: url('../images/icons/icon-compare-w.svg') center center
      no-repeat;
  }

  &:hover {
    &::before {
      border-color: $white;
    }
    color: $white;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.btn-enquire {
  color: $white;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  width: 250px;
  height: 100%;
  font-size: 22px;
  @include flex-container(column, wrap, center, stretch, center);

  &::before {
    content: '';
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 15px;
    @include border-radius(100%);
    @include myanimated;
    border: 4px solid rgba(255, 255, 255, 0.3);
    background: url('../images/icons/icon-mail-w.svg') center center no-repeat;
  }

  &:hover {
    color: #ffd754 !important;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.btn-build {
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  width: 150px;
  font-size: 1.6rem;
  @include flex-container(column, wrap, center, stretch, center);

  &::before {
    content: '';
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 15px;
    @include border-radius(100%);
    @include myanimated;
    border: 4px solid rgba(255, 255, 255, 0.3);
    background: url('../images/icons/icon-build-w.svg') center center no-repeat;
  }

  &:hover {
    &::before {
      border-color: $white;
    }
    color: $white;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.deal-btns {
  display: flex;
  width: 100%;
  margin: 1.5rem 0 0 0;
  padding: 0;
  list-style: none;
  > li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    > a {
      @include text-shadow;
      justify-content: flex-start;
      width: 120px;
      &::before {
        width: em(60);
        height: em(60);
        background-size: 50% 50%;
        //display: none;
      }
    }
  }

  @include respond-below(desktop) {
    > li {
      flex: 1;

      > a {
        font-size: 14px;
        margin-bottom: em(10);
        width: em(100);

        &::before {
          width: 50px;
          height: 50px;
          background-size: 50% 50%;
        }
      }
    }
  }

  // @include flex-container(row, wrap, center, stretch, center);
  // list-style: none;
  // margin: em(15) 0 0 0;
  // padding: 0;

  // > li {
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;
  //   > a {
  //     @include text-shadow;
  //   }
  // }

  // /** Responsive **/
  // @include respond-above(desktop) {
  //   justify-content: flex-start;
  // }

  // @media screen and (max-width: 359px) {
  //   > li {
  //     width: 100%;
  //   }
  // }

  // @include respond-between(mobile, desktop) {
  //   background-color: red;
  //   > li {
  //     > a {
  //       font-size: 14px;
  //       margin-bottom: em(10);
  //       &::before {
  //         width: 60px;
  //         height: 60px;
  //         background-size: 50% 50%;
  //       }
  //     }
  //   }
  // }

  // @media screen and (max-width: 359px) {
  //   > li {
  //     > a {
  //       font-size: em(18);
  //       margin-bottom: em(10);
  //       width: 100%;
  //       &::before {
  //         width: em(60);
  //         height: em(60);
  //         background-size: 50% 50%;
  //       }
  //     }
  //   }
  // }
}

//:-------------------------------------------------------------------------------------------------------------------------

.red-show-more {
  .show-more-anchor {
    p {
      a {
        background-color: $primary-color;
        border-color: $primary-color;
        span {
          border-color: $primary-color;
          &::after {
            border-color: $primary-color transparent transparent transparent;
            _border-color: $primary-color #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
          }
        }
        &:hover {
          background-color: transparent;
          border-color: #fff;
          span {
            border-color: #fff;
            &::after {
              border-color: #fff transparent transparent transparent;
              _border-color: #fff #000000 #000000 #000000;
              _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            }
          }
        }
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.show-more-anchor {
  @include wrapper(relative, 100%);

  .koda-block-container {
    //@include wrapper(absolute, 100%);
    position: relative;
    width: 100%;
    margin-top: -40px;
    z-index: 50;
    @include respond-above(tablet) {
      margin-top: -70px;
    }
  }

  p {
    @include wrapper(relative, 100%);
    margin: 0;
    text-align: center;

    a {
      @include border-radius(64px);
      position: relative;
      color: transparent;
      font-size: 0px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include respond-above(tablet) {
        color: $white;
        font-size: 16px;
        padding: 6px 20px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      span {
        @include wrapper(absolute, 100%);
        left: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        @include respond-above(tablet) {
          margin-bottom: -16px;
        }

        &::after {
          @include myanimated;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          //border-width: 15px 15px 0 15px;
          line-height: 0;
          border-width: 20px 20px 0 20px;
          @include respond-above(mobile) {
            border-width: 15px 15px 0 15px;
          }
          border-color: rgba(255, 255, 255, 0.5) transparent transparent
            transparent;
          _border-color: rgba(12, 3, 3, 0.5) #000000 #000000 #000000;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
        }
      }

      &:hover {
        border-color: $primary-color;
        background-color: $primary-color;
        span {
          &::after {
            border-color: $primary-color transparent transparent transparent;
            _border-color: $primary-color #000000 #000000 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
          }
        }

        //background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.anchor-link-target-wrapper {
  @include wrapper(absolute, 100%);
  text-align: center;
  top: 0;
  left: 0;
  z-index: 50;
  height: auto;

  .anchor-taget-btn {
    position: relative;
    margin-top: -35px;
    a {
      position: relative;
      width: 100%;
      &::after {
        @include myanimated;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        line-height: 0;
        //border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
        //_border-color: rgba(12, 3, 3, 0.5) #000000 #000000 #000000;

        border-color: rgba(51, 51, 51, 0.5) transparent transparent transparent;
        _border-color: rgba(51, 51, 51, 0.5) #000000 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      }

      &:hover {
        &::after {
          border-color: $primary-color transparent transparent transparent !important;
          _border-color: $primary-color #000000 #000000 #000000 !important;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000') !important;
        }
      }

      &.anchor-grey {
        &::after {
          border-color: rgba(51, 51, 51, 0.5) transparent transparent
            transparent;
          _border-color: rgba(51, 51, 51, 0.5) #000000 #000000 #000000;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
        }
      }
    }
  }
}
