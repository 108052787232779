.block-tb-megamenu{
    @include wrapper(relative, 100%);

    .tb-megamenu-main-menu{
        @include wrapper(relative, 100%);
        .dropdown-menu{
            z-index: 50 !important;
  

        }

        //:---------------------------------------------------------------------
        .tb-megamenu-button{
            display: none !important;
        }
        .nav-collapse.always-show.collapse,
        .nav-collapse.always-show{
            height: auto !important;
            overflow: visible !important;
            display: block !important;
            position: static !important;
            .tb-megamenu-nav{
                display: block !important;

                .tb-megamenu-submenu{
                    @include respond-below(lg){
                        display: none !important;
                    }

                    .mega-dropdown-inner{
                        //padding-left: em(20);
                        //padding-right: em(20);
                    }

                }
            }
        }
        .nav-collapse .nav > li > a {
            @media (max-width: 979px) {
                border-top: 0;
                border-bottom: 0
            }
            background-color: $gray-0 !important;
        }
        .nav-collapse {
            background-color: transparent !important;
        }
        //:---------------------------------------------------------------------
    }

    //:---------------------------------------------------------------------
    //Overwrite
    .tb-megamenu,
    .tb-megamenu .nav li.dropdown.open-dropdown > .dropdown-toggle,
    .tb-megamenu .nav li.dropdown.open > .dropdown-toggle,
    .tb-megamenu .nav li.dropdown.active > .dropdown-toggle,
    .tb-megamenu .nav li.dropdown.open-dropdown.active > .dropdown-toggle,
    .tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle,
    .tb-megamenu .nav > li.dropdown.open-dropdown.active > a:hover,
    .tb-megamenu .nav > li.dropdown.open.active > a:hover{
        background-color: $gray-0;
        color: $primary-color;
    }
    .tb-megamenu .nav > .dropdown > .dropdown-toggle .caret{
        display: none;
    }

    .tb-megamenu .nav > li {
        @include wrapper(relative, 100%);
        
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        &:hover{
            background-color: $gray-0;
        }

        > a{
            
            border: none;
            border-bottom: 1px solid $gray-1;
            font-size: 26px !important;
            font-weight: 600;
            background-color: $gray-0;
            transition: color 0.3s;
            padding-left: 0;
            padding-right: 0;

            &:hover{
                background-color: $gray-0;
                transition: none;
                color: $primary-color;       
            }

            &:link,
            &:visited,
            &:hover,
            &:active{
                border-bottom: 1px solid $gray-1;
            }
        }
    }
    //:---------------------------------------------------------------------

    .tb-megamenu .dropdown-menu{
        position: fixed !important;
        z-index: 999 !important;
    }

    ul.tb-megamenu-nav{
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap);

        > li{
            @include wrapper(relative, 100%);
        }

        >.tb-megamenu-item{
            &.open-dropdown,
            &.open{
                .mega-dropdown-inner{
                    display: block !important;
                    position: fixed !important;
                    float: left;
                    left: 0;
                    top: 0;
                    padding: 0 !important;
                    z-index: 999 !important;
                    //padding: 40px;
                    width: $slidermega-dropdown-inner-width;
                    //width: 100%;
                    height: $slidermega-dropdown-inner-height;
                    background: $slidermega-dropdown-inner;     

                    .slick{
                        @include wrapper(relative, 100%);
                        .slick__slider{
                            @include wrapper(relative, 100%);
                        }
                    }

                }
            }
        }

        .dropdown-menu{
            border-bottom: none;
        }

    }





    .slick__arrow{
        .slick-arrow{
            border-radius: 0;
            width: 53px;
            height: 53px;

            &.slick-prev{
                &::before{
                    content: url('../images/slick-arrow-left.png');
                }
            }

            &.slick-next{
                &::before{
                    content: url('../images/slick-arrow-right.png');
                }
            }

        }
    }



}
