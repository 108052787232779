.book-test-drive {
    @include wrapper(relative, 100%);
    //@extend .m-top-80;
    //@extend .m-bottom-80;
    .panel-body,
    .panel {
        box-shadow: none;
    }
    .webform-client-form {
        @include wrapper(relative, 100%);
        >div {
            @include wrapper(relative, 100%);
            @include flex-container(column, wrap, center, flex-start, center);
            @include respond-above(tablet) {
                flex-direction: row;
                justify-content: flex-end;
            }
        }
        /* remove padding and add margin on recaptcha*/
        .captcha {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: $grid-gutter-width/2;
        }
    }
    &.redform {
        color: $white;
         ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $white;
            font-size: 20px;
            opacity: 1;
            /* Firefox */
        }
         :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $white;
            font-size: 20px;
        }
         ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $white;
            font-size: 20px;
        }
        .resizable-textarea .grippie {
            border: none;
            background: none;
        }
        .block-full {
            >.panel-body {
                @include respond-above(tablet) {
                    @include flex-container(row, wrap, flex-end, stretch);
                    height: 100%;
                }
                .panel-body {
                    height: 100%;
                }
            }
        }
        .chosen-container-single {
            .chosen-single {
                @include border-radius(0);
                box-shadow: none;
                color: $white;
                width: auto !important;
                background: transparent !important;
                div b {
                    background: none;
                }
            }
        }
        //.webform-component-time,
        //.webform-component-date{
        //    background-color: violet;
        //}
        //.form-item-submitted-form-wrapper-left-region-preferred-time-ampm{
        //}
        .webform-container-inline,
        .webform-container-inline div.form-item {
            @include flex-container(row, wrap, flex-start, center, center);
            flex: 1;
        }
        .form-item-submitted-form-wrapper-left-region-preferred-time-ampm,
        .form-item-submitted-left-region-preferred-time-ampm {
            label {
                text-transform: uppercase;
                input {
                    position: relative;
                    margin-right: 8px !important;
                }
            }
        }
        .webform-component--form-wrapper--right-region--car-image {
            border-bottom: none;
            @include respond-above(tablet) {
                min-height: 428px;
            }
        }
        .webform-component-select,
        .webform-component--form-wrapper--right-region--choose-your-car,
        .webform-component--right-region--choose-your-car,
        .webform-component-time,
        .webform-component-date {
            @include flex-container(column, wrap, flex-start, flex-start, center);
            @include respond-above(tablet) {
                flex-direction: row;
                align-items: center;
            }
            .form-group,
            select {
                border: none !important;
                box-shadow: none;
                font-size: 20px;
                color: $white;
                -webkit-appearance: none;
                option {
                    color: $black;
                    font-size: 20px;
                }
            }
            .form-group {
                padding-right: $grid-gutter-width/2;
            }
            .chosen-drop {
                border-radius: 0;
                color: $black;
            }
            .webform-container-inline {
                width: 100%;
                @include flex-container(row, wrap, flex-start, center, center);
                @include respond-above(tablet) {
                    width: auto;
                }
                flex: 1;
                padding-top: $grid-gutter-width/2;
                padding-bottom: $grid-gutter-width/2;
                .form-radios {
                    @include flex-container(row, wrap, flex-start, center, center);
                    @include respond-above(tablet) {
                        width: auto;
                    }
                }
                .form-item {
                    margin: 0;
                    padding: 0;
                }
                >div {
                    @include flex-container(column, wrap, flex-start, center, center);
                    @include respond-above(tablet) {
                        flex-direction: row;
                    }
                    flex: 1;
                    * {
                        font-size: 20px;
                        font-weight: normal;
                        border: none;
                    }
                    .control-label {
                        @include flex-container(row, wrap, center, center, center);
                    }
                }
            }
        }
        .form-item-submitted-form-wrapper-right-region-choose-your-car,
        .webform-component--form-wrapper--right-region--choose-your-car,
        .webform-component--right-region--choose-your-car {
            padding-top: 16px;
            padding-bottom: 16px;
             ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $white;
                font-size: 20px;
                opacity: 1;
                /* Firefox */
            }
             :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $white;
                font-size: 20px;
            }
             ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $white;
                font-size: 20px;
            }
        }
        .webform-component--bottom-region {
            padding: 0;
        }
        .webform-component-markup {
            height: 1px;
            p {
                margin: 0;
            }
        }
        .webform-component--terms-and-conditions {
            border-bottom: none;
            box-shadow: none;
            width: auto !important;
            margin-bottom: 0;
            display: inline-flex;
            border-bottom: none !important;
            .form-checkbox {
                margin-top: 8px;
            }
        }
        .form-actions {
            width: auto !important;
            display: inline-flex;
            margin: 0;
            padding: 0 0 0 $grid-gutter-width/2;
        }
    }
}