// Declare Variables for Color
$white: #fff;
$white-a: rgba(255, 255, 255, 0.2);
$black: #000;
$black-a: rgba(0, 0, 0, 0.7);
$black-a2: rgba(0, 0, 0, 0.5);
$black-a3: rgba(0, 0, 0, 0.3);

$black-2: #272727;
$black-3: #181818;

$gray-0: #202020;
$gray-1: #545454;
$gray-2: #4f4f4f;
$gray-3: #595959;
$gray-4: #5d5d5d;
$gray-5: #b3b3b3;
$gray-6: #898581;
$gray-7: #3a3a3a;
$gray-8: #333333;
$gray-9: #484848;
$gray-10: #9b9b9b;
$gray-11: #575757;
$gray-12: #8d8d8d;

$gray-light-0: #e1e1e1;
$gray-light-1: #eeeeee;
$gray-light-2: #dadada;
$gray-light-3: #c4c4c4;
$gray-light-4: #e8e8e8;
$gray-light-5: #fafafa;
$gray-light-6: #eaeaea;
$gray-light-7: #f1f1f1;
$gray-light-8: #f4f4f4;
$gray-light-9: #d0d0d0;
$gray-light-10: #eaeaea;
$gray-light-11: #d7d7d7;
$gray-light-12: #d9d9d9;

$yellow-0: #ff9900;
$red-0: #de1a22;
$primary-color: $red-0; // temporary

$text-color: $gray-1;
//:----------------------------------------------------------------------
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10%);
//:----------------------------------------------------------------------
$header-color: $gray-0;
$default-color: $gray-0;
//:----------------------------------------------------------------------
// Bootstrap Overwrite
$brand-primary: $primary-color; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$link-color: $brand-primary;
$body-bg: $white;
$heading-color: $black; //$black-3;
//:----------------------------------------------------------------------
$grid-gutter-width: 60px;
//:----------------------------------------------------------------------
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px; //$screen-xs-min
$screen-sm: 768px; //$screen-sm-min
$screen-md: 980px; //$screen-md-min
$screen-lg: 1200px; //$screen-lg-min
$screen-xl: 1480px;
$screen-xl-min: $screen-xl;
$screen-xl-max: 1600px; //insane width

$screen-mobile: 640px;
$screen-tablet: 768px;
$screen-desktop: 1060px; //$screen-desktop: 992px;
$screen-normal: 1200px; //$screen-normal: 1200px;
$screen-wide: $screen-xl;
//:----------------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: $screen-xs,
  md: $screen-sm,
  lg: $screen-md,
  xl: $screen-lg,
  xxl: $screen-xl,
  mobile: $screen-mobile,
  tablet: $screen-tablet,
  desktop: $screen-desktop,
  normal: $screen-normal,
  wide: $screen-wide + $grid-gutter-width,
  max: $screen-xl-max,
);
//:----------------------------------------------------------------------

$font-family-icon-mi: 'Material Icons';
$font-family-icon-fa: 'FontAwesome';
$font-helvetica-neue-ltd: 'Helvetica Neue LT Std';

$font-Proxima-Nova: 'proxima-nova';
$font-OpenSans: 'Open Sans';

$font-Poppins: 'Poppins', sans-serif;
$font-Roboto: 'Roboto', sans-serif;

$font-awesome-pro: 'Font Awesome 6 Pro';
$font-awesome-duotone: 'Font Awesome 6 Duotone';
$font-awesome-brands: 'Font Awesome 6 Brands';

$font-theme-1: $font-Poppins;
$font-theme-2: $font-OpenSans;
$font-theme-3: $font-Roboto;
$font-theme-4: $font-helvetica-neue-ltd;

//:----------------------------------------------------------------------

$font-family-sans-serif: $font-Poppins;
$font-family-base: $font-Poppins;
$font-size-base: 20px;

//:----------------------------------------------------------------------

$headings-font-family: $font-Poppins;

//:----------------------------------------------------------------------
// Products
$horizontal-tabs-bg-color: #000;
$horizontal-tabs-color: #fff;
$horizontal-tabs-color-hover: $brand-primary;
$horizontal-tabs-color-selected: $white;
$horizontal-tabs-bg-color-selected: $brand-primary;
$horizontal-tabs-padding: 10px 8px;
$horizontal-tabs-font-size: 14px;
//:----------------------------------------------------------------------
$horizontal-subtabs-bg-color: $gray-7;
$horizontal-subtabs-color: $black;
$horizontal-subtabs-color-hover: $black;
$horizontal-subtabs-color-selected: $white;
$horizontal-subtabs-bg-color-selected: $gray-4;
$horizontal-subtabs-padding: 10px 8px;
$horizontal-subtabs-font-size: 14px;
//:----------------------------------------------------------------------

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: $white;
$btn-primary-bg: $primary-color;
$btn-primary-border: $primary-color;

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

//:----------------------------------------------------------------------
// Slider
$slider-color: $white;
$slider-img-mobile-height: 560px;
$slider-img-min-height: 560px; //542px;
$slider-img-max-height: 100vh;
$slider-img-medium-height: 778px;
//:----------------------------------------------------------------------
$slidermenu-width: 340px;
$slidermenu-bg-color: $gray-0;
$slidermega-dropdown-inner: linear-gradient(0deg, #171717 0%, #3a3a3a 100%);
$slidermega-dropdown-inner-height: 540px;
$slidermega-dropdown-inner-width: calc(100% - 340px);
//:----------------------------------------------------------------------

$nav-height: 90px;
$nav-min-height: 70px;

//:----------------------------------------------------------------------

$deal-banner-height: 720px;

//:----------------------------------------------------------------------
$mega-menu-bg: $gray-light-1;
$mega-menu-color: $gray-4;
//:----------------------------------------------------------------------

$v-spacing: $grid-gutter-width;
$h-spacing: $grid-gutter-width;

//:----------------------------------------------------------------------
$footer-vertical-spacing: $grid-gutter-width;
$footer-horizontal-spacing: 10px;
$footer-bg-color: $gray-3;
$footer-font-color: $gray-light-2;

//:----------------------------------------------------------------------
// Header
$header-min-height: 100px;
$header-bg: $white;
$header-color: inherit;
$header-border: $gray-light-1;

$logo-width: 183px;
$logo-height: 122px;
$logo-sm-height: 60px;

//:----------------------------------------------------------------------

$footer-min-height: 100px;
$footer-bg: $black;
$footer-color: inherit;
$footer-border: $gray-light-1;

//:----------------------------------------------------------------------
