.tb-megamenu{
    > .nav{
        > li{
            &.dropdown{
                &.open-dropdown,
                &.open{
                    > .dropdown-toggle{
                        background-color: transparent !important;
                    }
                }
            }
        } // li
    } // nav
}

.tb-megamenu .open-dropdown {
    z-index: 1000;
    > .dropdown-menu {
        display: block;
    }
}

.tb-megamenu .touch .dropdown-submenu.open-dropdown > .dropdown-menu {
    display: block;
}
.tb-megamenu.animate .mega.open-dropdown > .mega-dropdown-menu,
.tb-megamenu.animate .mega.animating.open-dropdown > .mega-dropdown-menu {
    opacity: 1;
}
.tb-megamenu .mega.open-dropdown > .mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover > .mega-dropdown-menu {
  display: block;
}


//.slide-menu-overlay{
    //display: none !important;
//}