.image-description{

    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;

    > .koda-paragraph-inner-wrapper{
        > .koda-header-wrapper{
            h2{
                text-align: center;
            }
            @include respond-below(tablet){
                margin-bottom: 30px;
            }
        }
    }

    .two-col-img-text{
     
        padding-top: $grid-gutter-width;

        @include respond-below(tablet){
            padding-top: 0;
        }
        @include respond-above(tablet){            
            padding-bottom: $grid-gutter-width; 
        }
       
        .par-content.group-right{
            .par-inner-layer{

                .field-group-tabs{
                 
                    .field-type-text-long{
                        font-size: 20px;
                        color: $heading-color;
                    }
                }

                .paragraphs-items{
                    background-color: $white;
                    padding: $grid-gutter-width $grid-gutter-width/2;
                    @include respond-above(desktop){
                        padding: $grid-gutter-width 60px;
                    }
        
                    .field-name-field-title{
                        width: 100%;
                        h2{
                            font-size: 1.4em;
                            margin-top: 0;
                            word-wrap: break-word;
                            overflow-wrap: break-word;
                            color: $heading-color !important;

                            @include respond-above(tablet){
                                font-size: 45px;
                            }
                        }
                    }
                    p{
                        font-size: 20px;
                        color: $heading-color !important;
                    }
                    .field-name-field-desc-basic-paragraph{
                        font-size: 20px;
                        color: $heading-color !important;
                    }
                    
                }
            }
        }

        
        //.koda-paragraph-inner-wrapper{
        //}

    }


    // With Slide
    .slick-dots{
        @include respond-below(mobile){
            bottom: -45px;
        }
    }


}


//:-------------------------------------------------------------------------------------------------------------------------

.image-description-sale{

    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;

    .koda-paragraph-inner-wrapper{
        .koda-header-wrapper{
            h2{
                text-align: center;
            }
        }
    }

    .two-col-img-text{

        &:first-child{
            @include respond-below(tablet){
                padding-top: 0;
            }
        }
        padding-top: $grid-gutter-width;

        @include respond-above(tablet){
            padding-bottom: $grid-gutter-width*2; 
        }

        .par-content{
            @include respond-below(tablet){ 
                padding: 0;  
            }  
        }

        .par-content.group-right{
            .par-inner-layer{
                .paragraphs-items{

                    background-color: $white;
                    padding: $grid-gutter-width/2;
        
                    .field-name-field-title{
                        h2{
                            //font-size: 45px;
                            font-size: rem-calc(45);
                            word-wrap: break-word;
                            color: $heading-color !important;;
                        }
                    }
                    p{
                        font-size: 20px;
                        color: $heading-color !important;
                    }
                    
                }
            }
        }


    }

}

//:-------------------------------------------------------------------------------------------------------------------------

.image-description-default{

    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width*2;
    
    @include respond-above(tablet){
        padding-top: 0;
        padding-bottom: 150px;
    }

    >.koda-paragraph-inner-wrapper{
        > .koda-header-wrapper{
            h2{
                text-align: center;
            }
            
        }
    }

    .two-col-img-text{

        .par-content{
            @include respond-below(tablet){ 
                padding-left: 0;
                padding-right: 0;

            }  
        }

        &.text50-img50{
            @include respond-above(tablet){                 
                
                .par-img.group-left{
                    .field-name-field-image-desc-p-image{
                        padding-left: 40px;

                    }
                }
                .par-content.group-right{
                    .koda-paragraph-outer-wrapper{
                        padding-right: 40px;
                    }
                }
            }

        }

        &.img50-text50{
            @include respond-above(tablet){

                .par-img.group-left{
                    .field-name-field-image-desc-p-image{
                        padding-right: 40px;

                    }
                }
                .par-content.group-right{
                    .par-inner-layer{
                        align-content: flex-start;
                    }
                    .koda-paragraph-outer-wrapper{
                        padding-left: 40px;                       
                    }
                }
            }   
        }

        &:first-child{
            @include respond-below(tablet){
                padding-top: 0;
            }
        }
        padding-top: $grid-gutter-width;

        .par-content.group-right{
            @include respond-above(mobile){ 
                padding-top: $grid-gutter-width;
            }
        }

        .paragraphs-items{
            @include wrapper(relative, 100%);
        }
       
        .koda-paragraph-inner-wrapper{
            //padding: $grid-gutter-width 60px;

            .field-name-field-title{
                h2{
                    font-size: 45px;
                }
            }
            p{
                font-size: 20px;
            }
        }

    }

}

//:-------------------------------------------------------------------------------------------------------------------------
