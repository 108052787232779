.node-range {
  &.node-teaser {
    //Teaser View
    position: relative;
    @include flex-container(column, wrap, flex-start, center, center);
    padding-bottom: 120px !important;
    flex: 1;

    /** Responsive **/
    @include respond-between(mobile, desktop) {
      padding: 0 30px;
    }

    .field-name-title {
      @include wrapper(relative, 100%);
      .field-items,
      .field-item {
        @include wrapper(relative, 100%);
      }

      h2 {
        @include wrapper(relative, 100%);
        font-size: 2.5rem;
        text-align: center;
        color: $gray-0 !important;
        margin: 20px 0;

        /** Responsive **/
        @include respond-above(desktop) {
          font-size: 3.2rem;
        }
        @include respond-between(mobile, desktop) {
          font-size: 2rem;
        }
      }
    }

    .field-name-field-car-range-image {
      @include wrapper(relative, 100%);
      .field-items,
      .field-item {
        @include wrapper(relative, 100%);
      }

      .field-item {
        @include flex-container(column, wrap, center, center, center);
        img {
          position: relative;
          margin: 0 auto;
          height: auto;
          width: 100%;

          @include object-fit(contain);
          object-position: center center;

          /** Responsive **/
          @include respond-above(wide) {
            height: em(300);
          }
          @include respond-between(tablet, wide) {
            height: em(200);
          }
        }
      }
    }

    .field-name-node-link {
      @include wrapper(absolute, 100%);
      bottom: 0;
      left: 0;
      margin-bottom: -30px;

      .field-items,
      .field-item {
        @include wrapper(relative, 100%);
      }

      .field-item {
        text-align: center;
        padding-top: em(30);
        padding-bottom: em(30);

        .btn-dark {
          margin: 0 auto;
          text-transform: none;
        }
      }
    }

    .field-collection-container {
      margin: 0;
      width: 100%;
      border-bottom: none;

      .field-collection-view {
        margin: 0;
        padding: 0;
        border-bottom: none;
      }

      .group-left {
        .field-item {
          font-size: em(14);
          color: $black;
          font-weight: 600;
          text-align: right;
          padding-right: 2.5rem;
        }
      }

      .group-right {
        .field-item {
          font-weight: 300;
          font-size: em(14);
          color: $black;
          text-align: left;
          color: #777;
        }
      }

      /** Responsive **/
      @include respond-below(tablet) {
        .field-collection-view {
          .entity-field-collection-item {
            .group-left,
            .group-right {
              width: 100%;
              .field-item {
                text-align: left;
              }
            }
            .group-left {
              .field-name-field-label {
                .field-item {
                  color: #777;
                }
              }
            }
            .group-right {
              .field-name-field-label {
                .field-item {
                  color: #222;
                }
              }
            }
          }
        }
      }
    }
  }

  //:---------------------------------------------------------------------------------------------------------------------
  &.view-mode-range_build_summary {
    //Build Summary
    .field-name-title {
      h2 {
        @include wrapper(relative, 100%);
        margin: 0;
        font-size: 45px;
        font-weight: 600;
        color: $gray-0 !important;
      }
    }

    // Accessories total price
    #accs-total-price {
      color: #000;
      display: none;
      font-size: 1.6rem;
      b {
        font-weight: 600;
        font-size: 2rem;
      }
      .dc {
        font-weight: 500;
      }
      @include respond-below(tablet) {
        text-align: center;
        b {
          display: block;
        }
      }
    }

    // Fallback
    .field-collection-container {
      margin-top: $grid-gutter-width/2;
      color: $gray-0 !important;
      border-bottom: none;

      .field-name-field-general {
        @include wrapper(relative, 100%);
      }

      .field-collection-view {
        @include wrapper(relative, 100%);
        display: flex;
        padding: 0 !important;
        margin: 0;
        border: none;

        &::before {
          content: url('../images/icons/icon-bullet.svg');
          @include flex-container(row, wrap, flex-start, center, center);
          @include wrapper(relative, 25px, 100%);
        }

        .entity-field-collection-item {
          flex: 1;
          @include flex-container(row, wrap);
          .group-right {
            @include respond-below(tablet) {
              flex: 0 0 100%;
              padding-left: 0;
            }
          }
        }
      }
    }

    .field-collection-view {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      .ds-2col > .group-left,
      .ds-2col > .group-right {
        width: auto;
      }
      .ds-2col > .group-left {
        display: flex;
        font-weight: 600;
      }
      .ds-2col > .group-right {
        padding-left: 10px;
        display: flex;
        flex: 1;
      }
    }
    // Fallback
  }
  //:---------------------------------------------------------------------------------------------------------------------
  // Build your mitsubishi
  &.view-mode-build_your_mitsubishi {
    .field {
      &.field-name-field-car-range-image {
        @include respond-above(desktop) {
          height: 400px;
          @include flex-container(column, wrap, center, center, center);
        }
        img {
          @include wrapper(relative, 100%, 300px);
          @include object-fit(contain);
          object-position: center center;
          @include respond-above(desktop) {
            max-height: 400px;
          }
        }
      }
    }
  }

  //:---------------------------------------------------------------------------------------------------------------------
  //Full Page
  &.view-mode-full {
    .field-name-title {
      @extend .fluid-container;
      @include wrapper(relative, 100%);
      margin-bottom: em(40);
      margin-top: em(40);
      @include respond-above(mobile) {
        margin-bottom: em(60);
        margin-top: em(60);
      }

      h1 {
        @include wrapper(relative, 100%);
        font-size: em(35);
        text-align: center;
        @include respond-above(mobile) {
          font-size: 60px;
        }
      }
    }

    .field-name-body {
      @extend .fluid-container;
    }

    .range-price {
      margin-bottom: em(20);
      //margin-bottom: em(30);

      @include respond-above(tablet) {
        padding-left: $grid-gutter-width * 2;
        display: inline-block;
        width: auto;
      }

      .price-wrapper {
        label {
          @include respond-above(tablet) {
            font-size: em(40);
            line-height: 60px;
            margin-top: 6px;
          }
        }
        h3 {
          @include respond-above(tablet) {
            font-size: em(40);
            line-height: 60px;
          }
        }
      }
    }

    .field-name-field-clean-car-notes {
      color: #000;
      margin-bottom: em(20);
      @extend .fluid-container;
    }

    .field-name-build-from-range {
      @extend .fluid-container;

      @include respond-above(tablet) {
        padding-left: $grid-gutter-width * 2;
      }

      .field-item {
        text-align: center;
        width: 100%;
        @include respond-above(tablet) {
          text-align: left;
        }
      }

      .build-from-range-link {
        @extend .btn-dark;
        min-width: 313px;
        justify-content: center;
        @include respond-below(mobile) {
          padding-left: 0 !important;
          padding-right: 0 !important;
          width: 100% !important;
          text-align: center;
          display: block;
        }
      }
    }

    // Tabs
    .field-group-htabs-wrapper {
      .horizontal-tabs {
        .horizontal-tabs-list {
          @include respond-below(mobile) {
            width: 100%;
            margin-bottom: 20px;
            border-bottom: none !important;
          }

          > li {
            @include respond-below(mobile) {
              width: 100%;
              &:hover {
                &.selected a {
                  background-color: $primary-color !important;
                  color: $white !important;
                  border-bottom: none !important;
                  strong {
                    color: inherit;
                  }
                }
              }
              &.selected a {
                background-color: $primary-color !important;
                color: $white !important;
                border-bottom: none !important;
                strong {
                  color: inherit;
                }
              }
              a {
                border-bottom: none !important;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      // Inside the tabs
      .field-collection-container {
        border-bottom: none;
      }

      .field-collection-view {
        padding: 0;
        margin: 0;
        border-bottom: none;
        box-shadow: none;
      }

      .entity-field-collection-item {
        font-size: 18px;
        color: $black;
        margin-bottom: em(15);
        @include flex-container(row, wrap);

        .group-left {
          width: 100%;
          @include respond-above(mobile) {
            @include flex-container(row, wrap);
            flex: 0 0 em(280);
            width: em(280);
          }
          .field-item {
            font-weight: 600;
          }
        }

        .group-right {
          width: 100%;
          @include respond-above(mobile) {
            @include flex-container(row, wrap);
            flex: 1;
            width: auto;
            padding-left: 20px;
          }
        }
      }
    } // End Tabs
  }
  //:---------------------------------------------------------------------------------------------------------------------
  &.view-mode-range_deal_teaser {
    .horizontal-tabs-pane {
      .group-specs-tab {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .horizontal-tabs-list {
          > li {
            > a {
              text-transform: uppercase;
              font-size: 22px;
            }
          }
        }
      }

      &.group-specs {
        .field-collection-container {
          border-bottom: none;

          .field-type-field-collection {
            @include respond-above(wide) {
              margin: 0 auto;
            }
          }

          .field-collection-view {
            margin: 0;
            padding: 0;
            border-bottom: none;
            
            @include respond-above(desktop) {
              padding: 0 0 em(20) 0;
            }

            > .entity-field-collection-item {
              color: $black;

              .group-left {
                font-weight: 600;
              }
              .group-right {
                @include respond-below(tablet) {
                  margin-bottom: 15px;
                }
              }

              .group-left,
              .group-right {
                @include respond-below(tablet) {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
  //:---------------------------------------------------------------------------------------------------------------------
  &.view-mode-range_compare {
    .field-name-title {
      h2 {
        padding-left: em(15);
        padding-right: em(15);
      }
    }
  }
  //:---------------------------------------------------------------------------------------------------------------------
  &.view-mode-build_your_mitsubishi_spec {
    .field-collection-view {
      border-bottom: none;
      padding: 0 0 0.2em 0;
      margin: 0;
    }
    .field-collection-container {
      border-bottom: none;
    }

    .field-group-htabs-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .horizontal-tabs-pane .panel-body {
      padding-left: 0 !important;
      padding-right: 0 !important;

      @include respond-above(wide) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }

      .entity-field-collection-item {
        .field .field-items .field-item {
          @include respond-between(tablet, wide) {
            font-size: rem-calc(40);
          }
          @include respond-below(tablet) {
            font-size: rem-calc(30);
          }
        }

        .group-left {
          .field .field-items .field-item {
            font-weight: 500;
            @include respond-below(tablet) {
              padding-right: 4px;
            }
          }
        }
      }
    }
  }

  //:---------------------------------------------------------------------------------------------------------------------
}
