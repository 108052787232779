.range-block-wrapper {
  @include wrapper(relative, 100%);
  border-top: 1px solid $gray-light-10;
  border-bottom: 1px solid $gray-light-10;
  padding: em(30) 0 em(35) 0;
  background: linear-gradient(180deg, #f8f8f8 0%, #fff 100%);

  .global-title {
    @include wrapper(relative, 100%);
    text-align: center;
    margin: 0 0 $grid-gutter-width 0;
    font-size: 4.2rem;
  }

  .fluid-container {
    @include respond-below(tablet) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slick {
    @extend .slick-arrow-style2;
    .slick__arrow {
      top: 25%;
      .slick-arrow {
        width: 30px;
        /** Responsive **/
        @include respond-above(desktop) {
          &.slick-prev {
            margin-left: -60px;
          }
          &.slick-next {
            margin-right: -60px;
          }
        }
      }
    }

    .slick-center {
      &:not(:first-child) {
        @include respond-above(mobile) {
          border-left: 1px solid $white;
        }
      }
    }

    @extend .slick-dots-gray;
    .slick-dots {
      @include wrapper(relative, 100%);
      li {
        max-width: 0 8px;
        &.slick-active {
          button {
            background-color: $primary-color !important;
          }
        }
        button {
          @include border-radius(100%);
          &::before {
            display: none;
          }
        }
      }
    }

    //IF NOT SLICK
    &.unslick {
      .slide--0 {
        .node-range {
          &.view-mode-teaser,
          &.node-teaser {
            @include respond-above(tablet) {
              margin: 0;
              padding: 0;
            }
            width: 100%;
            height: 100%;

            .field-collection-container,
            .field-name-title {
              @include respond-above(desktop) {
                width: 800px;
              }
            }
          }
        }
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    display: flex !important;
    height: auto;
    align-items: stretch;
  }

  .slide__content {
    display: flex;
  }

  .slick-slider {
    padding: 0;
  }

  .slick--skin--boxed {
    > .slick__slider {
      > .slick-list {
        margin: 0;
      }
    }
  }

  .slide {
    .slide__content {
      .node-range {
        &.view-mode-teaser,
        &.node-teaser {
          @include respond-above(tablet) {
            width: 90%;
            margin: 0 5%;
            padding: 0;
          }
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
