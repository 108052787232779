.enquire-block{
    position: relative;
    width: 100%;
    float: left;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    form.webform-client-form{
        padding: 0 30px 30px 30px !important;
    }
}

.enquire-block-left{   
    position: relative;
    width: 100%;
    float: left;
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    @include respond-above(tablet){
        width: 60%;
    }
}

.enquire-block-right{
   position: relative;
   width: 100%;
   float: left;
   color: $black;
   padding-bottom: 40px;
   margin-bottom: 40px;

   @include respond-above(tablet){
        width: 40%;
        margin-top: 160px;
        padding-bottom: 0;
   }

   .koda-block-container{
        padding: 0 30px;
        @include wrapper(relative, 100%);

        @include respond-above(desktop){
            padding: 0 $grid-gutter-width/2 0 3em;
        }
   }

   h3{
       margin: 0 0 15px 0;
       font-size: 30px;
       color: inherit;
   }

   p{
    color: inherit;
    font-size: 20px;
    color: $gray-9;
    font-weight: normal;
   }

   a{
       &.email{
        color: $gray-9;
        &:hover{
            color: $primary-color;
        } 
       }

       &.tel{
            font-size: 1.5em;
            font-weight: 600;
            font-family: $font-theme-3;
            background: url('../images/icons/icon-phone-black.svg') 0 center no-repeat;
            background-size: auto 100%;
            padding-left: em(25);

            @include respond-above(desktop){
            font-size: 40px;  
            }

            &:hover{
                color: $black;
            }
            //:-----------------------------------------------------------------
            &.prestige, 
            &.grey{
                color:#888888;
                &:hover{
                    color: $black;
                }
            }
            //:-----------------------------------------------------------------
        
       }

   }

}


//:-------------------------------------------------------------------------------------------------------------------------