
@mixin flex-container-wrapper( $flexdirection: row, $flexwrap: wrap, $justifycontent: flex-start, $alignitems: flex-start, $aligncontent: flex-start) {

    margin-right: auto;
    margin-left: auto;
    padding:0 $grid-margin-width-mb;
    box-sizing: border-box;
    @include flex-container($flexdirection, $flexwrap, $justifycontent, $alignitems, $aligncontent); 
}
  
  
  @mixin flex-container($flexdirection: row, $flexwrap: wrap, $justifycontent: flex-start, $alignitems: flex-start, $aligncontent: flex-start){
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  
    -webkit-flex-direction:  $flexdirection;
    -ms-flex-direction:  $flexdirection;
    flex-direction: $flexdirection;
  
    -webkit-flex-wrap: $flexwrap;
    -ms-flex-wrap: $flexwrap;
    flex-wrap: $flexwrap;
  
    -ms-flex-pack: $justifycontent;
    -webkit-justify-content: $justifycontent;
    -moz-box-pack: $justifycontent;
    justify-content: $justifycontent;
  
    -moz-box-align: $alignitems;
    -ms-flex-align: $alignitems;
    -webkit-align-items: $alignitems;
    align-items: $alignitems;
  
    -ms-flex-line-pack: $aligncontent;
    align-content: $aligncontent;
  }
  
  @mixin flex-element($order: 1, $flexgrow: 1, $flexshrink: 1, $flexbasis: auto) {
    -webkit-box-ordinal-group: $order;
    -moz-box-ordinal-group: $order;
    -webkit-order: $order;
    -ms-flex-order: $order;
    order: $order;
  
    -webkit-flex: $flexgrow $flexshrink $flexbasis;
    -ms-flex: $flexgrow $flexshrink $flexbasis;
    flex: $flexgrow $flexshrink $flexbasis;
  }
  
  
  @mixin flex-direction($flexdirection: row){
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  
    -webkit-flex-direction:  $flexdirection;
    -ms-flex-direction:  $flexdirection;
    flex-direction: $flexdirection;
  }
  
  @mixin flex-wrap($flexwrap: wrap){
    -webkit-flex-wrap: $flexwrap;
    -ms-flex-wrap: $flexwrap;
    flex-wrap: $flexwrap;
  }
  
  @mixin flex-justify($justifycontent: flex-start){
    -ms-flex-pack: $justifycontent;
    -webkit-justify-content: $justifycontent;
    -moz-box-pack: $justifycontent;
    justify-content: $justifycontent;
  }
  
  @mixin flex-align-items($alignitems: flex-start){
    -moz-box-align: $alignitems;
    -ms-flex-align: $alignitems;
    -webkit-align-items: $alignitems;
    align-items: $alignitems;
  }
  
  @mixin flex-align-content($aligncontent: flex-start){
    -ms-flex-line-pack: $aligncontent;
    align-content: $aligncontent;
  }

