.compare-cars-specs{

    @extend .koda-tabs-layout-medium; // Use (extend) Koda Tabs Layout
    @include respond-above(mobile){
        padding-top: em(40);
        padding-bottom: em(40);
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    padding-top: $grid-gutter-width/2;

    .field-collection-view{
        border-bottom: 0;
    }

    .koda-tabs-paragraph{
        .tab-content{

            @include wrapper(relative, 100%);

            .tab-pane{
                @include wrapper(relative, 100%);
                @include respond-above(wide){
                    padding-left: $grid-gutter-width*2;
                    padding-right: $grid-gutter-width*2;
                }

                .left,
                .right{

                    @include wrapper(relative, 100%);
                    float: left;
                    color: $black;
                    width: 50%;

                    .field-items,
                    .field-item{
                        color: $black;
                    }

                    .field-collection-view{
                        padding: 0 0 $grid-gutter-width/2 0;
                        //margin: 0 $grid-gutter-width/2;
                        @include respond-below(mobile){
                            margin: 0 $grid-gutter-width/2;
                        }
                    }

                    @include respond-above(mobile){
                        float: left;
                    }

                    .group-left{
                        .field-items .field-item{
                            font-weight: 500;
                            padding-right: 8px;
                        }
                    }


                    .group-left,
                    .group-right{
                        @include respond-below(mobile){
                            width: 100%;
                        }
                        .field-items .field-item{
                            //font-size: em(12);
                            font-size: rem-calc(20);
                            @include respond-above(mobile){
                                font-size: rem-calc(30);
                            }
                        }
                    }

                }

                .left{
                    @include respond-above(desktop){
                        padding-right: 40px;
                    }
                }

                .right{
                    @include respond-above(mobile){
                        width: 50%;
                        float: left;
                        border-left: 1px solid #D9D9D9;
                    }
                    @include respond-above(desktop){
                        padding-left: 40px;
                    }
                }

            }


        }
    }



    .left-range-info,
    .right-range-info{
        width: 50%;
        float: left;
        text-align: center;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        @include respond-below(tablet){
            margin-bottom: $grid-gutter-width;
        }

        .left-price-info,
        .right-price-info{
            color: $black;
            font-size: em(18);
            font-weight: 600;
            text-align: center;
            display: flex;
            flex-direction: column;

            @include respond-between(mobile, desktop){
                font-size: em(30);
            }

            @include respond-above(desktop){
                font-size: em(38);
            }

            &:after{
                //display: none; // Temporary Hide
                content: "+ Delivery costs";
                text-align: center;
                font-size: em(6);
                font-weight: normal;
                @include wrapper(relative, 100%);
            }

        }

        .left-build-info,
        .right-build-info{
            margin-top: em(22);
            .build-from-range-link{
                @extend .btn-dark;
                @include respond-below(desktop){
                    display: block;
                    font-size: em(12);
                    width: 100%;
                    padding-left: em(10);
                    padding-right: em(10);
                }
                @include respond-below(tablet){
                    font-size: em(10) !important;
                }

            }
        }


    }


}
