// Tyre center - Node
.node-type-tyre-center {
  color: #000;

  .block-easy-breadcrumb {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #contentblock {
    > .container-fluid {
      padding-left: 2rem;
      padding-right: 2rem;

      .region-header-content {
        padding-left: 0;
        padding-right: 0;
      }

      @include respond-above(max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .tyre-center__options-navigation {
    border-top: 4px solid rgba(222, 26, 34, 0.2);
    border-bottom: 4px solid rgba(222, 26, 34, 0.2);
  }

  // Print
  @media print {
    zoom: 70%;
    .header,
    .block-easy-breadcrumb,
    .topimg,
    .config,
    .koda-clone-block,
    .custom-header-buttons,
    .field-name-tyre-service-options-nav,
    .bottom-icons,
    .enquire-block-left,
    .enquire-block-right,
    footer {
      display: none;
    }
    .tyre-center__full__image img {
      width: 100px;
    }
    .field-name-field-tyre-brands img {
      margin: 0 auto;
    }
  }
}

// Node
.node-tyre-center {
  &.node-teaser {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .return-link {
    display: flex;
    margin: 4rem 0;
    font-weight: bold;
  }
}

// tyre-center
.tyre-center {
  &__bp_enquire {
    .field-name-paragraph-link-url {
      margin: 0;
      .field-item {
        @extend .header-buttons;
        margin-bottom: 0 !important;
      }
      a {
        width: 100%;
        @extend .enquire-now;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  // Description
  &__bp-description {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 2rem;

    h2,
    h3 {
      font-size: 30px !important;
      margin-top: 0;
      text-align: center;
      color: #000;
    }

    h2 {
      margin-bottom: 3rem;
    }

    p {
      color: #000;
    }

    > .koda-paragraph-inner-wrapper {
      > .koda-inner-column-paragraphs {
        > .paragraphs-items {
          display: flex;
          width: 100%;
        }
      }
    }

    @include respond-above(md) {
      border: none;
      padding-bottom: 0;

      h2,
      h3 {
        text-align: left;
      }
      > .koda-paragraph-inner-wrapper {
        > .koda-inner-column-paragraphs {
          > .paragraphs-items {
            > .column {
              &:first-child {
                flex: 0 0 85%;
              }
              &:nth-child(2) {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }

  &__bp-img-description {
    .koda-inner-column-paragraphs {
      @extend .fluid-container;
    }

    // paragraph tweaks
    .par-inner-layer {
      .paragraphs-items {
        width: 100%;
      }
    }

    &.image-description {
      > .koda-paragraph-inner-wrapper {
        > .koda-header-wrapper {
          margin-bottom: 0;
        }
      }
      .two-col-img-text {
        .par-img {
          .field-items,
          .field-item {
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @include respond-below(wide) {
      &.image-description {
        .two-col-img-text {
          .par-img,
          .par-content {
            padding: 0;
          }
        }
      }
    }
    @include respond-below(md) {
      &.image-description {
        .two-col-img-text {
          .par-content.group-right {
            .par-inner-layer {
              .paragraphs-items {
                padding: 20px;
              }
            }
          }
        }
      }
      .koda-inner-column-paragraphs {
        .two-col-img-text {
          padding-left: 0;
          padding-right: 0;
          .par-content {
            .paragraphs-items {
              p {
                font-size: 1.6rem !important;
              }
            }
          }
        }
      }
    }
  }

  &__bp-options {
    color: $heading-color;
    padding: 6rem 0;

    .koda-inner-column-paragraphs {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    h2.global-title {
      margin-bottom: 4rem;
    }

    @include respond-above(md) {
      .global-title {
        font-size: 85px;
      }
    }
    @include respond-above(max) {
      .koda-inner-column-paragraphs {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // Feautures
  &__bp-features {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;

    @include respond-below(md) {
      border-top: 1px solid #e6e6e6;
      padding: 0;
      margin-top: 2rem;
    }

    .paragraphs-items {
      > .column {
        > .paragraphs-item-koda-basic-paragraph {
          display: flex;
          align-items: center;

          .koda-paragraph-inner-wrapper {
            display: flex;
            justify-content: center;
          }

          .group-paragraph-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > .field-name-field-title {
              order: 1;
              display: flex;
              align-items: center;
              justify-content: center;

              > h2 {
                font-weight: normal;
                color: #000;
                font-size: 18px !important;
                line-height: 1.2;
                margin: 0;
                text-align: center;
              }
            }

            .field-name-field-media {
              order: 0;
              position: relative;
              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 5;
              }
              img {
                height: 150px;
                object-fit: scale-down;
              }
            }

            @include respond-above(xl) {
              flex-direction: row;
              width: 80%;
              > .field-name-field-title {
                > h2 {
                  font-size: 27px !important;
                  text-align: left;
                }
              }
              > .field-name-field-media {
                padding-right: 2rem;
              }
            }
          }
        }
      }
    }
  }

  // Brands
  &__brands {
    .view-tyre-brands {
      width: 100%;
      border-top: 4px solid rgba(222, 26, 34, 0.2);
      border-bottom: 4px solid rgba(222, 26, 34, 0.2);
      .view-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2rem 0;
        margin: 2rem auto;
        width: 80%;
        gap: 20px;
        .views-row {
          width: calc(50% - 20px);
          img {
            margin: 0 auto;
            height: 118px;
            width: auto;
            object-fit: contain;
          }

          @include respond-above(lg) {
            width: calc(33.3% - 20px);
          }
        }
      }
    }
  }

  // Teaser
  &__teaser {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 2rem;
    border: 1px solid #e6e6e6;

    .vocabulary-tyre-promo {
      position: absolute;
      right: -1px;
      top: -1px;
      .tyre-center__promo-tag {
        font-size: 1.4rem;
      }
    }

    &__thumbnail {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
      }
    }

    &__specs {
      flex: 1;
      display: flex;
      flex-direction: column;

      .vocabulary-tyre-brands {
        margin-bottom: 2rem;
        img {
          height: 90px;
          width: auto;
          object-fit: scale-down;
        }
      }

      .field {
        display: flex;
        margin-bottom: 0.5rem;

        .field-label {
          width: 50%;
          font-weight: 600;
          padding-right: 15px;
        }

        .field-items {
          flex: 1;
          display: flex;
          align-items: flex-end;
        }

        &.field-name-field-fitted-balanced-price {
          .field-items .field-item {
            color: $primary-color;
            font-weight: 600;
          }
        }

        &.field-name-node-link {
          margin-top: auto;
          .field-item {
            display: flex;
            justify-content: center;
          }
        }
      }

      .btn.btn-primary {
        margin-top: 1rem;
        background: #333333;
        border: 1px solid rgba(222, 26, 34, 0.3);
        border-radius: 53px;
        text-transform: uppercase;
        padding-left: 30px;
        padding-right: 30px;
        &:hover {
          background: $primary-color;
        }
      }
    }

    @include respond-above(md) {
      flex-direction: row;
      &__thumbnail {
        flex-basis: 40%;
      }
    }
    @include respond-above(sm) {
      &__specs {
        .field {
          &.field-name-node-link {
            .field-item {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  // Promo Tag
  &__promo-tag {
    display: inline-block;
    padding: 0.5rem 2rem;
    color: #fff;
    font-size: 1.9rem;
    text-transform: uppercase;
    font-weight: normal;
  }

  // Full Page
  &__full {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    ul li,
    ol li,
    p {
      font-weight: 300;
    }

    &__header {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      h1 {
        color: #181818;
        font-size: 40px;
        font-weight: 700;
        display: flex;
        align-items: center;
        line-height: 1.2;
        padding-right: 2rem;
        margin-top: 0;
      }

      .field-name-tyre-center-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
      }
    }

    &__image {
      width: 100%;
      img {
        margin: 0 auto;
        max-width: 100%;
        width: 180px;
        object-fit: contain;
      }
    }

    &__description {
      flex: 1;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #000;

      .field-name-field-tyre-brands {
        margin: 3rem 0;
      }
    }

    &__specifications {
      .field {
        display: flex;
        margin-bottom: 1.5rem;

        .field-label {
          flex-basis: 50%;
        }

        &.field-name-field-fitted-balanced-price {
          .field-item {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
    }

    // Responsive
    @include respond-above(md) {
      &__image {
        width: 50%;
        margin-right: 4rem;
        img {
          width: 50%;
        }
      }

      &__header {
        h1 {
          margin: 2rem 0;
        }

        .field-name-tyre-center-buttons {
          width: auto;
          margin-bottom: 0;
        }
      }
    }

    @include respond-above(lg) {
      padding-left: 0;
      padding-right: 0;
      &__image {
        img {
          width: auto;
        }
      }
    }
  }
}
