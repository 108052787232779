// Custom Mixins for Spacing 

// px to em
$browser-context: 16; // Default or $font-size-base - from bootstrap
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

//rem-calc px
@function rem-calc($size) {
    $remSize: $size / 16; //this should be the base font-size:16px
    @return #{$remSize}rem;
}

//Calc
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

//Predefined Spaces
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

//Generate margins and paddings
@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{$side}-#{$space}{ margin-#{$side}: #{$space}px !important;  }
        .p-#{$side}-#{$space}{ padding-#{$side}: #{$space}px !important;  }
        .m-all-#{$space}{ margin: #{$space}px !important;  }
        .p-all-#{$space}{ padding: #{$space}px !important; }
        @media screen and (max-width: $screen-mobile){
          .m-#{$side}-#{$space}-mobile{ margin-#{$side}: #{$space}px !important;  }
          .p-#{$side}-#{$space}-mobile{ padding-#{$side}: #{$space}px !important;  }
          .m-all-#{$space}-mobile{ margin: #{$space}px !important;  }
          .p-all-#{$space}-mobile{ padding: #{$space}px !important; }
        }
        @media screen and (min-width: $screen-mobile) and (max-width: $screen-tablet){
          .m-#{$side}-#{$space}-tablet{ margin-#{$side}: #{$space}px !important;  }
          .p-#{$side}-#{$space}-tablet{ padding-#{$side}: #{$space}px !important;  }
          .m-all-#{$space}-tablet{ margin: #{$space}px !important;  }
          .p-all-#{$space}-tablet{ padding: #{$space}px !important; }
        }
        @media screen and (min-width: $screen-tablet) and (max-width: $screen-desktop){
          .m-#{$side}-#{$space}-desktop{ margin-#{$side}: #{$space}px !important;  }
          .p-#{$side}-#{$space}-desktop{ padding-#{$side}: #{$space}px !important;  }
          .m-all-#{$space}-desktop{ margin: #{$space}px !important;  }
          .p-all-#{$space}-desktop{ padding: #{$space}px !important; }
        }
        @media screen and (min-width: $screen-desktop){
          .m-#{$side}-#{$space}-wide{ margin-#{$side}: #{$space}px !important;  }
          .p-#{$side}-#{$space}-wide{ padding-#{$side}: #{$space}px !important;  }
          .m-all-#{$space}-wide{ margin: #{$space}px !important;  }
          .p-all-#{$space}-wide{ padding: #{$space}px !important; }
        }
    }
} 


//flex container by Alex from less

@mixin object-fit($fit: fill){
  -o-object-fit: $fit;
     object-fit: $fit;
  font-family: 'object-fit: ${fit}';
}

@mixin box-sizing ($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  box-sizing:         $type;
}

@mixin border-radius ($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius:    $radius;
  border-radius:         $radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius:    $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius:  $bottomleft;
  -webkit-border-top-left-radius:     $topleft;

  -moz-border-radius-topright:        $topright;
  -moz-border-radius-bottomright:     $bottomright;
  -moz-border-radius-bottomleft:      $bottomleft;
  -moz-border-radius-topleft:         $topleft;

  border-top-right-radius:            $topright;
  border-bottom-right-radius:         $bottomright;
  border-bottom-left-radius:          $bottomleft;
  border-top-left-radius:             $topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

@mixin clearfix() {
  &:before,
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

