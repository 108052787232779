
body{

    .logo.contextual-region{
        @include wrapper(relative, 96%);
    }

    .site-logo{
        @include wrapper(relative, auto);
    }

    &.path-frontpage{
        .site-logo{
            img{
                &:nth-child(2){
                    display: none;
                }
            }
        }
        
        &.collapsed{
            .site-logo{
                img{
                    &:nth-child(1){
                        display: none;
                    }
                    &:nth-child(2){
                        display: block;
                    }
                }
            } 
        }
        
    }

    &:not(.path-frontpage){
        .site-logo{
            img{
                &:nth-child(2){
                    display: none;
                }
            }
        }
    }
    

}