.paragraphs-items{
    .slick-dots{
        > li{

            &.slick-active{
                > button{
                    background-color: $primary-color !important;
                }
            }

            > button{
                background-color: $gray-light-3;
                @include myanimated;
                @include border-radius(100%);
                &:before{
                    display: none;
                }

            }
        }
    }
}

.slick-dots{
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;

    @include wrapper(relative, 100%);
    
    li{

        &:only-child{
            display: none !important;
        }

        box-sizing: border-box;
        list-style: none;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        max-width: 0 8px;
        cursor: pointer;

        &.slick-active{
            button{
                background-color: $primary-color !important;  
            }             
        }

        button{
            display: block;
            border: none;
            font-size: 0;
            width: 20px;
            height: 20px;

            @include border-radius(100%);
            &::before{
                display: none;
            }

        }
    }
}


.slick-dots-gray{
    .slick-dots{
        > li {
            &.slick-active{
                > button{
                    background-color: $primary-color !important;
                } 
            }
            > button{
                background-color: $gray-light-3 !important;
            }
        }
    }
}


//:-------------------------------------------------------------------------------------------------------------------------

.slick-arrow-style{
    .slick__arrow{
        .slick-arrow{
            width: 50px;
            height: 100px;
            border-radius: 0;

            &::before{
                content: "";
                color: #D3D3D3;
                font-size: 45px;
                font-family: $font-family-icon-fa;
                text-align: center;
                @include wrapper(relative, 100%, 100px);
                @include respond-above(tablet){
                    font-size: 65px;
                }
            }

            &.slick-next{
                &::before{
                    content:"\f105";
                }
            }
            &.slick-prev{
                &::before{
                    content:"\f104";
                }
            }

        }
    }
}

.slick-arrow-style2{
    .slick__arrow{
        .slick-arrow{
            width: 100px;
            height: 100px;
            border-radius: 0;
            opacity: 0.9;
            @include myanimated;

            &.slick-disabled{
                opacity: 0.1;
            }
            &::before{
                display: none;
            }
            &.slick-next{
                background: url(../images/icons/arrow-carouosel-right.svg) center center no-repeat;
            }
            &.slick-prev{
                background: url(../images/icons/arrow-carouosel-left.svg) center center no-repeat;
            }

        }
    }
}

.slick-list{
    @include respond-below(tablet){
        min-height: auto !important;
        height: auto !important;
        width: 100%;
    }
}
