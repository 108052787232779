.view-new-cars{
    .view-content{

        .views-row{

           border-bottom: 1px solid $white;
           border-left: 1px solid $white;
          
            &:hover{
                .views-field-field-new-car-icon{
                    a{
                        @include imgZoomHover;
                    }
                }
            }

            .views-field{

                @include wrapper(relative, 100%);   

                &.views-field-field-new-car-icon{

                    .field-content{
                        @include wrapper(relative, 100%);
                        a{
                            @include wrapper(relative, 100%, 464px); 
                            @include imgZoomHover;
                            display: block;
                            color: transparent !important;

                            &::before{
                                content: "";
                                @include wrapper(absolute, 100%, 285px);
                                bottom: 0;
                                left: 0;
                                z-index: 20;
                                background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
                            }
                        }

                       
                        img{
                            display: block;
                            position: relative;
                            width: 100%;
                            height: 464px;
                            z-index: 2;
                            @include object-fit(cover);
                        }
                       
                       
                    }

                }

                &.views-field-title{
                    @include wrapper(relative, 100%);
                    @include flex-container(row, center, center, center);
                    text-align: center;
                    .field-content{
                        @include wrapper(absolute);
                        bottom: 0;
                        left: auto;
                        z-index: 50;

                        a{
                            @include wrapper(relative, 100%); 
                            @include text-shadow;
                            display: block;
                            text-align: center; 
                            font-size: em(25);
                            font-weight: 600;
                            text-transform: uppercase;
                            color: $white;
                            z-index: 50;
                            padding-bottom: $grid-gutter-width;
                            padding-left: $grid-gutter-width/2;
                            padding-right: $grid-gutter-width/2;

                            @include respond-above(wide){
                                font-size: em(35);
                            }

                            @include respond-between(desktop,wide){
                                font-size: em(30);
                            }

                        }
                    }
                }

                
            }

        }
    }
}