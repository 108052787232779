.node-type-car {
  @extend .stretch-fullwidth-content; // Stretch this content type to default fullwidth .global

  .block-easy-breadcrumb,
  .field-name-body,
  .field-name-field-extra-heading,
  .page-title {
    @extend .container-fluid;
  }

  .field-name-body {
    margin-bottom: $grid-gutter-width/2;
  }

  .field-group-htabs-wrapper {
    .horizontal-tabs {
      .horizontal-tabs-list {
        @include respond-below(mobile) {
          width: 100%;
          margin-bottom: 20px;
          border-bottom: none !important;
        }

        > li {
          @include respond-below(mobile) {
            width: 100%;
            &:hover {
              &.selected a {
                background-color: $primary-color !important;
                color: $white !important;
                border-bottom: none !important;
                strong {
                  color: inherit;
                }
              }
            }
            &.selected a {
              background-color: $primary-color !important;
              color: $white !important;
              border-bottom: none !important;
              strong {
                color: inherit;
              }
            }
            a {
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }

  //new-cars-paragraphs
  .new-cars-paragraphs {
    @extend .koda-tabs-layout; // Custom Tabs
    padding: 30px;

    .global-title {
      font-size: 4.2rem;
      margin-bottom: 2rem;
    }

    .koda-nav-tabs {
      @include respond-between(mobile, tablet) {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
      @include respond-below(mobile) {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-bottom: 0;
      }
    }

    .tab-content {
      @include respond-below(mobile) {
        padding: 0;
      }
      > .tab-pane {
        position: relative;
        width: 100%;
        .entity-paragraphs-item,
        .paragraphs-items {
          @include wrapper(relative, 100%);
          .koda-paragraph-outer-wrapper {
            display: block;
          }
        }
      }
    }

    @include respond-above(mobile) {
      padding: $grid-gutter-width 0;
    }
  }

  @extend .slick-dots-gray;
  .slick-dots {
    bottom: 0;
  }

  .page-title {
    //margin-bottom: 0;
    @include respond-above(desktop) {
      .page-header {
        width: 70%;
      }
    }
  }

  .enquire-block-left {
    &.redform {
      .koda-header-container {
        h2 {
          font-size: 3rem;
          padding-right: 30px;
          padding-left: 30px;
        }
        @include respond-above(tablet) {
          h2 {
            font-size: 5rem;
          }
        }
      }
    }
  }
}
