.logged-in {
  #contentblock {
    .tabs--primary {
      position: fixed;
      width: 100%;
      z-index: 200;
      bottom: 0;
      left: 0;
      margin: 0;
      background-color: #fff;
      display: flex;
      justify-content: center;
      border-top: 1px solid #e1e1e1;
      font-size: 14px;
    }
  }
}
