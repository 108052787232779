/*------------------------------------*\
  PHEV : Page
\*------------------------------------*/

/*------------------------------------*\
  Extra Title Custom for 2 phev page
  adds extra padding for the enquire now button 
\*------------------------------------*/
.page {
  &--how-phev-works,
  &--phev,
  &--phev-cars {
    .page-extra-title {
      @include respond-above(md) {
        padding-right: 250px;
      }
    }
  }
}

/*------------------------------------*\
  how-phev-works - page specific
\*------------------------------------*/
.page--how-phev-works,
.page--phev {
  &.node-type-page {
    .koda-fullwidth {
      .node-page.view-mode-full {
        > .field-name-body {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }
    }
  }

  #phevComponents {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 8rem;

    > .btn {
      font-size: 1.8rem;
      border-radius: 4px;
      padding: 1px 1.5rem;
      background-color: $primary-color;
      border: 3px solid $primary-color;
      box-shadow: none;
      &.active,
      &:hover {
        background: transparent;
        color: #444444;
      }
    }
    /** Responsive **/
    @include respond-below(md) {
      display: none;
    }
  }

  /*------------------------------------*\
      phev page specific classes .phev
    \*------------------------------------*/
  .phev {
    /** Main Wrapper **/
    &--main-wrapper {
      padding: 4rem 0;
      background-color: #f8f8f8;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 90%;
        left: 0;
        bottom: 0;
        z-index: 5;
        background: linear-gradient(
          180deg,
          rgba(95, 141, 202, 0) 0%,
          #97bbe4 100%
        );
      }
    }

    /** Main Component**/
    &--main-component {
      z-index: 10;
      .group-paragraph-info {
        h2,
        h3 {
          color: #000;
          text-align: center;
        }
        h2 {
          font-size: 6rem;
        }
        h3 {
          font-size: 2rem;
          font-weight: 400;
        }
      }
      img {
        width: auto;
        margin: 2rem auto;
      }
    }

    /** Accordion **/
    &--component-accordion {
      padding: 1rem 0;
      margin: 0;
      background-color: #f8f8f8;
      .panel {
        border-radius: 0;
        background: unset;
        box-shadow: none;
        border-bottom: 1px solid #eeeeee;
        h4 {
          font-size: 1.8rem;
          > a {
            &::after {
              margin-right: 0;
            }
          }
        }
        p {
          font-size: 1.6rem;
        }
      }
      /** Responsive **/
      @include respond-above(tablet) {
        padding: 10rem 0 4rem 0;
        .panel {
          h4 {
            font-size: 2.5rem;
          }
          p {
            font-size: 2rem;
          }
        }
      }
    }

    /** Braking System seciton **/
    &--regenerative-braking-system {
      .paragraphs-item-koda-basic-paragraph {
        padding-bottom: 2rem;
      }
      .two-col-img-text {
        min-height: 44rem; // fix for scroll target because of blazy
        .par-img.group-left,
        .par-content.group-right {
          padding-left: 0;
          padding-right: 0;
        }
        .par-img.group-left {
          .field,
          .field-items,
          .field-item {
            height: 100%;
          }
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .par-content.group-right {
          .paragraphs-item-koda-basic-paragraph {
            background-color: #000;
            color: #fff;
            padding: 4rem 5rem;
            .field-name-field-desc {
              p {
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      /** Responsive **/
      @include respond-above(mobile) {
        padding-top: 4rem;
      }
      @include respond-below(mobile) {
        > .koda-paragraph-inner-wrapper {
          padding-left: 0;
          padding-right: 0;
        }
        .two-col-img-text {
          .par-content.group-right {
            .paragraphs-item-koda-basic-paragraph {
              padding: 3rem;
            }
          }
        }
      }
      /** End **/
    }

    /** brakes **/
    &--drive-modes {
      padding-bottom: 4rem;
      .koda-inner-column-paragraphs {
        .paragraphs-items {
          > .column {
            .inner-section-content {
              padding-bottom: 0;
            }
            .paragraphs-item-koda-basic-paragraph {
              @extend .fluid-container;
              .group-paragraph-info {
                display: flex;
                flex-direction: column;
                .field-name-field-media {
                  margin-bottom: 2rem;
                  order: 0;
                  .content {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &::before {
                      content: '';
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      z-index: 10;
                    }
                    a {
                      position: relative;
                      width: auto;
                      margin: 0;
                    }
                    img {
                      width: auto;
                    }
                  }
                }
                .field-name-field-desc {
                  order: 1;
                  p {
                    strong {
                      color: $primary-color;
                    }
                  }
                  .drive-modes-img-desktop {
                    display: none;
                    img {
                      margin: 4rem auto;
                      width: auto;
                    }
                  }
                }
              }

              /** Responsive **/
              @include respond-above(mobile) {
                .group-paragraph-info {
                  .field-name-field-media {
                    display: none;
                  }
                  .field-name-field-desc {
                    .drive-modes-img-desktop {
                      display: block;
                    }
                  }
                }
              }
              @include respond-below(mobile) {
                padding-left: 0;
                padding-right: 0;
              }
              /** End **/
            }
          }
        }
      }
    }
  }
}

/*------------------------------------*\
  Common PHEV class used  in phev-cars 
  and phev page
\*------------------------------------*/
.phev {
  /*------------------------------------*\
    Common description field for PHEV pages
    with floating Enquire now button on the
    right using link field as a button
    \*------------------------------------*/
  &__description {
    .group-paragraph-info {
      display: flex;
      flex-wrap: wrap;
      .field-name-field-title {
        @extend .page-extra-title;
        color: #000;
        margin-bottom: 2rem;
        h2 {
          margin-top: 0;
        }
      }
      .field-name-field-desc {
        width: 100%;
      }
    }

    .field-name-paragraph-link-url {
      display: flex;
      justify-content: center;
      width: 100%;
      .field-item {
        @extend .header-buttons;
        margin-bottom: 0 !important;
      }
      a {
        @extend .enquire-now;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    @include respond-above(md) {
      .group-paragraph-info {
        flex-direction: column;
        .field-name-field-desc {
          order: 2;
        }
        .field-name-paragraph-link-url {
          order: 1;
          width: auto;
          .field-items {
            position: absolute !important;
            right: 0;
            top: -150px;
          }
        }
      }
    }
  }

  /** Model **/
  &__model {
    .paragraphs-items {
      > .column {
        margin-bottom: 6rem;
        .paragraphs-item-koda-basic-paragraph {
          .field-name-field-media {
            position: relative;
            /** Cover the link modal **/
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 5;
            }
          }
          .field-name-field-desc {
            p {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .field-name-paragraph-link-url {
            .field-item {
              position: absolute !important;
              width: 100%;
              bottom: 0;
              display: flex;
              justify-content: center;
              margin-bottom: -15px;
              z-index: 10;
              > a {
                background-color: $primary-color;
                color: #fff;
                font-size: 16px;
                padding: 8px 20px;
                border-radius: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::after {
                  @include myanimated;
                  content: '';
                  position: absolute;
                  bottom: 0;
                  margin-bottom: -14.5px;
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  //border-width: 15px 15px 0 15px;
                  line-height: 0;
                  border-width: 15px 15px 0 15px;
                  border-color: $primary-color transparent transparent
                    transparent;
                  _border-color: rgba(12, 3, 3, 0.5) #000000 #000000 #000000;
                  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                }
                &:hover {
                  background-color: darken($primary-color, 5%);
                  &::after {
                    border-color: darken($primary-color, 5%) transparent
                      transparent transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /** Features **/
  // &__features{ }
  /** Warranty **/
  &__warranty {
    padding-top: 8rem;
    padding-bottom: 8rem;
    h2.global-title {
      @extend .title-line;
      font-size: 30px;
      text-align: left;
      margin-bottom: 2rem;
    }
    .field-name-paragraph-link-url {
      a {
        font-weight: bold;
      }
    }
    @include respond-above(md) {
      padding-top: 15rem;
      padding-bottom: 15rem;
      h2.global-title {
        font-size: 50px;
      }
      .paragraphs-items {
        > .column {
          img {
            margin-top: 4rem;
          }
        }
      }
    }
    @include respond-below(md) {
      .paragraphs-items {
        > .column {
          &:nth-child(2) {
            order: 0;
          }
          &:first-child {
            order: 1;
          }
        }
      }
    }
  }

  /** Range **/
  &__range {
    @include extendbg(#fff, #e5e5e5);
    padding: 5rem 2rem;
    .koda-inner-column-paragraphs {
      margin-top: 8rem;
    }
    .paragraphs-item-koda-basic-paragraph {
      display: flex;
      //padding: 0 2rem 2rem 2rem;
      .koda-paragraph-outer-wrapper,
      .koda-paragraph-inner-wrapper,
      .group-paragraph-info {
        height: 100% !important;
      }
      .group-paragraph-info {
        display: flex;
        flex-direction: column;
        padding: 0 2rem 4rem 2rem;
        h2,
        h3 {
          font-size: 3rem !important;
          padding: 0;
          text-align: center;
        }
        .field {
          order: 1;
          &.field-name-field-media {
            position: relative;
            order: 0;
            display: flex;
            align-items: center;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 5;
            }
          }
          &.field-name-field-sub-title {
            margin-bottom: 4rem;
          }
          &.field-name-field-desc {
            color: #545454;
          }
          &.field-name-paragraph-link-url {
            margin-top: auto;
            text-align: center;
            .field-link {
              @extend .btn;
              @extend .btn-primary;
              border-radius: 21px;
              padding-left: 4rem;
              padding-right: 4rem;
              margin-top: 2rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    @include respond-above(md) {
      padding: 8rem 0;
      .paragraphs-item-koda-basic-paragraph {
        .group-paragraph-info {
          h2,
          h3 {
            font-size: 3.8rem !important;
          }
          .field-name-field-media {
            height: 200px;
          }
        }
      }
    }
    @include respond-above(desktop) {
      .paragraphs-item-koda-basic-paragraph {
        .group-paragraph-info {
          .field-name-field-media {
            height: 350px;
          }
        }
      }
    }
    @include respond-above(wide) {
      .paragraphs-item-koda-basic-paragraph {
        .group-paragraph-info {
          padding: 0;
          .field-name-field-media {
            height: 415px;
          }
        }
      }
    }
  }

  /** FAQ **/
  &__faq {
    padding: 2rem;
    .koda-header-wrapper {
      margin-bottom: 4rem;
    }
    .koda-accordion a.accordion-header {
      font-size: 1.8rem;
    }
    @include respond-above(wide) {
      padding: 5rem 0;
    }
    @include respond-above(md) {
      .koda-accordion a.accordion-header {
        font-size: 2.5rem;
      }
    }
  }
}
