/*------------------------------------*\
  Page Blog
\*------------------------------------*/
.page--blog{
    /** Sidebar **/
    .sidebar{
        .view-blog{
            a{
               display: flex;
               padding: 1rem 0;
               line-height: 1.4; 
               text-decoration: none;
            }
        }
    }
}

.node-blog {
    /** Teaser **/
    &.node-teaser {
        h3 {
            font-size: 20px;
            margin: 0 0 $grid-gutter-width/2 0;
            @include wrapper(relative, 100%);
            a {
                color: $black;
                @include wrapper(relative, 100%);
                &:hover {
                    color: $primary-color;
                }
            }
        }
        p {
            font-size: 20px;
        }
        .group-teaser-content {
            @include wrapper(relative, 100%);
            padding: $grid-gutter-width/2;
        }
    }
    /** Thumbnail **/
    &.view-mode-thumb_teaser {
        .group-teaser-content {
            @include wrapper(relative, 100%);
            padding: $grid-gutter-width/2;
            text-align: left;
            .field-name-body {
                p {
                    @include respond-below(mobile) {
                        font-size: em(12) !important;
                    }
                }
            }
        }
    }

    /** Menu Teaser **/
   &.view-mode-menu_teaser{
        @extend .nav-menu_teaser;
   }    

    /** Fullpage **/
    &.view-mode-full {
        @include respond-below(mobile) {
            padding-top: $grid-gutter-width/2;
        }
        @include respond-above(wide) {
            padding-right: $grid-gutter-width*2 !important;
            padding: 0;
        }
        @include respond-between(desktop, wide) {
            .field-name-body {
                padding-left: $grid-gutter-width/2;
                padding-right: $grid-gutter-width;
            }
        }
        .field-name-body {
            h2,
            h3 {
                margin-top: 0;
                font-size: 4.5rem;
            }
        }
        .page-title {
            @include respond-below(desktop) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        h2,
        h3,
        h4,
        h5 {
            color: $black;
        }
        .blog-photo-gallery {
            margin-top: $grid-gutter-width/2;
            .label-above {
                width: 100%;
                @extend .title-line;
            }
            .field-item,
            .views-row {
                @include imgZoomHover;
                a {
                    img {
                        height: 220px;
                        width: auto;
                        position: relative;
                        width: 100%;
                        @include object-fit(cover);
                    }
                }
            }
        }

        /** Blog Container **/
        .field-collection-container{
            border: none;
        }
        .blog-contents{
            .field-collection-view{
                padding: 0;
                margin: 0;
                border: none;
                .blog-content__wrapper{
                    > img{
                        margin-top: 4rem;
                        margin-bottom: 4rem;
                    }
                }
            }
        }

        /** For admin **/
        .field-collection-view-links{
            list-style: none;
            margin-bottom: 1rem;
            margin-right: 0;
            padding: 5px 15px;
            border: 1px solid #efefef;
            li a{
                margin: 0;
                padding: 0 4px;
                font-size: 12px;
            }
        }
        .action-links.action-links-field-collection-add{
            list-style: none;
            a{
                @extend .btn-dark;
                padding: 4px 20px;
                font-size: 14px;
            }
        }

    }

}

