// Add custom CSS here if you think it is needed to have a separate file
@import './admin';
@import './bottom-icons';
@import './default-image-header';
//@import './blog-type-column';
//@import './hp-block-news';
@import './hp-featured';
@import './hp-carmodel-carousel';
@import './hp-team';
@import './meet-the-team-tile';
@import './hp-featured-video';
@import './hp-promo';
@import './hp-featured-blog';
@import './latest-deal-carousel';
@import './latest-deal-selection-wrapper';
@import './koda-tabs-layout';
@import './image-description';
@import './hero-heading';
@import './hero-subtitle';
@import './newcars-tile-info';
@import './koda-fullwidth';
@import './banner-heading-paragraph';
@import './warranty-block';
@import './range-block-wrapper';
@import './media-reviews-tile';
@import './enquire-block';
@import './blog-sidebar';
@import './horizontal-tabs';
@import './faq-accordion';
@import './modal';
@import './modal-video';
@import './finance-calculator';
@import './social-icons';
@import './compare-cars';
@import './compare-cars-specs';
@import './default-page-paragraphs';
@import './switch-languages-tabs';
@import './banner-image-header';
@import './header-buttons';
@import './custom-header-block';
@import './used-cars-buttons';
@import './build-your-car-heading';
@import './tile-paragraph';
@import './default-content';
@import './service-open-hours';
@import './lease-offers-tile';
@import './used-cars-featured';
@import './used-cars-result-item';
@import './used-car-refined-search';

@import './prestige-cars-result-item';
@import './prestige-cars-refined-search';

@import './loan-application';
@import './quiz-title';
@import './anchor-block';
@import './range-price';
@import './contact-info';
@import './contact-tiles';
@import './contact-form-column';
@import './book-a-test-drive-form';
@import './bfs-covers';
@import './contact-form-tabs';
@import './new-cars';
@import './process';
@import './testimonials';
@import './nav-menu_teaser';
@import './p-horizontal-teaser';
@import './roadshow';
//@import './sl-video-player'; Removed video player embedded

//:---------------------------------------------------------------------------------------------------------------------
.thumb-tile-3 {
  .view {
    @extend .view-three-column-tiles;
  }
}

.thumb-tile-4 {
  .view {
    @extend .view-four-column-tiles;
  }
}

//:---------------------------------------------------------------------------------------------------------------------
