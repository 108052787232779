.bottom-icons{
    @include wrapper(relative, 100%);
    background-color: $gray-light-5;
    padding-top: em(30);
    padding-bottom: em(30);

    &.bottom-header{
        padding-bottom: 0;
        h2, h3{
            color: $black;
        }
    }

    p{
        margin-bottom: 0;
    }

    .koda-block-container{
        @extend .container-fluid;
    }

    @extend .slick-dots-gray;
    .slick-dots{
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
    }

    ul.bottom-icon-content{
        &:not(.contextual-links){
        
            @include wrapper(relative, 100%);
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            
            @include respond-below(tablet){
                padding-left: $grid-gutter-width/2;
                padding-right: $grid-gutter-width/2;
                flex-wrap: wrap;
            }

            > .slick-list{
                width: 100%;
                .slick-slide{
                    text-align: center;
                    padding-bottom: 15px;
                    img{
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 15px;
                    }
                }
            } 

            > li{
                @include flex-container(column, wrap, center, center, center);
                position: relative;
                width: 100%;
                list-style: none;
                margin: 0;
                padding: 0 em(20) em(20) em(20);              
                font-size: 14px;
                text-align: center;
                
                @include respond-above(desktop){
                    padding: 0 em(20);
                    flex: 1;
                    width: auto;
                }

                p{
                    @include wrapper(relative, 100%);  
                }

                img{
                    @extend .img-responsive;
                    position: relative;
                    margin-bottom: em(20);
                    @include respond-above(tablet){
                        @include wrapper(relative, 100%, 150px);
                        @include object-fit(scale-down);
                        object-position: center bottom;
                    }
                }
            } 
        }
    }

}