.block-fieldblock{

    .field-name-field-range-deal-hero-image{
        @include wrapper(relative, 100%);
    
        .field-items,
        .field-item{
            @include wrapper(relative, 100%);
        }
    
        .field-item{
            
            img{
                @include wrapper(relative, 100%);
                height: $slider-img-min-height;
                @include object-fit(cover);
                object-position: center center;
                @include respond-above(tablet){
                    height: $deal-banner-height; // 720px
                    min-height: $slider-img-min-height;
                }
            }
    
            @extend .img-gradient-cover;
    
        }
    
       
    
    }

}
