.contact-form-tabs{
    @extend .fluid-container;
    .contact-form-column{
        .group-paragraph-info{
            .field-name-field-desc{
                @include respond-below(tablet){
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}