.node-deal-model{

    //:--------------------------------------------------------------------------------------------------------
    // FULL VIEW
    &.view-mode-full{

        .latest-deal-selection-wrapper{
            @extend .container-fluid;
            @include respond-above(mobile){
                padding-left: $grid-gutter-width/2;
                padding-right: $grid-gutter-width/2;
            }
        }

    }

    //:--------------------------------------------------------------------------------------------------------
    &.view-mode-menu_teaser{

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        height: em(380);
        @include myanimated(500ms);
        cursor: pointer;
        // Hover

        &:hover{
            align-content: flex-end;
            .field-name-title{
                @include animateEffect(fadein, 800ms);
                position: absolute;
                bottom: -100px;
                display: none;
                opacity: 0;
            }
            .latest-deal-selection-wrapper,
            .koda-tabs-paragraph-title{
                @include animateEffect(fadein, 800ms);
                display: block;
            }
            .field-name-field-deal-base-image{
                img{
                    width: 70%;
                }
            }

        }
        // End Hover

        .field-name-field-deal-base-image{
            @include wrapper(relative, 100%);
            z-index: 99;
            .field-items,
            .field-item,
            .field-item a{
                @include wrapper(relative, 100%);
            }
            img{
                @include object-fit(contain);
                @include myanimated(500ms);
                margin: 0 auto;
                width: 100%;
                max-height: 318px;
            }
        }
        .latest-deal-selection-wrapper{
            display: none;
            @include animateEffect(fadein, 800ms);
            padding-bottom: 0 !important;
        }
        .field-name-title{
            @include animateEffect(fadein, 800ms);
            @include myanimated;
            @include wrapper(relative, 100%);
            .field-items,
            .field-item{
                @include wrapper(relative, 100%);
            }

            h2{
                @include wrapper(relative, 100%);
                font-size: em(28);
                font-weight: 500;
                text-align: center;
                color: $white;
                margin-top: 0;
                a{
                    color: $white;
                    &:hover{
                        color: $primary-color;
                    }
                }

                @include respond-below(normal) {
                    font-size: em(20);
                }
            }
        }

        .paragraphs-items{
            @include wrapper(relative, 100%);
        }

        .koda-tabs-paragraph-title{
            @include wrapper(relative, 100%);
            @include animateEffect(fadein, 800ms);
            margin-top: -150px;
            display: none;
            padding-left: em(20);
            padding-right: em(20);
            ul{
                @include wrapper(relative, 100%);
                //margin: 0 5%;
                @include respond-above(wide){
                    padding: 80px 8% em(20) 8%;
                }
                padding: 80px em(20) em(20) em(20);
                background-color: $gray-7;
                @include border-radius(10px);
                @include flex-container(column, wrap, center, center, center);

                > li{

                    &:first-child{
                        margin-top: 0;
                    }

                    margin: 10px 0 0 0;
                    padding: 0;
                    text-align: center;
                    @include wrapper(relative, 100%);
                    @include flex-container(column, wrap, center, center, center);


                    a{
                        font-weight: 600;
                        color: $white;
                        text-align: center;
                        padding: 10px;
                        @include wrapper(relative, 100%);
                        @include border-radius(13px);
                        border: 1px solid rgba(200, 200, 200, 0.5);

                        &:hover{
                            font-weight: 600;
                            background-color: $primary-color;
                            border: 1px solid $primary-color;
                        }
                    }
                }
            }
        }

        // this perhaps ought to be moved
        .latest-deal-selection-wrapper {
            @include respond-below(wide) {
                padding-bottom: 0;

                .koda-tabs-paragraph-title ul > li a {
                    font-size: 16px;
                }
            }
        }
    }


    //:--------------------------------------------------------------------------------------------------------
    &.view-mode-teaser{

        @include wrapper(relative, 100%, 100%);
        @include flex-container(column, wrap, center, stretch, flex-start);
        //padding: 0 $grid-gutter-width/2;
        padding: 0;

        .field-name-title,
        .field-items,
        .field-item,
        .field-item a{
            @include wrapper(relative, 100%);
            @include flex-container(column, wrap, center, stretch, center);
        }

        .field-name-field-deal-base-image{
            .field-items,
            .field-item,
            .field-item a{
                @include wrapper(relative, 100%);
                text-align: center;
                img{
                    margin: 0 auto;
                }
            }

        }


        h2{
            @include wrapper(relative, 100%);
            font-size: 30px;
            font-weight: normal;
            text-align: center;
            a{
                color: $white;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    //:--------------------------------------------------------------------------------------------------------


    //:--------------------------------------------------------------------------------------------------------

}
