.bfs-covers,
.promo-covers{

    .slick-dots{
        @include wrapper(absolute, 100%);
        position: absolute;
        bottom: 0;
        margin-bottom: -40px;   
    }

}