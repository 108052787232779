.media-reviews-tile{
    position: relative;
    padding: $grid-gutter-width/2 0;
    
    @include respond-below(tablet){
        border-bottom: 1px solid #ddd;
        padding-bottom: 55px;
        margin-bottom: 45px;
    }

    @include respond-between(mobile, wide){
        padding: $grid-gutter-width/2;
    }

    .global-title{
        color: $heading-color !important;
    }

    .koda-header-wrapper{
        margin-bottom: em(30);
    }

    .koda-inner-column-paragraphs .paragraphs-items{
        //> .column{
            .field{
                &.field-name-field-title{
                    margin: em(10) 0;
                    h2{
                        font-size: 20px;
                        color: $heading-color !important;
                        margin: 0 !important;
                    }
                }

                &.field-name-field-hero-media{
                    .cboxElement{
                        @include imgZoomHover;
                    }
                    img{  
                        height: 236px !important;
                        width: 100%;
                        @include object-fit(cover);
                        
                    }
                }

            }
            
        //}
    }

    .entity-paragraphs-item{
        &.paragraphs-item-koda-basic-paragraph{
    
            .koda-paragraph-outer-wrapper{
                .koda-paragraph-inner-wrapper{
                    .file{
                        .content{
                            .flexslider,
                            .optionset-default{
                                margin-bottom: 0;
                                iframe{
                                    height: em(200);
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }


}