.hero-heading{

    @extend .fluid-container;

    .koda-block-container{
        @include wrapper(absolute, 100%);
        color: $white;
        z-index: 50;
        bottom: 0;
        left: 0;
        //margin-bottom: $grid-gutter-width;
        margin-bottom: 30vw;
        @include respond-above(tablet){
            margin-bottom: 120px;
        }
        
        h2{
            @include wrapper(relative, 100%);
            text-align: center;
            margin: 0;
            font-size: 10vw;
            padding: 0 $grid-gutter-width/2;
            @include respond-above(tablet){
                font-size: 5em;
                //font-size: 100px;
            }
        }
    }

}


.hero-heading-medium{

    @extend .container-fluid;

    .koda-block-container{
        @include wrapper(absolute, 100%);
        color: $white;
        z-index: 50;
        bottom: 0;
        margin-bottom: $grid-gutter-width;
        
        @include respond-above(tablet){
            margin-bottom: 120px;
        }
        
        h2{
            @include wrapper(relative, 100%);
            text-align: center;
            margin: 0;
            font-size: 40px;
            padding: 0 $grid-gutter-width/2;
            @include respond-above(tablet){
                font-size: 85px;
            }
        }
    }

}