.page-add-accessories{

    @include wrapper(relative, 100%);
    @include flex-container(column, wrap);

    form{
        &#add-accessories-form{
            padding-bottom: $grid-gutter-width/2;
            text-align: center;

            > div{
                @include flex-container(column, wrap, flex-start, center);

                .btn{
                    &.form-submit{
                        @extend .btn-red;
                        outline: none;
                    }
                }
            }
        }
    }

    .page-title{
        display: none;
    }

    .form-item-choose-accessories{
        @include wrapper(relative, 100%);
        @include respond-between(mobile,wide){
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
            padding-bottom: $grid-gutter-width;
        }

        text-align: center;
        //margin-bottom: 0;

        @include respond-below(mobile){
            overflow-x: scroll;
        }

        .form-radios,
        .form-checkboxes{
            @include wrapper(relative, 100%);
            display: block;
            .form-radios,
            .form-checkboxes{
                @include wrapper(relative, 100%, 100%);
                @include respond-above(desktop){
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 5rem;
                }

                @include respond-between(mobile, desktop){
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 3rem;
                }

                label.control-label{
                    position: relative;
                    //font-size: 1px;
                    //color: transparent;
                    //margin-bottom: $grid-gutter-width;

                    @include respond-below(mobile){
                        min-width: 100vw;
                        padding-top: $grid-gutter-width;
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;
                    }

                    @include flex-container(column, wrap);
                    @include myanimated;

                    @include respond-above(mobile){
                        @include flex-container(column, wrap, flex-start, stretch, stretch);
                    }

                    input[type=checkbox]{
                        //Just hide this guy
                        position: absolute;
                        top:0;
                        left: 0;
                        opacity: 0;
                    }

                    .node{
                        @include wrapper(relative, 100%);
                    }

                    .field-name-field-sku{
                        display: none;
                    }

                    .field-name-field-accessory-image{
                        img{
                            @include myanimated;
                            border: 1px solid transparent;
                        }

                        &::before{
                            content: "";
                            @include myanimated;
                            @include wrapper(absolute, 50px, 50px);
                            @include border-radius(100%);
                            background: url('../images/icons/icon-check-w.svg') center center no-repeat $yellow-0;
                            top: 0;
                            right: 0;
                            margin: -15px -15px 0 0;
                            z-index: 5;
                            opacity: 0;

                            @include respond-above(mobile){
                                width: 80px;
                                height: 80px;
                                background-size: 50px auto;
                                margin: -25px -25px 0 0;
                            }
                        }
                    }

                    &.selected{
                        .field-name-field-accessory-image{
                            img{
                                border: 5px solid $yellow-0;
                            }
                            &::before{
                                opacity: 1;
                            }
                        }
                    }

                    &:hover{
                        h2{
                            color: $primary-color;
                        }
                    }

                    width: 100%;
                    cursor: pointer;

                    h2{
                        @include wrapper(relative, 100%);
                        @include myanimated;
                        margin: 0;
                        padding: $grid-gutter-width/2;
                        font-size: 24px;
                        font-weight: normal;
                        color: $black;
                        text-align: center;
                    }

                    img{
                        @include wrapper(relative, 100%, 200px);
                        @include object-fit(cover);
                        object-position: center center;
                        @include respond-above(mobile){
                            height: 243px;
                        }
                    }

                    .form-radio{
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 50;
                        opacity: 0;
                    }


                }
            }

        }



    }


}
