.mobile-menu{
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    .menu{
        li{
            &:active,
            &:focus,
            &:hover{
                background-color: transparent;
                a{
                    background-color: transparent;  
                }
            }

            a{
                color: $white;
                font-size: 26px;
                font-weight: 600;
                padding-left: 0;
                padding-right: 0; 
                
                &:hover, 
                &:focus,
                &:active{
                    background-color: transparent;  
                    color: $primary-color;
                }
            }

            border-bottom: 1px solid #545454;
        }
    }
}