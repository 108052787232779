@import './block-menu-opening-hours';
@import './block-tb-megamenu';
@import './block-language-select';
@import './region-slidemenu';
@import './meganav-horz-tile';
@import './mobile-menu';
.slide-menu-overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 101; // higher than everything on page but lower than slide-menu
    //z-index: 50;
    left: 0;
    top: 0;
    display: none;
}

.slide-menu {
    @include wrapper(fixed, $slidermenu-width, 100%);
    @media screen and (max-width: 360px) {
        width: 100%;
    }
    @include respond-below(tablet) {
        margin-top: $nav-min-height;
        padding: 0 $grid-gutter-width/2;
    }
    //padding: 0 $grid-gutter-width/2 0 40px;
    padding: 0 0 40px 0;
    background-color: $slidermenu-bg-color;
    right: 0;
    top:0;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    z-index: 102; // if you update this, make sure you update the slide-menu-overlay
    .slide-contact,
    .language-select,
    .menu-opening-hours,
    .footer-social-icons {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }
    .slide-contact {
        @include respond-below(tablet) {
            display: none;
        }
        a.phone {
            font-size: 25px;
            color: #fff;
            font-weight: 600;
            display: flex;
            align-items: center;
            font-family: $font-Roboto;
            margin-top: -70px;
            &:hover {
                color: $primary-color;
            }
            &:before {
                content: '';
                background-image: url(../images/icons/icon-phone.svg);
                width: 25px;
                height: 25px;
                display: inline-block;
                background-position: center;
                background-size: cover;
                margin-right: 10px;
            }
        }
    }
    .footer-social-icons {
        margin-top: 25px;
    }
}