/*------------------------------------*\
  Horizontal Teaser Type 
  - Requires Multi Col to wrap and Image Desc inside
\*------------------------------------*/

.p-horizontal-teaser-type {
  margin-top: 4rem;
  margin-bottom: 4rem;

  .paragraphs-items {
    gap: 4rem;
  }

  .paragraphs-item-koda-basic-paragraph {
    .field-name-field-desc {
      @include respond-below(wide) {
        padding-left: 30px;
        padding-right: 30px;
      }
      p {
        font-size: 18px;
      }
    }
  }

  .two-col-img-text {
    background: rgba(234, 234, 234, 0.5);

    h2,
    .field-name-field-title h2 {
      color: $black;
      font-size: em(28);
      font-weight: normal;
      margin-top: 0;
      @include respond-above(desktop) {
        font-size: em(35);
      }
    }

    p,
    ul,
    ul li,
    a,
    span {
      font-size: 18px;
    }

    .par-img,
    .par-content {
      .par-inner-layer {
        justify-content: flex-start;
      }
    }

    .par-content {
      .par-inner-layer {
        padding: 2rem 0;
      }
    }

    .paragraphs-item-koda-basic-paragraph {
      .field-name-field-desc {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @include respond-above(desktop) {
      .par-img {
        flex: 0 0 560px !important;
        width: 560px !important;
      }
      .par-content {
        flex: 1 !important;
        width: auto !important;
        .par-inner-layer {
          padding: 4rem;
        }
      }
    }
    @include respond-below(tablet) {
      .par-img {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include respond-below(desktop) {
      .btn-red,
      .btn-dark {
        width: 100%;
      }
    }
  }
}
