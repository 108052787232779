
.view-three-column-tiles{

    align-items: stretch;
    height: 100%;       
    @include wrapper(relative, 100%);  
    //:----------------------------------------------------------------------------------
    &.with-gap{
        > .field-items,
        .view-content,
        .tile-content{

            > .field-item,
            .views-row,
            .tile-row{
                margin-bottom: 20px;
                box-sizing: border-box;

                @include respond-above(normal){
                    
                    width: calc(33.3% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(3n+1),
                    &.views-row-first{ 
                        margin-left: 0;  
                    }                                     
                }
        
               
                @include respond-between(tablet, normal){
                    width: calc(50% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(2n+1),
                    &.views-row-first{ 
                        margin-left: 0;                                
                    }                                
                }
                
                
            }
            
        }
    }
    //:----------------------------------------------------------------------------------
    &.with-gap2{
        > .field-items,
        .tile-content,
        .view-content{

            > .field-item,
            .views-row,
            .tile-row{
                margin-bottom: 20px;
                box-sizing: border-box;

                @include respond-above(normal){
                    
                    width: calc(33.3% - 40px);
                    margin-left: 60px;

                    &:first-child,
                    &:nth-child(3n+1),
                    &.views-row-first{ 
                        margin-left: 0;  
                    }                                     
                }
        
               
                @include respond-between(tablet, normal){
                    width: calc(50% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(2n+1),
                    &.views-row-first{ 
                        margin-left: 0;                                
                    }                                
                }
                
                
            }
            
        }
    }
     //:----------------------------------------------------------------------------------    
    
    > .field-items,
    .tile-content,
    .view-content{

        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, stretch);
        height: 100%;
    
        > .field-item,
        .tile-row,
        .views-row{
        height: 100%;
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, flex-start, stretch);
        
        @include respond-above(tablet){
            @include wrapper(relative, 33.3%);
            
            &:first-child,
            &:nth-child(3n+1),
            &.views-row-first{ 
                padding-left: 0;                                  
            }
            
            &:nth-child(3n),
            &.views-row-last{
                padding-right: 0;                        
            }
        }

       //:----------------------------------------------------------------------------------------
        }
    }


}
