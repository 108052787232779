.paragraphs-item-lucas-sale{

    .group-header,
    .group-left,
    .group-right,
    .group-footer{
        color: $gray-0 !important;
    }

    .group-header{
        @include wrapper(relative, 100%);
        margin-bottom: $grid-gutter-width/2;

        
        h3{
            font-size: 45px;
            margin: 0;
        }

    }

    > .group-left{
        
        width: 100% !important;
        @include respond-above(desktop){
            width: 40% !important;
        }

        .field{
            font-size: 20px;
            font-weight: 600;
            @include wrapper(relative, 100%);
            margin-bottom: $grid-gutter-width/2;
    
            .field-items{
                @include flex-container(row, wrap, flex-start, center, center);
                @include wrapper(relative, 100%);  
                &:before{
                    content: "";
                    width: 43px;
                    height: 43px;
                    //background-color: $primary-color;
                    @include border-radius(100%);
                }         
            }
            .field-item{
                padding-left: $grid-gutter-width/2;
                display: flex;
                flex: 1;
            }
    
        }
    }

    > .group-right{
        width: 100% !important;
        @include respond-above(desktop){
            width: 60% !important;
        }
    }

    .field-name-field-specs{
        @include wrapper(relative, 100%);
        ul{
            > li{
                padding-left: 10px;
                padding-bottom: 10px;
            }
        }
    }

    

    .field-name-field-cost-per-week{
        .field-item{
            display: flex;
            flex: 1;
        }
    }

    .field-name-field-from-price{
        .field-items{
            &:before{
                background: url(../images/icons/icon-check.svg) center center no-repeat #749A37;
            }
        }
    }

    .field-name-field-save{
        .field-items{
            &:before{
                background: url(../images/icons/icon-dollar.svg) center center no-repeat #55acee;
            }
        }
    }

    .field-name-field-cost-per-week{
        .field-items{
            &:before{
                background: url(../images/icons/icon-calendar.svg) center center no-repeat #7d4497;
            }
        }
    }


}