.view-blog{

    .views-row{
        &.masonry-item{
            background-color: $white;           
            border: none;
            width: 100%;
            margin: 0 !important;
            padding: 0 $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2;
            
            @include respond-above(desktop){
                width: 25%;
            }
            @include respond-between(tablet, desktop){
                width: 50%;
            }
            .node-blog.node-teaser.view-mode-teaser{
                box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.18);                
            }

        }
    }


    &.homepage-block-blog{
        @include wrapper(relative, 100%);
        .view-content{
            @include wrapper(relative, 100%);  
            .slick{
                position: relative;
            }
        }
    }

}