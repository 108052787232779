.block-sharethis{
    @include wrapper(relative, 100%);
   
    @include respond-above(wide){
        margin-top: em(10);
    }

    .koda-block-container{
        @include wrapper(relative, 100%);
    }

    .koda-header-container{
        @include wrapper(relative, 100%);
        .block-title{
            @include wrapper(relative, 100%);
            font-size: em(15);
            margin: 0;
            justify-content: center;
            text-align: center;
            font-weight: normal;
        }
    }

    .sharethis-wrapper{
        @include wrapper(relative, 100%); 
        @include flex-container(row, wrap, center, center, center);
        margin-top: em(10);
        text-align: center;

        span{
            @include wrapper(relative, em(29), em(29));
            @include border-radius(100%);
            @include myanimated;
            cursor: pointer;
            margin: 0 em(2);
            background-color: #C8C8C8;

                &::before{
                    font-family: $font-family-icon-fa;
                    color: $white;
                    line-height: em(29);
                }

            &:hover{
                background-color: $primary-color;
                color: $white;
            }

            &.st_facebook_custom{
                &::before{
                    content:"\f09a";
                }
            }
            &.st_twitter_custom{
                &::before{
                    content:"\f099";
                }
            }
            &.st_linkedin_custom{
                &::before{
                    content:"\f0e1";
                }
            }
            &.st_sharethis_custom{
                &::before{
                    content:"\f1e0";
                }
            }
        }

    }


}