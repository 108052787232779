//Mixin Effects
@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: $string;
}
@mixin box-shadow ($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}
@mixin drop-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow:  $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow:   $x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin inner-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow:    inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow:         inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin opacity ($opacity: 0.5) {
  -webkit-opacity:  $opacity;
  -moz-opacity:     $opacity;
  opacity:    $opacity;
}

@mixin gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background: -webkit-linear-gradient(top, $startColor, $endColor);
  background: -moz-linear-gradient(top, $startColor, $endColor);
  background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
}

@mixin horizontal-gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background: -webkit-linear-gradient(left, $startColor, $endColor);
  background: -moz-linear-gradient(left, $startColor, $endColor);
  background: -ms-linear-gradient(left, $startColor, $endColor);
  background: -o-linear-gradient(left, $startColor, $endColor);
}
@mixin diagonal-gradient ($startColor: #eee, $endColor: white) {
  background: $startColor;
  background: -moz-linear-gradient(-45deg,  $startColor 0%, $endColor 100%);
  background: -webkit-linear-gradient(-45deg,  $startColor 0%, $endColor 100%);
  background: linear-gradient(135deg,  $startColor 0%, $endColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=1 );
}

@mixin placeholder-color($color){

  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }

}

@mixin transform-o($x:center, $y:center) {

  -webkit-transform-origin: $x $y;
  -moz-transform-origin:    $x $y;
  -ms-transform-origin:     $x $y;
  -o-transform-origin:      $x $y;

}

@mixin scale ($factor) {
  -webkit-transform: scale($factor);
  -moz-transform:    scale($factor);
  -ms-transform:     scale($factor);
  -o-transform:      scale($factor);
}