.custom-htabs {
    .horizontal-tabs {
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, center, center, center);
        border: none;
        margin: 0 0 $grid-gutter-width/2 0;
    }

    .horizontal-tabs-list {
        position: relative;
        width: 100%;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid $gray-light-9;
        margin-bottom: $grid-gutter-width/2;

        @include flex-container(column, wrap, center, center, center);
        @include respond-above(tablet) {
            flex-direction: row;
            width: auto;
        }
        @include respond-below(tablet) {
            border-bottom: none;
        }

        > li {
            background-color: transparent !important;
            border: none;
            font-size: 27px;
            font-weight: normal;
            color: $black;
            min-width: auto;
            padding: 0 !important;

            @include respond-below(tablet) {
                width: 100%;
                min-width: 100%;
            }

            &.selected {
                &:hover {
                    @include respond-above(tablet) {
                        background-color: transparent !important;
                        color: $black;
                    }
                }

                > a {
                    padding: 10px $grid-gutter-width/2;

                    @include respond-above(tablet) {
                        @include flex-container(column, wrap, center, center, center);
                        color: $black !important;
                        background-color: transparent !important;
                        border-bottom: 10px solid $primary-color;
                        strong {
                            color: $black !important;
                        }
                    }
                    @include respond-below(tablet) {
                        background-color: $primary-color;
                        color: $white;
                        strong {
                            color: $white;
                        }
                    }
                }
            } // selected

            &:hover {
                &:not(.selected) {
                    background-color: transparent;
                    color: $black;
                    > a {
                        background-color: transparent;
                        color: $black;
                    }
                }
            }

            > a {
                font-size: 27px;
                font-weight: normal;
                background: none;
                border: none;
                padding: 10px $grid-gutter-width/2;
                color: $black;
                text-align: center;

                @include respond-above(tablet) {
                    border-bottom: 10px solid transparent;
                }

                strong {
                    font-weight: normal;
                }
            }
        }
    }

    .horizontal-tabs-panes {
        @include wrapper(relative, 100%);

        .panel {
            width: 100%;
            box-shadow: none;
            padding: 0;
            margin: 0 !important;
            border-radius: 0;

            .video-tab {
                @include wrapper(relative, 100%);

                .flexslider {
                    margin-bottom: 0;
                }

                .file-video,
                .file {
                    @include wrapper(relative, 100%);
                    iframe {
                        @include wrapper(relative, 100%, 480px);
                        @include respond-above(mobile) {
                            height: 640px;
                        }
                    }
                }
            }

            .field-items .field-item {
                img {
                    @include wrapper(relative, 100%, 307px);
                    @include object-fit(cover);
                    object-position: center center;
                }

                > a {
                    @include wrapper(relative, 100%);
                    @include imgZoomHover;
                }
            }
        }
    }
}
