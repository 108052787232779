/** Warranty Block **/
.warranty-block {
  padding: 4rem 0;

  .koda-header-container {
    padding: 0 $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width;
    @include wrapper(relative, 100%);

    h2 {
      margin: 0;
      padding: 0;
      text-align: left;
      @include wrapper(relative, 100%);
    }
  }

  .koda-block-container {
    @include wrapper(relative, 100%);
  }

  /** Responsive **/
  @include respond-above(desktop) {
    padding: $grid-gutter-width * 2 0;
  }

  @include respond-above(tablet) {
    .koda-header-container {
      h2 {
        font-size: 60px;
      }
    }
  }
}
