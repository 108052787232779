@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  

    .two-col-img-text{
        
        .par-img.group-left{
            height: 600px !important;
        }      
    }

 }

 