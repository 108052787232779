.region-topcontent{
    @include wrapper(relative, 100%);
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    
    h1{
        font-size: 25px;
        text-transform: uppercase;
        color: $gray-1;
        width: auto;

        &.page-header{
           margin: 0;
           padding: 0;
           border: none;
           
           span{
               position: relative;
               width: auto;
               margin-bottom: 0;
               padding-left: 10px;
               border-left: 3px solid $gray-light-2; 
           }
        }
    }
}

.top-content{
    @include wrapper(relative, 100%);
    border-bottom: 1px solid $gray-light-1;
}