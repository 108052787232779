.node-type-blog .region-sidebar-first {
    line-height: 39px;
  
    .block-title {
        font-size: 20px;
        line-height: 39px;
        margin: 0;
    }
  
    .view-blog {
        margin-bottom: 20px;
        margin-top: 10px;
  
        .views-row:not(:first-child) {
            border-top: 1px solid #E2E2E2;
        } 
    }
}

