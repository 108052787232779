section{
    &.full-page-without-regions{

        .main-container{
            &.container-fluid{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .region-content{
            padding-top: 0;
            padding-bottom: 0;
        }

    }
}