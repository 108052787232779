// Promo Logo - Floating on the top
.hp-promo {
  &__logo {
    position: absolute;
    width: 150px;
    height: auto;
    bottom: 0;
    right: 0;
    padding: 1rem;
    img {
      width: 100%;
    }
    @include respond-above(tablet) {
      width: 220px;
      padding: 0;
    }
    @include respond-above(desktop) {
      width: 260px;
    }
  }
}
