.meet-the-team-tile{
    @include wrapper(relative, 100%);
    overflow: hidden;
    .koda-inner-column-paragraphs{
        @include respond-below(tablet){
            background-color: $white;

            .slick-dots{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                margin-bottom: -35px;
            }

        }
    }


}