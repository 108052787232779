.menu-opening-hours {
  padding-top: $grid-gutter-width/2;

  h4 {
    color: $white;
    font-size: 17px;
    margin: 0;
  }

  .tab-links {
    padding-bottom: 15px;
    padding-top: 15px;

    a {
      color: $white;
      font-size: 17px;
      margin-right: 15px;
      padding-bottom: 4px;
      cursor: pointer;

      &.open {
        border-bottom: 4px solid $primary-color;
      }
    }
  }

  .tabs > div {
    color: $white;
    font-size: 14px;

    &:not(.open) {
      display: none;
    }
  }
}