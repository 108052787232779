/*------------------------------------*\
  Assured Future Vehicle Value Page
\*------------------------------------*/

.benefits-tile{

    @include extendbg(#545454);
    color: #FFF;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;
    @extend .afv--tiles;
    .column{
        background-color: #FFF !important;
    }

}

/** afv **/
.afv{
    /** apply **/
    &--apply{
        margin-top: 2rem;

        .paragraphs-item-koda-basic-paragraph{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .field-name-field-desc{
                padding: 3rem 0;
                ol, ul{
                    > li{
                        font-weight: 500;
                        margin-bottom: 1rem;
                        @include respond-between(xl, xxl){
                            margin-bottom: 2rem;
                            font-size: 2.5rem;
                        }
                        @include respond-above(xxl){
                            margin-bottom: 2.5rem;
                            font-size: 3rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        /** Paragraph as button **/
       .paragraph-as-button{
            .field-item{
                margin-bottom: 0;
            }
            .field-link{
               min-width: 32rem;   
            }

            @include respond-above(md){
                margin-top: 2rem;
                .field-item{
                    margin-bottom: 2rem;
                }
                 &.btn-left{
                    .field-link{
                        margin-right: 0;
                    }
                 }  
                 &.btn-right{
                    .field-link{
                        margin-left: 0;
                    }
                 }   
            }
       } 

    }
    /** Tiles **/
    &--tiles{
        h2.global-title{
            font-size: 4.5rem;
            text-align: center; 
            margin-bottom: 4rem;
        }

        .column{
            background-color: #F8F8F8;
            padding: 3rem;   
            .group-paragraph-info{
                display: flex;
                flex-direction: column;
            }
            h2{
                color: $brand-primary;
                font-size: 3rem;
                font-weight: 600;
                margin-top: 0;
                text-align: center;
            }
            p{
                text-align: center;
                font-size: 1.6rem;
                color: #000;
            }
            .field-name-field-title{
                order: 0;
            }
            .field-name-field-media {
                order: 1;
                .content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    min-height: 15rem;
                    img{
                        width: auto;
                    }
                }
            }
            .field-name-field-desc{
                order: 2;
            }
        }
        /** Responsive **/
        @include respond-below(md){
            .column{
                margin-bottom: 3rem;
            }
        }
        @include respond-above(md){
            .column{
                margin-bottom: 0;
                padding: 4rem;
            }
        }
    }

    /** Terms **/
    &--terms-conditions{
        margin-bottom: 4rem;
        p{
            font-size: 1.2rem;
        }
    }

}