.header-buttons {
  @include flex-container(row, wrap, center, stretch, center);
  flex: initial;
  width: auto;
  //margin-bottom: $grid-gutter-width/2;
  @include respond-above(desktop) {
    margin-bottom: -$grid-gutter-width;
  }
  @include respond-above(tablet) {
    z-index: 60;
    margin-left: auto;
    justify-content: flex-end;
  }
  @include respond-below(tablet) {
    margin-left: auto;
    margin-right: auto;
  }

  .start-again {
    &::before {
      border: 4px solid rgba(24, 24, 24, 0.3) !important;
    }

    &:hover {
      &::before {
        border: 4px solid rgba(24, 24, 24, 1) !important;
      }
      color: $black;
    }
  }

  .start-again,
  .enquire-now,
  .latest-deal,
  .more-info {
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
    max-width: 120px;
    padding: 0 15px;
    line-height: 1;
    font-size: em(10);

    @include respond-above(desktop) {
      font-size: 20px;
    }

    @include respond-above(mobile) {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      border: 4px solid rgba(222, 41, 16, 0.3);
      margin-bottom: 15px;
      position: relative;
      width: 60px;
      height: 60px;
      @include respond-above(desktop) {
        width: 80px;
        height: 80px;
      }
      @include border-radius(100%);
      @include myanimated;
    }

    &:hover {
      &::before {
        border-color: $primary-color;
      }
    }
    &:active,
    &:visited {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0;
  }

  .koda-block-container {
    @include flex-container(row, wrap);
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.header-buttons-white {
  @include flex-container(row, wrap, center, stretch, center);
  flex: initial;
  width: auto;
  margin-bottom: $grid-gutter-width;

  @include respond-above(tablet) {
    justify-content: flex-end;
  }

  .koda-block-container {
    @include flex-container(row, wrap, center);
  }

  .enquire-now,
  .latest-deal,
  .enquire-now-w,
  .latest-deal-w {
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
    color: $white;
    padding: 0 15px;
    font-size: em(10);

    @include respond-above(desktop) {
      font-size: 22px;
      padding: 0 $grid-gutter-width/2;
    }

    @include respond-above(mobile) {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      border: 4px solid $white;
      margin-bottom: 15px;
      width: 60px;
      height: 60px;

      @include respond-above(desktop) {
        width: 80px;
        height: 80px;
      }
      @include border-radius(100%);
      @include myanimated;
    }

    &:hover {
      &::before {
        border-color: $white;
      }
      color: $primary-color;
      text-decoration: none;
    }

    &:active,
    &:visited {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0;
  }
}
//:-------------------------------------------------------------------------------------------------------------------------

.contact-us-button {
  .koda-block-container {
    @include respond-below(desktop) {
      justify-content: center;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.compare-enquire-button {
  @extend .fluid-container;
  .koda-block-container {
    .enquire-now {
      width: 210px;
      margin: 0 !important;
      @include respond-below(desktop) {
        width: 120px;
      }
    }
  }

  &.desktop-btn {
    //display: flex;
    margin-bottom: 0;
    @media screen and (min-width: 1640px) {
      .koda-block-container {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -100px;
      }
    }
  }

  &.mobile-btn {
    //display: none;
    @media screen and (max-width: 1639px) {
      //display: block;
    }
    margin-bottom: 0;
    .koda-block-container {
      justify-content: center !important;
      .enquire-now {
        @include respond-below(tablet) {
          width: 120px;
        }
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.header-buttons {
  &.inline-buttons {
    @include flex-container(row, wrap, center, center, center);
    @include respond-below(mobile) {
      flex-direction: column;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.custom-header-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;

  .print {
    &::before {
      background: url('../images/icons/icon-print.svg') center center no-repeat;
    }
  }

  .enquire {
    &::before {
      background: url('../images/icons/icon-mail.svg') center center no-repeat;
    }
  }

  > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-size: em(10);
    font-weight: bold;
    cursor: pointer;

    @include respond-above(desktop) {
      font-size: 22px;
    }

    @include respond-above(mobile) {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      border: 4px solid rgba(222, 41, 16, 0.3);
      margin-bottom: 15px;
      position: relative;
      width: 60px;
      height: 60px;

      @include respond-above(desktop) {
        width: 80px;
        height: 80px;
      }
      @include border-radius(100%);
      @include myanimated;
    }

    &:hover {
      &::before {
        border-color: $primary-color;
      }
    }

    &:active,
    &:visited {
      text-decoration: none;
    }
  }
}
