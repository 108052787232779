.hp-featured-blog{
    @extend .screenh100;
    @include wrapper(relative, 100%);
    @include flex-container(column, wrap, center, center, center);
    padding-top: $grid-gutter-width;

    .koda-paragraph-outer-wrapper{
        position: relative;
        display: block !important;       
        .slick{
            position: relative;
            width: 100%;
            .slick-track{
                position: relative;
                display: flex;
                width: 100%;               
            }
            .slide__content{ 
                position: relative;       
                @include respond-above(mobile){
                    padding-left: em(15);
                    padding-right: em(15);
                }
            }
            .slick-dots{
                margin-bottom: 20px;
            }
        }

    }

    .node.node-blog.view-mode-thumb_teaser{

        .group-teaser-content{
            padding-left: 0;
            padding-right: 0;

            h3{
                font-size: em(15);
                margin-bottom: em(15);
                a{
                    color: $black;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
    
            p{
                font-size: em(14);
            }
        }

        .field-name-node-link{
            a{
                font-size: em(14);  
            }
        }

        .field-name-field-blog-listing-img{
            @include imgZoomHover;
            img{
                height: 224px;
                //min-height: 224px;
                @include object-fit(cover);
                object-position: top center;
            }
        }

    }
    
}