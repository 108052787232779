.view-mode-build_your_mitsubishi{
    @include wrapper(relative, 100%);
    padding: 0;
    margin: 0; 

    .header-buttons{
        &.inline-buttons{
            margin-left: auto;
            margin-right: auto;
        }
    }

}