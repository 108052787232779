.contact-info{

    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;

    @include respond-between(tablet, wide){
        padding: $grid-gutter-width*2 $grid-gutter-width/2;
    }

    .par-content{
        @include respond-above(wide){
            padding-left: em(60);
        }
    }

    .field-name-field-desc-basic-paragraph{
      
        .field-item{
            h2{
                margin: em(8) 0;
                font-size: em(30);
                font-weight: 600 !important;
                line-height: 1.2em;
            }
    
            hr{
                border-top: 1px solid #656565;
            }
        }

        a.phone{
            font-family: $font-Roboto;
            color: #EBB428;
            font-size: em(28);
            font-weight: 600;
            padding-left: em(20);
            background: url(../images/icons/icon-phone.svg) 0 center no-repeat;
            background-size: auto 100%;
            width: 100%;
            margin-right: 0;
            display: block;

            @include respond-above(mobile){
                width: auto;
                margin-right: 20px;
                display: inline-block;
            }
            
        }

    }

}