.contact-form-column{

    .group-paragraph-info{

        h3{
            color: $black;
            margin: 0 0 em(10) 0;
            font-size: em(30);
        }
        p{
            font-size: em(16);
        }

        display: flex;
        flex-wrap: wrap;

        .field-name-field-desc{
            display: flex;
            order: 1;
            width: 100%;
            
            @include respond-above(desktop){
                flex: 0 0 35%;
                width: 35%;
                padding-left: em(35);
            }
            @include respond-between(tablet, desktop){
                flex: 0 0 40%;
                width: 40%;
                padding-left: em(30);
            }
            @include respond-below(tablet){
                padding: 0 $grid-gutter-width/2;
            }
        }

        a{
            &.email{
             color: $gray-9;
             &:hover{
                 color: $primary-color;
             } 
            }
     
            &.tel{
                font-size: 1.5em;
                font-weight: 600;
                font-family: $font-theme-3;
                background: url('../images/icons/icon-phone-black.svg') 0 center no-repeat;
                background-size: auto 100%;
                padding-left: em(25);
     
                @include respond-above(desktop){
                 font-size: 40px;  
                }
     
                &:hover{
                    color: $black;
                }
            }
     
        }

        .block-webform{
            display: flex;
            order: 0;
            width: 100%;
            margin-bottom: em(35);

            @include respond-above(desktop){
                flex: 0 0 65%;
                width: 65%;
                margin-bottom: 0;
            }
            @include respond-between(tablet, desktop){
                flex: 0 0 60%;
                width: 60%;
                margin-bottom: 0;
            }

            @include respond-below(tablet){
                display: block !important;
            }
        }

    }
}

.contact-form-tabs{
    //padding-bottom: em(20);
    border-bottom: 1px solid #ddd;
    @include respond-above(desktop){
        border-bottom: none;
    }

    > .koda-paragraph-inner-wrapper{  
        
        @include respond-below(mobile){
            padding: 0 !important;
        }
        
        > .koda-inner-column-paragraphs{
            @include respond-between(mobile, wide){
                padding: 0 $grid-gutter-width/2;
            } 
        }
         
    }


}