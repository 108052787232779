.service-open-hours{
    position: absolute !important;
    width: 90%;
    right: 0;
    top: 0;
    margin-top: 15vh;
    color: $white;
    padding: $grid-gutter-width;
    background-color: $primary-color;
    z-index: 50;

    @include respond-above(tablet){
        margin-top: 30vh;
    }

    @include respond-above(mobile){       
        width: 400px;
    }
    
    h2.block-title{
        margin: 0 0 $grid-gutter-width/2 0;
        font-size: 18px;
        font-weight: 600;
        color: $white !important;
        @include respond-above(mobile){ 
            font-size: 25px; 
        }
    }
    p{
        font-size: 16px;
        @include respond-above(mobile){ 
            font-size: 20px; 
        }
    }
}