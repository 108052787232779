// Language Select Block
.language-select{
    @include wrapper(relative, 100%);
    
    .koda-block-container{
        @include wrapper(relative, 100%);
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $gray-1;
    }

    a{
        color: $white;
        position: relative;
        margin-right: 10px;

        &:hover{
            color: $primary-color;
        }
    }

    .koda-block-container{
        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, center, center);

        p{
            margin-bottom: 0; 
            > a{
                font-weight: 600;
                font-size: 30px;
            } 
        }

    }
}
//:---------------------------------------------------------------------------------------------------------------------