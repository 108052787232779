.node-type-page{
    // IF FULL WIDTH 
    //:--------------------------------------------------------------------------------------------------------------------
    .koda-fullwidth{
        // MAKE BODY - Fluid Container 
        .node-page{
            &.view-mode-full{
        
                > .field-name-body{
                    @extend .container-fluid;
                    padding-top: $grid-gutter-width;
                    padding-bottom: $grid-gutter-width;

                    @include respond-below(wide){
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;
                    }
                }
        
            }
        }
        
    }
    //:--------------------------------------------------------------------------------------------------------------------

    .page-title{
        display: none;
    }

    .page-extra-title{
        @include respond-below(mobile){
            padding-top: $grid-gutter-width;
        }
    }

    //:--------------------------------------------------------------------------------------------------------------------

    .field-name-field-paragraph{
        @extend .koda-tabs-layout; // Display Default Tab
    }

    //:--------------------------------------------------------------------------------------------------------------------

}