// Node Type
.page {
  &--phev-event,
  &--all-new-triton {
    .topimg {
      height: auto;
      min-height: 800px;
      .Screen {
        &::after {
          display: none;
        }
      }
    }
    .region-topimg {
      position: relative;
      font-family: $font-Poppins;
      height: 100%;
      min-height: 100vh;

      > .block-views {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 1000px;
        .koda-block-container,
        .koda-block-container .view,
        .koda-block-container .view div,
        .koda-block-container img {
          height: 100%;
        }
      }
      > .block-fieldblock {
        width: 100%;
        min-height: 1000px;
        height: 100vh;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        > .koda-block-container {
          @extend .container;
        }
      }

      h2 {
        font-family: $font-Poppins;
        margin: 0 0 2rem 0;
        font-size: 8rem;
      }

      p {
        font-weight: 300;
        font-size: 3rem;
      }
    }

    #contentblock {
      .content-col {
        min-height: auto;
        height: 0;
      }
    }

    .header-content {
      display: none;
    }

    .config {
      position: fixed;
      bottom: 0;
      z-index: 999;
    }

    .footer {
      display: none;
    }
  }

  /** PHEV **/
  &--phev-event {
    color: #fff;
    h1,
    h2 {
      text-align: center;
    }
    p {
      font-size: 20px !important;
      text-align: center;
      font-weight: 200;
    }

    @include respond-below(desktop) {
      h1,
      h2 {
        font-size: 38px !important;
      }
      p {
        font-size: 12px !important;
      }
    }

    .group-paragraph-info {
      max-width: 900px;
      margin: 0 auto;
    }

    .webform-client-form {
      margin-top: 20px;
      z-index: 100;

      > div {
        display: grid;
        gap: 1.5rem;

        ::placeholder {
          color: #4f4f4f;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          color: #4f4f4f;
        }

        ::-ms-input-placeholder {
          color: #4f4f4f;
        }

        .webform-component {
          &--choose-date {
            border-radius: 3px;
            .form-select,
            .form-control,
            .chosen-container {
              border-radius: 0;
              border: none;
            }

            &:first-child {
              .form-control {
                border-radius: 3px 0 0 3px !important;
              }
            }
            &:nth-child(3) {
              .form-control {
                border-radius: 0 3px 3px 0 !important;
              }
            }
          }
        }

        @include respond-above(mobile) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          .form-actions {
            grid-column: 1 / 3;
            height: 54px;
            button {
              width: 30% !important;
              text-transform: uppercase;
            }
          }
        }

        .form-item {
          position: relative;
        }

        .form-type-select {
          border-radius: 3px;
          &::after {
            content: '\f107';
            position: absolute;
            right: 10px;
            z-index: 10;
            top: 15px;
            color: #4f4f4f;
            font-family: $font-family-icon-fa;
          }
          > select.form-control {
            border-radius: 3px;
            color: #4f4f4f;
            option:not(:first-of-type) {
              color: black;
            }
          }
        }

        > .form-item {
          .chosen-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .chosen-single {
              height: 100%;
              width: 100% !important;
              border: none;
              box-shadow: none;
              display: flex;
              align-items: center;
              background: none !important;
              > div,
              > span {
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                box-shadow: none;
                > b {
                  display: none;
                }
              }
            }
            .chosen-drop {
              z-index: 100;
            }
          }
          .form-control {
            position: relative;
            border-radius: 3px;
            height: 100%;
            min-height: 54px;
            font-size: 16px;
            font-family: $font-Poppins;
            background-color: rgba(255, 255, 255, 0.8) !important;
          }
          .webform-container-inline {
            display: flex;
            > div,
            > input {
              flex: 1;
            }
            &.webform-datepicker {
              border-radius: 3px;
              border: none;
              .form-item {
                .form-control {
                  border-radius: 0;
                  border: none;
                }
                &:first-child {
                  .form-control {
                    border-radius: 3px 0 0 3px;
                  }
                }
                &:nth-child(3) {
                  .form-control {
                    border-radius: 0 3px 3px 0;
                  }
                }
              }
            }
          }

          .webform-calendar {
            display: none;
          }
        }

        .form-group {
          margin-bottom: 0;
        }

        .form-actions {
          display: flex;
          justify-content: center;
          button {
            display: flex;
            margin: 0;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            text-align: center !important;
            @extend .btn;
            @extend .btn-primary;
          }
        }
      }
    }
  }

  /** Tritorn **/
  &--all-new-triton {
    .region-topimg {
      .block-fieldblock {
        ._form_10 {
          display: flex;
          justify-content: flex-end;
          font-family: $font-Poppins !important;
        }

        form {
          width: 100%;
          background: transparent !important;
        }

        .g-recaptcha {
          margin-top: 20px;
        }

        ._form-content {
          background: transparent !important;

          ._button-wrapper {
            margin-top: 20px;
            ._submit {
              border-radius: 3px !important;
            }
          }

          @include respond-above(desktop) {
            ._form-title {
              font-size: 50px !important;
              line-height: 1.2 !important;
            }
          }
        }

        #_form_64DED0DA5ACFD_,
        form {
          font-family: $font-Poppins !important;
          margin: 0 !important;
          background-color: transparent !important;

          label,
          input,
          ._form-label {
            font-family: $font-Poppins !important;
            font-weight: 300px !important;
          }

          input {
            padding: 18px 12px !important;
          }

          ._submit {
            border-radius: 0 !important;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}
