.page-what-mitsubishi-person-are-you,
.page-how-many-people-will-share-your-vehicle,
.page-how-will-you-use-your-vehicle,
.page-what-more-important-in-your-mitsubishi,
.page-how-much-are-you-willing-to-pay {
  #contentblock {
    padding-bottom: 4rem;
  }

  .page-title {
    .page-header {
      text-align: center;
      @include respond-above(tablet) {
        font-size: 45px;
        line-height: 1.4em;
      }
      &:after {
        display: none;
      }
    }
    margin-bottom: 0;
  }

  .limit_1,
  .limit_2 {
    @include wrapper(relative, 100%);
    display: block;
    text-align: center;
    font-size: 30px;
    text-align: center;
    margin-bottom: $grid-gutter-width;
    color: #c0c0c0;

    @include respond-below(mobile) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
      font-size: em(20);
    }
  }

  form {
    @include wrapper(relative, 100%);
    margin-top: $grid-gutter-width/2;

    > div {
      @include wrapper(relative, 100%);
      @include flex-container(column, wrap, flex-start, center);

      .form-item {
        @include wrapper(relative, 100%);
      }

      .btn {
        &.form-submit {
          @extend .btn-red;
          outline: none;
          min-width: em(150);
          margin-top: $grid-gutter-width;
          @include respond-above(mobile) {
            min-width: 222px;
          }
        }
      }
    }

    // Start
    .form-checkboxes,
    .form-radios {
      @include wrapper(relative, 100%);
      @include flex-container(row, wrap, flex-start, stretch, center);

      label {
        @include flex-container(column, wrap, flex-start, stretch, center);
        @include myanimated;
        position: relative;
        width: 50%;
        margin: 0;
        padding: 0;
        border: 0px solid transparent;
        cursor: pointer;

        @include respond-above(tablet) {
          //width: 50%;
        }

        // Selected ------------------------------------------------------------------------------
        &.selected {
          &::before {
            opacity: 1;
          }

          .field-item {
            &::before {
              border: 4px solid $yellow-0;
            }
          }
        }
        // --------------------------------------------------------------------------------------

        &::before {
          content: '';
          @include myanimated;
          display: block;
          @include border-radius(100%);

          @include wrapper(absolute, 30px, 30px);
          background: url('../images/icons/icon-check-w.svg') center center
            no-repeat $yellow-0;
          background-size: 25px auto;

          @include respond-above(mobile) {
            @include wrapper(absolute, 50px, 50px);
            background: url('../images/icons/icon-check-w.svg') center center
              no-repeat $yellow-0;
            background-size: 40px auto;
          }

          top: 0;
          right: 0;
          z-index: 10;
          margin: 15px 15px 0 0;
          opacity: 0;

          @include respond-above(mobile) {
            width: 54px;
            height: 54px;
            background-size: 40px auto;
          }
        }
      }

      .field-name-title {
        @include wrapper(absolute, 100%);
        bottom: 0;
        left: 0;
        z-index: 10;
        margin-bottom: $grid-gutter-width;

        h2 {
          @include wrapper(relative, 100%);
          font-size: em(15);
          margin: 0;
          padding: 0;
          color: $white;
          text-align: center;
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
          @include respond-above(mobile) {
            font-size: 40px;
          }
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      .field-name-field-quiz-img {
        display: block;

        .field-items,
        .field-item {
          @include wrapper(relative, 100%);
          display: block;
        }

        .field-item {
          &::before {
            @include myanimated;
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            border: 0px solid transparent;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) 5.96%,
              rgba(0, 0, 0, 0) 57.89%
            );
          }
        }

        img {
          position: relative;
          width: 100%;
          height: 50vw;

          @include object-fit(cover);
          object-position: center center;
          @include respond-above(mobile) {
            height: 311px;
          }
        }
      }
    }
  }
}
