.finance-calculator{
    
    .field-name-field-title{
        h2{
            color: $heading-color !important;
            font-size: 50px;
            text-align: center;
            margin: 0;
            
            @include respond-above(tablet){
                font-size: 100px !important;
            }
        }
    }

    .field-name-paragraph-link-url{
        position: relative;
        margin-bottom: $grid-gutter-width;
        .field-item{
            text-align: center;
            .field-link{
                @extend .btn-red;
            }
        }
    }

}