.region{
    &.region-slidemenu{
        margin-top: 20px;
        padding-bottom: 80px;
        
        @include respond-above(tablet){
            margin-top: $header-min-height; 
            padding-bottom: 50px;          
        }
                     
        .mainmenu{
            @include wrapper(relative, 100%);
            .menu{
                @include wrapper(relative, 100%);
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    
                    list-style: none;
                    padding: 0;
                    margin-bottom: $grid-gutter-width/3;
                    @include wrapper(relative, 100%);
                    @include respond-above(tablet){
                        margin-bottom: $grid-gutter-width/2;
                    }

                    a{
                        position: relative;
                        color: $white;
                        font-size: 20x;
                        width: auto;
                        font-weight: normal;
                        font-family: $headings-font-family;
                        @include myanimated;
                        @include respond-above(tablet){
                            font-size: 25px; 
                        }

                        &::after{
                            content: "";
                            position: relative;
                            width: 0px;
                            height: 4px;
                            background-color: $white;
                            display: block;
                            @include myanimated;
                        }

                        &:hover,
                        &:active,
                        &:focus{
                            @include myanimated;
                            text-decoration: none;
                            &::after{
                                @include myanimated;
                                width: 46px;
                            }
                        }
                    }
                    
                }
            }
        }


        


        
    }
}