.logged-in{ // IF LOGGED IN

    .block-koda-node-clone{
        @include wrapper(relative, 100%);
        margin-bottom: $grid-gutter-width;
        .koda-show-clone-form{
            @extend .btn;
            font-size: 14px;
            border: 1px solid $gray-light-0;
            //@extend .btn-red;
        }
    
        .koda-clone-form-content{
            top: 0;
            left: 0;
        }
    
    }

    .koda-fullwidth{
        .block-koda-node-clone{
            @extend .fluid-container;
        }
    }

}

