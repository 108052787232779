.region-topmenu{
    @include wrapper(relative, 100%);
    @include flex-container(row, wrap, center, center, center);
    padding: 0 10px;

    @include respond-below(tablet) {
        z-index: 998;
    }

    section{
        &.block-block{
            @include flex-container(column, wrap);
            flex: 0 0 auto;
            width: auto;
            p{
                margin-bottom: 0;
            }
            &.header-left-logo{
                position: relative;
                @include respond-above(tablet){
                    position: absolute;
                    left: 0;
                    top:0;  
                    margin-top: -10px;               
                }
                img{
                    height: 50px;
                    @include respond-above(tablet){
                        height: 71px;
                        margin: -10px 10px 0 10px;
                    }
                }               
            }

            &.header-logo{              
                @include respond-below(tablet){
                    margin-right: auto;
                    img{
                        height: 20px;
                        margin-left: 10px;
                    }
                }
                &.prestige-logo{
                    @include respond-below(desktop){
                        img{
                            height: 50px;
                        }                           
                    }
                    @include respond-below(mobile){
                        img{
                            height: 28px;
                            margin-left: 0;
                        }
                    }                   
                }
            }

            &.header-contact-phone{              
                position: absolute;
                right: 0;
                top:0;
                height: 100%;
                color: $white;
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                margin: 0px 75px 10px 10px; 
                @include flex-container(column, wrap, center, center, center);

                .koda-block-container{
                    @include flex-container(row, wrap, center, center, center);

                    a{
                        @include flex-container(row, wrap, center, center, center);
                        color: $white;
                        font-family: $font-Roboto;
                        &:hover{
                            color: $primary-color;
                        }

                        &.phone{      
                            @include respond-below(tablet){
                                width: 30px;
                                overflow: hidden;
                                font-size: 0px;
                                color: transparent;
                            }
                            
                            &::before{
                                position: relative;
                                content: "";
                                width: 25px;
                                height: 25px;
                                background: url('../images/icons/icon-phone.svg') center center no-repeat;
                                margin-right: em(4);    
                                margin-top: 0;                            
                               
                            }                        
                        }

                        &.marker{
                            @include respond-above(tablet){
                                display: none;
                            }
                            width: 25px;
                            height: 25px;
                            background: url('../images/icons/icon-marker.svg') center center no-repeat;  
                            background-size: auto 100%;                      
                        }

                    }


                }

                
            }

        }
    }
}