.node-accessories{

    /** Accessories Wrapper **/
    .accs{

      &--headers{
        width: 100%;
        text-align: center;
        padding: 2rem 0;
      }

      &--price-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5rem 0;
        font-weight: normal;
        color: #000;
      }

      &--name{
        width: 100%;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        color: #000;
      }

    }

    &.view-mode-build_your_mitsubishi{
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap);
        @include respond-above(tablet){
            flex-direction: row;
        }

        /** Fix **/
        .contextual-links-wrapper{
          .contextual-links li a{
            font-size: 14px;
          }
        }

        // h2{
        //   font-size: 24px;
        //   font-weight: normal;
        //   text-align: center;
        //   margin: 0;
        //   padding: $grid-gutter-width/2;
        //   line-height: 1.4em;
        //   @include wrapper(relative, 100%);
        // }

        //font-size: 1px;
        //margin-bottom: $grid-gutter-width;

        > .field{
            @include wrapper(relative, 100%);
            width: 100%;
            opacity: 1;

            &.field-name-field-accessory-image{
                img{
                    @include wrapper(relative, 100%, 243px);
                    @include object-fit(cover);
                }
            }

            &.field-name-field-sku{
                display: none;
            }
        }

    }


    &.view-mode-teaser{
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap);

        .field-items,
        .field-item{
            @include wrapper(relative, 100%);
        }



        .field-name-field-accessory-image{
            @include wrapper(relative, 100%, 243px);
            @include imgZoomHover;
            border: 1px solid $gray-light-0;
            img{
                @include wrapper(relative, 100%, 243px);
                @include object-fit(cover);
                object-position: center center;
            }
        }
        .field-name-title{
            @include wrapper(relative, 100%);
            h2{
                @include wrapper(relative, 100%);
                font-size: 24px;
                line-height: 1.4em;
                color: $black;
                text-align: center;
            }
        }
        .field-name-field-price{
            //display: none;
        }
    }




}
