body.path-frontpage{

  .hamburger{
    span{
      background-color: $white;
    }
  }

  &.collapsed{
    .hamburger{
      span{
        background-color: $white;
      }
    }
  }

}

.hamburger {  
    // adapted from https://codepen.io/erikterwan/pen/EVzeRP
    position: fixed;
    height: 25px;
    width: 35px;
    right: 20px;
    //top: 25px;
    z-index: 999;
    -webkit-user-select: none;
    user-select: none;   
    @include flex-container(column, wrap, space-between, center, center);

    span {
      display: none;
      height: 4px;
      border-radius: 4px;
      width: 100%;
      position: relative;
      z-index: 888;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;    
      background-color: $white;
    }

    input{
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        cursor: pointer;  
        opacity: 0; /* hide this */
        z-index: 999; /* and place it over the hamburger */ 
        -webkit-touch-callout: none;       
    }

    input:checked ~ span
    {
      &:nth-child(3) {
        opacity: 0;
      }
      &:nth-child(2){
        transform: rotate(45deg) translate(2px, 0);
        background-color: $white;
      }

      &:nth-child(4){
        transform: rotate(-45deg) translate(0, 0);
        background-color: $white;
      }
      
    } 

    input ~ .slide-menu{
      display: none;
      @include animateEffect(fadeIn,500ms);
    }

    input:checked ~ .slide-menu{
      transform: none;
      //display: block;
      
    }

    > input ~ .slide-menu-overlay {
      @include animateEffect(fadeIn,500ms);
    }

    > input:checked ~ .slide-menu-overlay {
      display: block;
    }
}
