footer{
    &.footer{

        background-image: url('../images/bg-footer.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        .footer-content{
            @include flex-container(column, wrap);
            @include respond-above(tablet){
                flex-direction: row;
            }
        }

        h4,
        h2.block-title {
            color: $white;
            font-size: em(15);
            margin-bottom: 20px;
            margin-top: 15px;
            font-weight: 400;
        }

        p{
            font-size: 14px;
        }


        .block-menu{
           .menu{
               margin: 0;
               padding: 0;
               > li{
                margin: 0;
                padding: 0;
                background-color: transparent;

                &.active-trail{
                    > a{
                        color: $primary-color;
                    }
                }

                > a{
                    font-size: 14px;
                    padding: 0 0 14px 0;
                    background-color: transparent;

                    &:hover{
                        background-color: transparent;
                        color: $primary-color;
                    }

                }
               }
           }
        }


       
    }
}