/*------------------------------------*\
  Pop up modal
\*------------------------------------*/
.car-inlin-acc-popup {
  @include flex-container(column, wrap);
  padding-top: $grid-gutter-width/2;

  .car-inlin-acc-popup-desc {
    /** modal desc price **/
    h4 {
      &.accs--price {
        color: #000;
        font-weight: normal;
        font-size: 2rem;
        &::before {
          content: "Price: ";
        }
      }
    }
  }

  .car-inlin-acc-popup-img {
    @include flex-container(column, wrap, center, center, center);
    width: 100%;
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: 30rem;
      object-fit: cover;
    }
  }
  .car-inlin-acc-popup-info {
    @include flex-container(column, wrap);
    flex: 1;
    padding: 0;
    .car-inlin-acc-popup-title {
      @include wrapper(relative, 100%);
      h2 {
        font-size: 40px;
        margin-top: 0;
        color: $heading-color;
        @extend .title-line;
        @include wrapper(relative, 100%);
      }
    }
  }

  // Responsive
  @include respond-above(xl) {
    flex-direction: row;
    .car-inlin-acc-popup-img {
      width: 50%;
      margin-bottom: 0;
      img {
        height: auto;
      }
    }
    .car-inlin-acc-popup-info {
      padding: 0 $grid-gutter-width;
    }
  }
}
