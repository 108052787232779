.page-summary{


    .page-title{
        display: none;
    }

    #contentblock{
        > .container-fluid{
            width: 100%;
            > .block-block{
                float: left;
            }
        }

        .build-car-summary{
          color: $default-color;
            // Info
            &__info{
                @include wrapper(relative, 100%);
                @include respond-below(wide){
                    padding-left: $grid-gutter-width/2;
                    padding-right: $grid-gutter-width/2;
                }
                padding-bottom: $grid-gutter-width/2;
                background: linear-gradient(360deg, rgba(196, 196, 196, 0.25) 2.86%, rgba(196, 196, 196, 0) 103.83%);
                .field-name-field-option-color-code,
                .field-name-field-option-color-name{
                    display: none;
                }
                /** Build option **/
                .build-option-links{
                  @include wrapper(relative, 100%);
                  display: flex;
                  .header-buttons{
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 0;
                    margin-top: 1rem;
                    @include respond-below(sm){
                      justify-content: center;
                    }
                    a{
                      margin-top: 0;
                      padding: 0 5px;
                      font-size: 1.0rem;
                      &::before{
                        width: 4.5rem;
                        height: 4.5rem;
                        border-width: 3px;
                      }
                      &.start-again{
                        &::before{
                          background-size: contain;
                        }
                      }

                    }
                  }
                }
            }

            // Message
            &__message{
              font-size: 18px;
              margin-top: 2rem;
              margin-bottom: 3rem;
            }
            // Sumamry List
            &__list{
              font-size: 18px;
              label{
                &.lbl-alert{
                  &::before{
                    content: "*";
                    color: $primary-color;
                  }
                }
              }
              ul{
                padding-left: 25px;
              }
            }
            // Info footer
            &__info-footer{
              @include respond-above(md){
                display: flex;
                justify-content: space-between;
                .build-option-links{
                  width: auto;
                }
              }
            }
            // Terms and condition
            &__tcs{
              font-size: 16px;
              display: flex;
              flex: 1;
              align-items: flex-end;  
              color: $default-color;
              > p{
                margin-bottom: 0;
                &::before{
                  content: "*";
                  color: $primary-color;
                }
              }
              @include respond-below(md){
                margin-bottom: 2rem;
              }
            }

            .build-car-summary-spec{
                @include wrapper(relative, 100%);
                @include respond-below(wide){
                    padding-left: $grid-gutter-width/2;
                    padding-right: $grid-gutter-width/2;
                }
                color: $gray-0 !important;
                background-color: $white;
                padding-top: 0;

                .global-title{
                    margin-top: $grid-gutter-width;
                    margin-bottom: $grid-gutter-width;
                }

                .accessories-tile{
                    @include flex-container(column, wrap, flex-start, stretch, center);
                    @include respond-above(tablet){
                        flex-direction: row;
                    }
                    > .cols{
                        @include flex-container(column, wrap, flex-start, stretch, center);
                        @include respond-above(normal){
                            width: calc(33.3% - 40px);
                            margin-left: 60px;
                            &:first-child,
                            &:nth-child(3n+1),
                            &.views-row-first{
                                margin-left: 0;
                            }
                        }
                        @include respond-between(tablet, normal){
                            width: calc(50% - 15px);
                            margin-left: 20px;
                            &:first-child,
                            &:nth-child(2n+1),
                            &.views-row-first{
                                margin-left: 0;
                            }
                        }
                    }
                }

                .spec-result{
                    @include wrapper(relative, 100%);
                    padding-bottom: $grid-gutter-width/2;
                }

            }
        }


        /*
        ======================================================
         Block: Summary - Color Name
        ======================================================
        */
        .field-collection-item-field-color-options{
          .field-name-field-option-color-name{
            //display: flex;
            display: none;
            justify-content: center;
            .field-item{
              margin: 15px 0;
              background-color: #FFF;
              padding: 0.5rem 2rem;
              border: 1px solid#d7d7d7;
              display: flex;
              align-items: center;
              font-weight: 500;
              color: #000;
              &::before{
                //content: url(../images/icons/icon-check-gr.svg);
                margin-right: 15px;
                content: "";
                width: 20px;
                height: 20px;
                background: url('../images/icons/icon-check-gr.svg') center center no-repeat;
                background-size: contain;
              }
            }
          }
        }


        /*
        ======================================================
         Block: Variant Price ( Header h3, h4)
        ======================================================
        */
        .model-variant{
          &--price{
            font-weight: 600;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &::after{
              content: "+ DC";
              margin-left: 8px;
              color: $primary-color;
            }
            /** RSP **/
            .rsp--val{
              &::before{
                content: "$";
                margin-right: 8px;
              }
            }
          }
        }

    }

}
