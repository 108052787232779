.region-footer-left{
    @include wrapper(relative, 100%);
    @include flex-container();
    @include respond-above(desktop){
        width: 49%;
    }

    > section {
        @include respond-above(tablet) {
            width: 33%;
            padding: 0 30px;
  
            &:first-child {
                padding-left: 0;
            }
        
            &:last-child {
                padding-right: 0;
            }
        }
    }
}