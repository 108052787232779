//: Is logged in 
//:-------------------------------------------------------------------------------------------------------------------------

.logged-in{

    .tabs--primary.nav.nav-tabs{
        margin-top: $grid-gutter-width/2;
        margin-bottom: $grid-gutter-width/2;
        @extend .container-fluid;
    }

    &.node-type-blog{
        .tabs--primary.nav.nav-tabs{
            @include wrapper(relative, 100%);
        }
    }

    .config{
        @extend .container-fluid;
    }

    > .action-links{
        @include wrapper(relative, 100%);
    }

    @media all and (max-width:1024px){
        .slicknav_menu{
            display: none;
        }
    }

    .block-koda-node-clone{
        margin-bottom: 40px !important;
    }

    .slide-menu .slide-contact{
        a.phone{
            margin-top: -42px;
        }
    }

}
//:-------------------------------------------------------------------------------------------------------------------------

.contextual-links-wrapper{
    font-weight: normal !important;
    font-size: 14px;
}