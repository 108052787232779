.tile-paragraph{
    //display: none;
    &:hover{
        .group-paragraph-info{         
            @include respond-above(mobile){
                opacity: 1;
            }
        }
    }

    .field-name-field-hero-media{
        @include wrapper(relative, 100%);
        .field-items,
        .field-item,
        .file-image,
        .content,
        .content a{
            @include wrapper(relative, 100%);
            img{
                position: relative;
                width: 100%;
                height: 35vh;
                //height: 40vh;                
                @include object-fit(cover, center top);
                                
                @include respond-above(tablet){
                    height: 280px;
                }
                @include respond-below(tablet){
                    min-height: 150px;
                }
            }
        }
    }
 
    .group-paragraph-info{
        @include myanimated;
        position: relative;
        width: 100%;
        //min-height: 360px;
        top: 0;
        left: 0;
        background-color: rgba(231, 41, 41, 0.9);
        padding: 20px 10px;
        
        @include respond-above(tablet){
            position: absolute !important;
            height: 100% !important;
            opacity: 0;
        }
        @include respond-below(tablet){
            //height: 30vh !important;
            height: 36vh !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-content: center;
        }
 
        h2{
            @include wrapper(relative, 100%);
            text-align: center;  
            font-size: 1.4em;          
            //font-size: 7.5vw;
            font-weight: 600;
            margin: 0;
            //margin: 40px 0 0 0 !important; 
            @include respond-above(mobile){
                font-size: 2.8vh;
            }          
        }
 
        p{
            @include wrapper(relative, 100%);           
            //font-size: 4.5vw;
            font-size: 0.7em;
            font-weight: normal;
            text-align: center;   
            @include respond-above(mobile){
                font-size: 1.8vh;
            }        
        }
 
        .field-name-field-desc{
             @include wrapper(relative, 100%);
             padding: 20px;
        }
 
        .field-name-paragraph-link-url,
        .field-name-field-link{
            @include respond-above(tablet){
                position: absolute !important;                            
                height: 100%;
                bottom: 0;
                left: 0;
            }
            text-align: center;
            width: 100%;
             
             .field-items,
             .field-item,
             .field-item a{
                @include respond-above(tablet){
                    @include wrapper(absolute, 100%, 100%);
                    top: 0;
                    left: 0;
                }
                text-align: center; 
                color: $white;
             }
                         
             a{
                @include flex-container(column, wrap, flex-end, center, center);
                z-index: 10;
                font-size: 1em;          
                //font-size: 5vw;
                font-weight: 600;
                padding-bottom: $grid-gutter-width/2;
                @include respond-above(mobile){
                    font-size: 1.8vh;
                } 
             }
             
        }
    }   
 
 }

 .slick-dots{
    li{
        button{
            width: 26px;
            height: 26px;
            ::before{
                font-size: 26px !important;
            }
        }
    }
}