
.view-four-column-tiles{  
    align-items: stretch;
    height: 100%;       
    @include wrapper(relative, 100%);  
    //:----------------------------------------------------------------------------------
    &.with-gap{
        > .field-items,
        .view-content{

            > .field-item,
            .views-row{
                margin-bottom: 20px;
                box-sizing: border-box;

                @include respond-above(normal){
                    
                    width: calc(25% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(4n+1),
                    &.views-row-first{ 
                        margin-left: 0;  
                    }                                     
                }
        
               
                @include respond-between(tablet, normal){
                    width: calc(50% - 15px);
                    margin-left: 20px;

                    &:first-child,
                    &:nth-child(2n+1),
                    &.views-row-first{ 
                        margin-left: 0;                                
                    }                                
                }
                
                
            }
            
        }
    }
     //:----------------------------------------------------------------------------------
   
    > .field-items,
    .view-content{

        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, stretch);
        height: 100%;
    
        > .field-item,
        .views-row{
        height: 100%;
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, flex-start, stretch);
        
        @include respond-above(normal){
            @include wrapper(relative, 25%);

            
            &:first-child,
            &:nth-child(4n+1),
            &.views-row-first{ 
                padding-left: 0;                                  
            }
            
            &:nth-child(4n),
            &.views-row-last{
                padding-right: 0;                        
            }
        }

        
    
        @include respond-between(tablet, normal){
            @include wrapper(relative, 50%);

            &:first-child,
            &:nth-child(2n+1),
            &.views-row-first{ 
                padding-left: 0;                                  
            }
            
            &:nth-child(2n),
            &.views-row-last{
                padding-right: 0;                        
            }
            
        }
        

        //:----------------------------------------------------------------------------------------
        }
    }


}
