.region-footer-left,
.region-footer-right {
  padding: $grid-gutter-width/2;
}

.region-footer-right {
  @include wrapper(relative, 100%);
  @include respond-above(desktop) {
    width: 50%;
  }
  display: flex;
  flex-wrap: wrap;

  @include respond-above(tablet) {
    > section {
      width: 50%;
    }
  }

  > section {
    @include respond-above(tablet) {
      &.footer-full {
        width: 100%;
      }

      &.footer-half {
        width: 50%;
      }
    }

    @include respond-below(desktop) {
      padding-top: 0;
    }

    .row {
      margin: 0;
      padding-bottom: 10px;
      > div {
        padding-left: 0;
        p {
          margin-bottom: 8px;
          > a {
            text-decoration: underline;
            &:hover {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
      }

      @include respond-below(tablet) {
        > div {
          padding-right: 0;
        }
      }
    }
  }

  .footer-contacts {
    text-align: center;
    display: flex;
    justify-content: center;

    @include respond-above(tablet) {
      align-items: flex-end;
      justify-content: flex-end;
    }

    > div {
      display: inline-block;
    }

    .footer-phone {
      font-size: 25px;

      @include respond-below(tablet) {
        font-size: 30px;
      }

      a {
        display: flex;
        align-items: center;
        color: $white;
        font-weight: bold;
        font-family: $font-theme-3;

        &:hover {
          text-decoration: none;
          color: $primary-color;
        }

        &:before {
          content: url(../images/icons/icon-phone.svg);
          margin-right: 2px;
          margin-top: 8px;
        }
      }
    }

    .footer-social-icons {
      @include respond-above(tablet) {
        text-align: right;
      }
    }

    @include respond-above(tablet) {
      text-align: right;
    }
  }
}
