/*------------------------------------*\
  Process
\*------------------------------------*/
.process{
    color: #000;
    .global-title{
        color: inherit;
        margin-bottom: 4rem;
    }
    > .koda-paragraph-inner-wrapper{
        padding: 0;
    }
    &__list{
        margin: 0;
        padding: 0;
        counter-reset: num;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > li{
            position: relative;
            padding: 1.5rem;
            color: inherit;
            font-weight: 600;
            font-size: 1.6rem;
            cursor: pointer;
            &:not(:first-child){
                border-top: 1px solid #EDEDED;
            }
            &::before{
                counter-increment: num;   
                content: counter(num) ".";
                color: #D5D5D5;
                margin-right: 1.5rem;
            }      
        }
        // Circle
        &--circle{
            position: relative;
            width: 30rem;
            height: 30rem;
            border-radius: 100%;
            border: 10px solid #ECECEC;
            background: linear-gradient(36.25deg, #0B0B0B 17.77%, rgba(11, 11, 11, 0.72) 86.43%);
            background-color: #FFF;
            margin: 0;
            padding: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > li{
                @include myanimated;
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                padding: 4rem;
                font-weight: normal;
                font-size: 1.2rem;
                text-align: center;
                list-style: none;
                color: #FFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0;
                visibility: hidden;
                top: 0;
                left: 0;
                &.selected{
                    opacity: 1;
                    visibility: visible;
                }
                strong{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    font-weight: 600;
                    font-size: 1.8rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .field-name-field-desc{
        .field-item{
            display: flex;
            justify-content: space-between;        
        }
    }
    // Responsive
    @include respond-below(lg){
        .field-name-field-desc .field-item{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        padding-left: 2rem;
        padding-right: 2rem;
        &__list{
            margin-bottom: 4rem;
            > li{
                &.selected{
                    color: $brand-primary;
                }
            }
        }
    }
    @include respond-above(lg){
        &__list{
            > li{
                padding: 1.8rem 0 1.8rem 2.5rem;
                font-size: 2.5rem;
                &.selected,
                &:hover{
                   border-color: transparent;
                   &::after{
                    width: 320%;
                    opacity: 1;
                    background: linear-gradient(90deg, #EEEEEE 0.86%, rgba(196, 196, 196, 0) 88.57%);
                   }
                }
                &::after{
                    @include myanimated;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 0%;
                    height: 100%;
                    z-index: -1;
                }
            }
            &--circle{
                width: 54.4rem;
                height: 54.4rem; 
                > li{
                    padding: 4rem 7.5rem;
                    font-size: 2rem;  
                    strong{
                        font-size: 4.5rem; 
                    }
                }
            }
        }
       
    }
    @include respond-between(sm, lg){
        &__list{
            > li{
                font-size: 2rem; 
            }
            &--circle{
                width: 48rem;
                height: 48rem;  
                > li{
                    font-size: 2rem;  
                    strong{
                        font-size: 3rem; 
                    }
                }
            }
        }
    }
    @include respond-between(sm, max){
        padding-left: 2rem;
        padding-right: 2rem;
        > .koda-paragraph-inner-wrapper{
            padding: 0 !important;
        }
    }
}