.hp-featured-video {
  @extend .screenh100;
  display: flex;
  align-items: center;
  padding: $grid-gutter-width $grid-gutter-width/2;
  height: auto;

  .koda-paragraph-outer-wrapper {
    padding: $grid-gutter-width/2 0;
    @include respond-below(tablet) {
      height: 100% !important;
      justify-content: center;
    }
  }

  .field-name-field-title {
    h2 {
      position: relative;
      font-size: em(30);
      margin: 0 0 em(5) 0;
      padding: 0;
      @include respond-above(desktop) {
        font-size: em(55);
        margin: 0 0 10px 0;
      }
    }
  }
  .field-name-field-sub-title {
    h3 {
      position: relative;
      font-size: em(20);
      margin: 0 0 em(20) 0 !important;
      @include respond-above(desktop) {
        font-size: em(30);
        margin: 0 0 em(20) 0 !important;
      }
    }
  }

  .field-name-field-desc {
    margin-bottom: em(20);
  }

  .field-name-field-title,
  .field-name-field-sub-title {
    @include respond-below(tablet) {
      padding-left: $grid-gutter-width/2 !important;
      padding-right: $grid-gutter-width/2 !important;
      h2,
      h3 {
        text-align: center;
      }
    }
  }
  &.paragraphs-item-koda-basic-paragraph .koda-paragraph-inner-wrapper {
    @extend .fluid-container;
  }
  .field-name-field-media {
    .flexslider {
      padding: 0;
      margin: 0;
      border: none;
    }
    .field-items {
      @include wrapper(relative, 100%);
      .field-item {
        @include wrapper(relative, 100%);
      }
    }
  }
  .file-video {
    @include wrapper(relative, 100%);
    margin: 0 auto;
    @include respond-above(wide) {
      width: 1170px;
    }
  }
  .media-youtube-video {
    @include wrapper(relative, 100%);
    background-color: $black;
    margin: 0 auto;
    iframe {
      position: relative;
      width: 100%;
      height: 42vh;
      @include respond-above(tablet) {
        height: 60vh;
      }
    }
  }
  .video-modal-image {
    position: relative;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    height: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 42vh;
      @include object-fit(cover);
    }
    @include respond-above(tablet) {
      padding-left: 0;
      padding-right: 0;
      img {
        height: 60vh;
        width: 90%;
      }
    }
    &:before {
      position: absolute;
      content: '\f04b';
      font-family: $font-family-icon-fa;
      margin: auto;
      z-index: 50;
      color: $primary-color;
      font-size: 10rem;
    }
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      &:before {
        display: none;
      }
    }
  }
}
