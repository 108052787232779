.node-home-slider {
  &.view-mode-full {
    @extend .screenh100;

    > .paragraphs-items {
      @extend .screenh100;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .field-name-field-paragraphs,
      > .field-name-field-paragraphs > .field-items,
      > .field-name-field-paragraphs > .field-items > .field-item {
        @extend .screenh100;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        /** for Basic Paragraph Only **/
        > .paragraphs-item-koda-basic-paragraph {
          @extend .screenh100;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 2rem;
          padding-right: 2rem;
          justify-content: center;
          padding-top: 8rem;
          padding-bottom: 8rem;

          @include respond-below(md) {
            .field-name-field-desc {
              .field-item {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .paragraphs-items {
      .two-col-img-text {
        .par-img {
          @extend .screenh100;
          @include wrapper(relative, 100%);
          @extend .img-gradient-cover;
          padding-left: 0;
          padding-right: 0;
          flex: auto;

          img {
            @extend .screenh100;
            @include object-fit(cover, center);
            border: 0;
          }
        }

        .par-content {
          .group-paragraph-info {
            color: inherit;
            h2,
            h3 {
              @include text-shadow;
              color: inherit;
            }
            h2 {
              margin: 0;
            }
            h3 {
              margin: 0 0 0.2em 0;
            }
            p {
              @include text-shadow;
              color: inherit;
            }
          }
        }

        .field-type-file {
          @include wrapper(relative, 100%);
          margin-bottom: 1.1em;

          &::before {
            @include wrapper(absolute, 100%, 100%);
            content: '';
            top: 0;
            left: 0;
            z-index: 10;
          }
        }

        .wrap-center {
          .group-paragraph-info {
            text-align: center;
          }
        }
      }

      .img100-text100,
      .img100-text50,
      .text50-img100 {
        .par-inner-layer {
          > .paragraphs-items {
            @include wrapper(relative, 100%, 100%);

            padding-top: 90px;
            padding-bottom: 80px;

            @include respond-above(desktop) {
              padding-top: 80px;
              padding-bottom: 80px;
            }

            > .field-name-field-desc-basic-paragraph {
              @include wrapper(relative, 100%, 100%);

              > .field-items {
                @include wrapper(relative, 100%, 100%);
                > .field-item {
                  @include wrapper(relative, 100%, 100%);

                  > .paragraphs-item-koda-basic-paragraph {
                    @include wrapper(relative, 100%, 100%);
                  }

                  > .paragraphs-item-koda-basic-paragraph
                    > .koda-paragraph-outer-wrapper {
                    @include wrapper(relative, 100%, 100%);
                    justify-content: center;

                    > .koda-paragraph-inner-wrapper {
                      > .group-paragraph-info {
                        @include respond-below(tablet) {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } // End

      // Option : Slider Position
      .content-top {
        .koda-paragraph-outer-wrapper {
          justify-content: flex-start !important;
        }
      }

      .content-middle,
      .content-center {
        .koda-paragraph-outer-wrapper {
          justify-content: center !important;
        }
      }

      .content-bottom {
        .koda-paragraph-outer-wrapper {
          justify-content: flex-end !important;
        }
      }

      .field-name-paragraph-link-url {
        margin-top: $grid-gutter-width/2;
        margin-bottom: $grid-gutter-width/2;
        .field-link {
          @extend .btn-red;

          @include respond-below(tablet) {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

/*
======================================================
 Block: Home Slider Special : content as image
======================================================
*/
.home-slider {
  &--special {
    img {
      width: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    &.left {
      img {
        margin: 0;
      }
    }

    &.right {
      img {
        margin: 0 0 0 auto;
      }
    }
  }
}
