/*------------------------------------*\
  File: New Cars
\*------------------------------------*/
.new-cars{
  // custom 2 column content
  &--2col-content{
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width;
    &.koda-multi-cols-2-column{

      @include respond-between(mobile,max){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }

      @include respond-above(lg){
        .koda-inner-column-paragraphs{
          > .paragraphs-items{
            > .column{
                &:first-child{
                  flex: 1;
                }
                &:nth-child(2){
                  flex: 0 0 400px;
                }
            }
          }
        }
      }

      @include respond-below(lg){
        .koda-inner-column-paragraphs{
          > .paragraphs-items{
            > .column{
                &:first-child{
                  margin-bottom: $grid-gutter-width/2;
                }
              }
            }
          }
      }


    }

  }
}

/*
======================================================
 Block: Take the quiz
======================================================
*/
.take-the-quiz{
  .group-paragraph-info{

    @include imgZoomHover;

    &:hover{
      > .field-name-field-desc{
        p{
          > u{
            text-decoration: none;
          }
        }
      }
    }

    > .field-name-field-desc{
      position: absolute !important;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 10;
      padding: 4rem;
    }

    > .field-name-paragraph-link-url{
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 20;

      .field-items,
      .field-item{
        display: flex;
        align-items: stretch;
        height: 100%;
        width: 100%;
      }
      a.field-link{
        width: 100%;
        font-size: 0;
      }
    }

  }


  h2{
    @include myanimated;
    margin-top: 0;
    color: #FFF;
    font-size: 36px;
    text-align: center;
  }

  p{
    @include myanimated;
    font-size: 24px;
    text-align: center;
    color: #FFF;
    font-weight: 600;
    margin-bottom: 0;
    u{
      color: #DE1A22;
    }
  }

}
