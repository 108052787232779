//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin respond-above($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($grid-breakpoints, $breakpoint) {
  
      // Get the breakpoint value.
      $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
  
      // Write the media query.
      @media all and (min-width: $breakpoint-value) {
        @content;
      }
  
      // If the breakpoint doesn't exist in the map.
    } @else {
  
      // Log a warning.
      @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
  }

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

  @mixin respond-below($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($grid-breakpoints, $breakpoint) {
  
      // Get the breakpoint value.
      $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
  
      // Write the media query.
      @media all and (max-width: ($breakpoint-value - 1)) {
        @content;
      }
  
      // If the breakpoint doesn't exist in the map.
    } @else {
  
      // Log a warning.
      @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
  }


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

  @mixin respond-between($lower, $upper) {

    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
  
      // Get the lower and upper breakpoints.
      $lower-breakpoint: map-get($grid-breakpoints, $lower);
      $upper-breakpoint: map-get($grid-breakpoints, $upper);
  
      // Write the media query.
      @media all and (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
        @content;
      }
  
      // If one or both of the breakpoints don't exist.
    } @else {
  
      // If lower breakpoint is invalid.
      @if (map-has-key($grid-breakpoints, $lower) == false) {
  
        // Log a warning.
        @warn 'Your lower breakpoint was invalid: #{$lower}.';
      }
  
      // If upper breakpoint is invalid.
      @if (map-has-key($grid-breakpoints, $upper) == false) {
  
        // Log a warning.
        @warn 'Your upper breakpoint was invalid: #{$upper}.';
      }
    }
  }
  


