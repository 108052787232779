.node-type-deal-range{

    .field-name-body{
        @extend .section-fluid;
    }

    .field-name-field-business{
        h2, h3, h4{
            color: $heading-color;
        }
    }

    .latest-deals-gallery{
        .slick-dots{
            margin-top: $grid-gutter-width/2;
        }
    }

}