.default-page-paragraphs{

    //Image Description Paragraph
    .paragraphs-item-koda-image-desc{
        align-items: flex-start !important;        
        
        .par-content{
            @include respond-above(tablet){
                padding-right: $grid-gutter-width*2;
            }
        }
 
    }

}


.type-content-1{

    h2, h3{
        @include wrapper(relative, 100%);
        font-size: 40px;
        margin-bottom: 0 !important;
        color: $heading-color !important;
    } 

    .field-name-field-desc{
        margin-top: $grid-gutter-width/2;
    }

}