.build-your-car-heading{
    text-align: center;
    margin-bottom: 0;
    padding-top: $grid-gutter-width;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include respond-above(wide){
        padding-left: 0;
        padding-right: 0;
        margin-bottom: $grid-gutter-width*2;
    }
    
    .koda-header-container{
        @include wrapper(relative, 100%);
        h2{
            
            margin: 0;
            padding: 0;
            text-align: center;
            @include wrapper(relative, 100%);
            @include respond-above(tablet){
                font-size: 100px;
            }
        }
    }

    .koda-block-container{
        @include wrapper(relative, 100%);
        text-align: center;

        h4{
            @include wrapper(relative, 100%);
            font-weight: normal;
            font-size: 25px;
            color: #8D8D8D;
            padding: $grid-gutter-width/2 0;
        }

        ul{

            //&.contextual-links{}

            &:not(.contextual-links){

                @include respond-above(tablet){
                    @include flex-container(row, wrap, center, stretch, center);
                }
                display: none;
                list-style: none;
                padding: 0;
                margin: $grid-gutter-width 0 0 0;

                
    
                li{
                    @include flex-container(column, wrap, flex-end, stretch, center);
                    font-size: 27px;
                    color: $black;
                    margin-bottom: 20px;
                    margin-left: 15px;
                    margin-right: 15px;
                    text-align: center;

                    border-bottom: 8px solid transparent;

                    a{
                        text-decoration: none;
                        color: $gray-12;
                        &:hover{
                            color: $black;
                        }
                    }
    
                    &.current{
                        border-bottom: 8px solid $primary-color;
                    }                
    
                    &:not(.current){
                        
                        a{
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            &::before{
                                content: url('../images/icons/icon-check-gr.svg');
                                align-items: center;
                            }
                        }
                    }
    
                }

            }


        }
    }
}