.paragraph{

    &.ds-2col-8-4{
        .group-left{
            @include wrapper(relative,100%);      
            @include respond-above(tablet){
                @include flex-element(0,0,60%);
                @include wrapper(relative,60%);   
            }
        }
        .group-right{
            @include wrapper(relative,100%);      
            @include respond-above(tablet){
                @include flex-element(0,0,40%);
                @include wrapper(relative,40%); 
            }
        }
    }

    &.ds-2col-4-8{
        .group-left{
            @include wrapper(relative,100%);      
            @include respond-above(tablet){
                @include flex-element(0,0,40%);
                @include wrapper(relative,40%);   
            }
        }
        .group-right{
            @include wrapper(relative,100%);      
            @include respond-above(tablet){
                @include flex-element(0,0,60%);
                @include wrapper(relative,60%); 
            }
        }
    }

    &.ds-2col-equal-width{
        .group-left,
        .group-right{  
            @include wrapper(relative,100%);            
            @include respond-above(tablet){
                @include flex-element(0,0,50%);
                @include wrapper(relative,50%);              
            }
            
        }
    }

   

    &.ds-2col-equal-width,
    &.ds-2col-stacked-equal,
    &.ds-2col-stacked{

        h2, h3, h4{
            margin-top: 0;
        }

        @include wrapper(relative,100%);
        @include flex-container(column, wrap);

        @include respond-above(tablet){
            @include flex-container(row, wrap);
        }

        margin-bottom: $grid-gutter-width/2;

        .field{
            @include wrapper(relative,100%); 
            @include flex-container(column, wrap);
            text-align: left;
        }

        &.swap-left-right{
            .group-left{               
                @include respond-above(tablet){
                    order: 1;
                    padding-left: $grid-gutter-width/2;
                }
            } 
            .group-right{
                @include respond-above(tablet){
                    order: 0;
                    padding-right: $grid-gutter-width/2;
                }
            }
            .group-footer{
                @include respond-above(tablet){
                    order: 3;
                }
            }
        }

        &:not(.swap-left-right){
            .group-left{
                @include respond-above(tablet){
                    padding-left: 0;
                    padding-right: $grid-gutter-width/2;
                }
            }
            .group-right{
                @include respond-above(tablet){
                    padding-left: $grid-gutter-width/2;
                    padding-right: 0;
                }
            }
        }
            
        
        
        .group-header,
        .group-footer{
            @include wrapper(relative,100%);
            @include flex-element(0,1,100%);
            flex: 1;
            //h2, h3{ margin-top: 15px;}
        }


    }

}