/*------------------------------------*\
  Nav Menu Teaser Format
\*------------------------------------*/
.nav-menu_teaser{
    @include wrapper(relative, 100%);
    @include flex-container(column, wrap, flex-start, stretch, center);
    height: 100%;
    .field-name-menu-title{
        @include wrapper(relative, 100%);    
    }
    .field-items,
    .field-item{
        @include wrapper(relative, 100%);
        display: block;
    }
    .field-name-field-menu-icon,
    .field-name-field-hero-image{ 
        @include wrapper(relative, 100%);      
        .field-item a{
            @include wrapper(relative, 100%);  
        }   
        img{
            position: relative;
            width: 100%;
            height: em(200);
            @include object-fit(cover);
        }
    }
    .lucas-menu-title-wrapper{           
        @include wrapper(relative, 100%);
        color: $white;
        text-align: center;
        padding-top: em(15);
        padding-bottom: em(15);
        min-height: 120px;
        a.lucas-menu-title{
            @include wrapper(relative, 100%);
            @include text-shadow;
            font-size: em(16);
            line-height: 1.1em;
            font-weight: 300;
            color: $white;
            display: inline-block;
            white-space: normal; 
            text-decoration: none; 
            @include respond-above(wide){
                font-size: em(25);
                font-weight: 600;
            }
            &:hover{
                color: $primary-color;
            }
        }
    }
    .lucas-menu-readmore-wrapper{
        @include wrapper(relative, 100%);
        text-align: center;
        padding-top: em(10);
        padding-bottom: em(10);
        .lucas-menu-readmore{
            @extend .btn-dark;
            text-transform: capitalize !important;
            display: inline;
            margin: 0 auto;
            font-weight: 600;
            padding: em(4) em(15) !important;
            @include respond-below(desktop){
                font-size: em(10) !important;
                font-weight: 300;
            }
            
        }
    }
}