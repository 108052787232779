/*------------------------------------*\
 Node : Car
\*------------------------------------*/

.node-car {
  // Promoted Teaser : nav
  &.view-mode-menu_teaser {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    height: em(380);
    @include myanimated(500ms);
    cursor: pointer;
    @include respond-above(wide) {
      padding-left: em(10);
      padding-right: em(10);
    }
    .fields,
    .field {
      @include wrapper(relative, 100%);
    }
    .group-teaser-info {
      @include animateEffect(fadein, 800ms);
      @include wrapper(relative, 100%);
      display: none;
      @media screen and (min-width: 2000px) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
      }
    }
    // Image
    .field-name-field-menu-icon {
      height: 300px;
      transition: height 500ms;
      > .field-items {
        width: 100%;
        margin: 0 auto;
        transition: height 1s;
        img {
          //width: 90%;
          margin: 0 auto;
          transition: height 1s;
          max-height: 318px;
        }
      }
    }
    // Title
    .field-name-title {
      h4 {
        @include wrapper(relative, 100%);
        text-align: center;
        color: $white;
        font-size: em(20);
        text-align: center;
        @include text-shadow;
        @include respond-above(wide) {
          font-size: em(25);
        }
      }
    }
    // Price
    .field-name-field-from-price {
      @include wrapper(relative, 100%);
      display: inline-flex;
      align-content: center;
      justify-content: center;
      margin-bottom: $grid-gutter-width/2;
      .field-label,
      .field-items {
        display: inline;
        color: #ffda1a;
        font-weight: 600;
        text-align: center;
        @include text-shadow;
        @include respond-below(wide) {
          font-size: em(14);
        }
      }
    }
    // Text
    .field-type-text-with-summary {
      padding: 0 $grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width/2;
      p {
        text-align: center;
        font-weight: normal;
        color: $white;
        margin-bottom: 0;
        @include respond-below(wide) {
          font-size: em(12);
        }
        @include text-shadow;
      }
    }
    // Button Link
    .field-name-node-link {
      text-align: center;
      margin-bottom: 10px;
      a {
        @extend .btn-red;
        display: inline;
        margin: 0 auto;
      }
    }
    // Background
    &:before {
      content: ' ';
      position: absolute;
      left: auto;
      right: auto;
      margin-left: auto;
      margin-right: auto;
      bottom: 0;
      margin-bottom: 15px;
      background-color: transparent;
      transition: background-color 500ms;
      border-radius: 10px;
      width: 100%;
      height: calc(100% - 40px);
      @include respond-above(wide) {
        width: 90%;
      }
      //margin-top: 30px;
    }
    //Hover
    &:hover {
      align-content: flex-end;
      &:before {
        background-color: #3a3a3a;
      }
      .group-teaser-info {
        max-height: 300px;
        overflow: visible;
      }
      .field-name-field-menu-icon {
        height: em(150);
        > .field-items {
          width: 65%;
        }
      }
      .group-teaser-info {
        display: block;
      }
    }
    // End hover
  }
  // Promoted Teaser : Prev Style
  &._view-mode-menu_teaser {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    @include myanimated(500ms);
    cursor: pointer;
    //height: em(380);
    //margin-top: em(40);
    //background-color: yellowgreen;
    .field,
    .fields {
      @include wrapper(relative, 100%);
      @include flex-container(column, wrap, center, stretch, center);
      height: 100%;
      color: $white;
      a {
        color: $white;
      }
    }
    .field-name-field-menu-icon {
      height: 220px;
      transition: height 500ms;
      > .field-items {
        width: 85%;
        margin: 0 auto;
        transition: height 1s;
        img {
          width: 100%;
          transition: height 1s;
        }
      }
    }
    .field-name-title {
      h4 {
        color: $white;
        font-size: em(20);
        text-align: center;
        //font-weight: 600;
        @include respond-above(wide) {
          font-size: em(28);
        }
      }
    }
    .field-type-text-with-summary {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      p {
        text-align: center;
        margin: 10px auto 20px;
        font-weight: normal;
        font-size: em(12);
      }
    }
    .field-name-field-from-price {
      align-items: center;
      color: #ffda1a;
      font-weight: 600;
      font-size: em(14);
    }
    .group-teaser-info {
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms;
    }
    .field-name-body,
    .field-name-node-link {
      display: none;
    }
    .field-name-node-link {
      a {
        @extend .btn-red;
      }
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      background-color: transparent;
      transition: background-color 500ms;
      border-radius: 10px;
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 30px;
    }
    &:hover {
      &:before {
        background-color: #3a3a3a;
      }
      .group-teaser-info {
        max-height: 300px;
        overflow: visible;
      }
      .field-name-field-menu-icon {
        height: 150px;
        > .field-items {
          width: 65%;
        }
      }
      .field-name-node-link,
      .field-name-body {
        display: flex;
      }
    }
  }

  /*------------------------------------*\
    Node : Full mode
  \*------------------------------------*/
  &.view-mode-full {
    // New Car - New Layout

    .field-name-body,
    .field-name-field-desc {
      font-size: 1.8rem;
      a {
        color: $primary-color;
      }
    }

    .koda-multicol {
      .new-car__content {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .new-cars-paragraphs {
      padding-bottom: 0;
    }

    .new-car {
      &__clean-car-fee-default {
        background-color: #f8f8f8;
        @include extendbg(#f8f8f8);
      }

      &__clean-car-fee {
        background-color: #f8f8f8;
        @include extendbg(#f8f8f8);

        a {
          color: #00d315;
        }

        .new-car__content {
          .group-paragraph-info {
            > .field-name-field-media {
              order: 0;
              display: flex;
              justify-content: center;
              img {
                width: auto;
              }
            }
            > .field-name-field-desc {
              order: 1;
            }
          }
          /** Responsive **/
          @include respond-below(tablet) {
            padding-left: 30px !important;
            padding-right: 30px !important;
          }
        }
      }

      &__content {
        @extend .fluid-container;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 8rem;
        padding-bottom: 8rem;

        h2 {
          font-size: 28px;
        }

        .field-name-body,
        .field-name-field-desc {
          width: 100%;
        }

        &--block {
          .btn {
            width: 100%;
          }
        }

        .group-paragraph-info {
          > .field {
            display: flex;
            align-items: center;
          }
        }

        &.paragraphs-item-koda-basic-paragraph {
          gap: 0;
        }

        .koda-paragraph-outer-wrapper {
          h2 {
            margin: 0;
          }
          .group-paragraph-info {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 4rem;
            .group-paragraph-info {
              display: flex;
              align-items: center;
            }
          }
        }

        /** Responsive **/
        @include respond-above(desktop) {
          flex-direction: row;
          gap: 4rem;

          h2 {
            font-size: 42px;
            line-height: 1.25;
          }
          > .field-name-field-extra-heading,
          .group-paragraph-info .field-name-field-title {
            flex: 0 0 500px;
          }

          &.layout-extended {
            > .field-name-field-extra-heading,
            .group-paragraph-info .field-name-field-title {
              flex: 0 0 40%;
            }
          }

          &--block {
            .btn {
              width: 466px;
            }
          }

          .koda-paragraph-outer-wrapper {
            .group-paragraph-info {
              flex-direction: row;
            }
          }
        }
      }

      &__specs {
        width: 100%;
        position: relative;
        background: #f8f8f8;

        &--btn {
          display: flex;
          .btn {
            font-size: 1.4rem;
            font-weight: 600;
          }
          /** Responsive **/
          @include respond-above(desktop) {
            .btn {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }

        &--wrapper {
          @extend .fluid-container;
          padding-top: 4rem;
          padding-bottom: 4rem;
          display: grid;
          gap: 2rem;

          h3 {
            line-height: 1.2;
            font-size: 2.4rem;
            margin-bottom: 2rem;

            &:after {
              content: '';
              display: block;
              position: relative;
              width: 225px;
              height: 25px;
              margin-top: -10px;
            }
          }
        }

        iframe {
          width: 100%;
          height: 430px;
          border-radius: 3px;
        }

        /** Responsive **/
        @include respond-above(desktop) {
          &--wrapper {
            padding-top: 8rem;
            padding-bottom: 8rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .new-car {
            &__video-overview {
              grid-row: 1/3;
            }
          }
        }
      }

      &__video-overview {
        h3 {
          &::after {
            border-bottom: 4px solid $primary-color;
          }
        }
      }

      &__exterior,
      &__interior {
        position: relative;
        overflow: hidden;

        h3 {
          &::after {
            background: url('../images/bg-specs-arrow.svg') right bottom
              no-repeat;
          }
        }
        .slick {
          margin-bottom: 2rem;
          z-index: 50;
          .slick-list {
            position: relative;
            .slide.slick-active {
              &:not(.slick-current) {
                .slide__content {
                  &::after {
                    @include myanimated(500ms);
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background: linear-gradient(
                      90deg,
                      rgba(248, 248, 248, 0) 0%,
                      #f8f8f8 100%
                    );
                  }
                }
              }
            }
          }
          &__slider {
            margin-left: -1rem;
            margin-right: -1rem;
          }
          .media {
            position: relative;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
          }
          img {
            height: 170px;
            width: 100%;
            border-radius: 3px;
            object-fit: cover;
          }

          &__arrow {
            .slick-arrow {
              background-color: #fff;
              @include myanimated(500ms);

              &.slick-disabled {
                opacity: 0;
                visibility: hidden;
              }

              &::before {
                font-family: $font-awesome-pro;
                font-weight: 400;
                color: #222222;
              }
              &.slick-prev {
                border-radius: 0 32px 32px 0;
                &::before {
                  content: '\f060';
                }
              }
              &.slick-next {
                border-radius: 32px 0 0 32px;
                &::before {
                  content: '\f061';
                }
              }
            }
          }

          /** Responsive **/
          @include respond-above(desktop) {
            margin-bottom: 0;
          }
        }
      }

      /** Features **/
      &__features {
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-color: #f8f8f8;

        h2.global-title {
          font-size: 4.2rem;
          margin-bottom: 4rem;
        }

        /** Key Features **/
        .nc-key-features {
          @extend .container-fluid;

          // For Multicols
          &.paragraphs-item-multi-cols {
            .group-paragraph-info {
              h2 {
                margin-top: 0;
                font-size: 26px;
                color: $primary-color;
              }
            }
            .paragraphs-items {
              background-color: #fff;
              border-left: 4px solid $primary-color;
              padding: 2rem;
              gap: 4rem;
              > .column {
                flex: 1;
                margin-left: 0;
              }
            }
            .field-name-field-desc {
              img {
                width: auto !important;
                height: auto !important;
                max-width: 100%;
              }
              @include respond-above(tablet) {
                img {
                  max-width: 400px;
                }
              }
            }
          }

          // Accordtion
          &.koda-accordion {
            > .panel {
              margin-top: 2rem;
              .accordion-header {
                border-left: 4px solid $primary-color;
                padding: 2rem;
              }
              > .panel-collapse {
                > .paragraphs-item-collapsible-group-element {
                  .paragraphs-item-koda-basic-paragraph {
                    padding: 0 4rem;
                  }
                }
              }
            }

            .two-col-img-text {
              .par-inner-layer {
                justify-content: flex-start;
              }
            }
          }
        }

        /** Responsive **/
        @include respond-above(desktop) {
          padding-top: 8rem;
          padding-bottom: 6rem;
          h2.global-title {
            margin-bottom: 8rem;
          }
          .nc-key-features {
            .paragraphs-items {
              flex-direction: row;
              > .column {
                &:first-child {
                  flex: 0 0 460px;
                  width: 460px;
                }
              }
            }
            &.koda-accordion {
              > .panel {
                margin-top: 4rem;
                .accordion-header {
                  font-size: 42px;
                }
              }
            }
          }
        }

        @include respond-below(desktop) {
          .nc-key-features {
            .paragraphs-items {
              flex-direction: column;
              > .column {
                margin: 0;
                width: 100%;
              }
            }

            &.koda-accordion {
              > .panel {
                > .panel-collapse {
                  > .paragraphs-item-collapsible-group-element {
                    .paragraphs-item-koda-basic-paragraph {
                      padding: 2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

      /** FAQ **/
      &__faq {
        margin-top: 4rem;
        margin-bottom: 8rem;
        @include respond-above(desktop) {
          margin-top: 8rem;
        }
      }
    }

    color: #222;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: inherit;
    }

    ul,
    ul li,
    ol,
    ol li,
    p {
      color: inherit;
      line-height: 1.7;
    }

    img {
      width: auto;
      max-width: 100%;
    }

    .btn-red,
    .btn {
      border-radius: 25px;
      text-align: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 700;
      text-transform: none;
      color: #fff !important;
      min-width: 100%;
      &:active,
      &:focus {
        color: #fff !important;
        text-decoration: none;
      }
      @include respond-above(tablet) {
        min-width: 250px;
      }
    }

    .page-title {
      position: relative;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 0;

      .page-header {
        font-weight: 600;
        font-size: 3.6rem;
      }
    }

    .field-name-body {
      font-size: 1.8rem;
    }

    p {
      font-size: 18px;
      &.note {
        color: #777 !important;
        margin-bottom: 0;
      }
    }

    /** Responsive **/
    @include respond-above(wide) {
      .page-title {
        padding-left: 0;
        padding-right: 0;
        .page-header {
          font-size: 6.4rem;
        }
      }
    }
  }

  .nc-diamond-advantage {
    padding: 8rem 0;
    .block-title {
      text-align: center;
      font-size: 4.2rem;
    }
    img {
      width: auto;
    }

    .col-md-8,
    .col-xs-12 {
      padding-left: 0;
      padding-right: 0;
    }

    @include respond-below(desktop) {
      img {
        width: auto !important;
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.view-mode-build_your_mitsubishi {
    @include wrapper(relative, 100%, 100%);
    @include flex-container(column, wrap, flex-start, stretch, center);
  }

  .paragraphs-items-field-bottom-paragraphs {
    @include respond-between(mobile, wide) {
      > .new-cars-paragraphs {
        > .container-fluid {
          > .koda-accordion,
          > .koda-paragraph-outer-wrapper {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }
}

/** Triton ***/

// .node-type-car {
//   &.page--mitsubishi-triton {
//     .new-car {
//       &__specs--btn {
//         display: none;
//       }
//     }
//   }
// }
