.node-type-blog {
    .block-easy-breadcrumb {
        @include respond-below(desktop) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .block-fieldblock {
        .field-name-field-hero-image {
            img {
                height: $slider-img-min-height;
                @include respond-above(tablet) {
                    height: $slider-img-medium-height;
                    min-height: $slider-img-min-height;
                }
            }
        }
    }
}