.page-choose-range{
    @include wrapper(relative, 100%);

    form{
        &#choose-range-form{
            padding-bottom: $grid-gutter-width/2;
            text-align: center;

            > div{
                @include flex-container(column, wrap, flex-start, center);

                .btn{
                    &.form-submit{
                        display: none;
                        //@extend .btn-red;
                    }
                }
            }
        }
    }

    .page-title{
        display: none;
    }

    .form-item-choose-range{

        @include wrapper(relative, 100%);          
        text-align: center;
        @include respond-above(mobile){
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
            padding-bottom: $grid-gutter-width;
        }

        .form-radios{
            @include wrapper(relative, 100%);
            display: block;
            .form-radios{                
                @include wrapper(relative, 100%, 100%);
                @include respond-above(mobile){
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 1rem;
                }               

                label.control-label{
                    @include flex-container(column, wrap, center, stretch, stretch);
                    @include myanimated;
                    
                    @include respond-above(mobile){
                        max-width: 0 auto;
                    }
                    @include respond-below(mobile){
                        min-width: 100vw;
                    }

                    &:hover{
                        &:after{
                            background-color: $black;
                        }  
                    }

                    //:------------------------------------------------------------------------------                   
                    &:after{
                        content: "Select";
                        position: relative;
                        background-color: $primary-color;
                        color: $white;
                        font-weight: normal;
                        margin: $grid-gutter-width auto;
                        width: 222px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        text-transform: uppercase;
                        font-weight: normal;
                        @include myanimated;
                        @include border-radius(53px);
                    }
                    
                    position: relative;                  
                    //border: 5px solid transparent;
                    //&.selected{
                        //border: 5px solid rgba(228, 26, 26, 0.3);
                    //}

                    &:hover{
                        h2{
                            color: $primary-color;
                        }
                    }
                    
                    width: 100%;
                    cursor: pointer;

                    
                    h2{
                        @include wrapper(relative, 100%);
                        @include myanimated;
                        margin: 0;
                        padding: $grid-gutter-width/2;
                        font-size: 40px;
                        font-weight: normal;
                        color: $black;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    /*
                    img{
                        @include wrapper(relative, 100%, 300px);
                        @include object-fit(contain);
                        object-position: center center;
                        @include respond-above(desktop){
                            height: 474px;
                        }
                    }
                    */

                    .form-radio{
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 50;
                        opacity: 0;
                    }


                }
            }
        }



    }

}