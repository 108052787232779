.koda-fullwidth{

    >.container-fluid,
    >.container,
    .content-col{
        @include wrapper(relative, 100%);
        @include respond-above(mobile){
            padding-left: 0;
            padding-right: 0;

            .koda-paragraph-inner-wrapper{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

        }
        
    } 

    .config{
        //margin-top: $grid-gutter-width/2;
        @extend .fluid-container;
    }
    
    .region-header-content{
        @extend .fluid-container;
    }

    .page-title{
        @extend .fluid-container;
    }

    .page-extra-title{
        @extend .fluid-container;
    }



}