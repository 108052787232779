.view-compare{

    .views-exposed-form{
        .views-exposed-widget{
            @include wrapper(relative, 100%);

            @include respond-above(tablet){
                padding: 0 $grid-gutter-width/2;
            }

            padding-top: 0;
            padding-bottom: 0;

            .chosen-container{
               position: relative;
               width: 100% !important;
                .chosen-single{
                    @include wrapper(relative, 100%);
                    height: auto;
                    padding: 0;
                    border-radius: 0;
                    background: none;
                    box-shadow: none;
                    border: none;
                    font-size: 16px !important;
                    line-height: 28px;
                    span{
                        font-size: 16px;
                    }
                }
                .chosen-drop{
                    > .chosen-results{
                        > li{
                            font-size: 16px !important;
                            padding-top: em(8);
                            padding-bottom: em(8);
                        }
                    }
                }
            }

            

            .form-control{
                @include border-radius(0);
            }

        }
    }


}