.used-cars-featured{
    .koda-header-wrapper{
        margin-top: $grid-gutter-width;
    }
}

#used-cars-featured-cars-wrapper{
  @include wrapper(relative, 100%);
}

#used-cars-featured-cars{
    @include wrapper(relative, 100%);
    display: grid  !important;
    gap: 2em;

    @include respond-above(desktop){
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include respond-between(mobile, desktop){
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1.5em;
    }

}
