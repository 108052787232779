section{
    @include wrapper(relative,100%);
}
header{
    @include wrapper(relative,100%);
    &.header{     
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;   
        //min-height: $header-min-height;
        color: $header-color;
        @include myanimated;
        @include flex-container(row, wrap);
    }
}

.layout-container{
    @include wrapper(relative,100%);
    min-height: 80vh;
}

footer{
    @include wrapper(relative,100%);
    &.footer{
        min-height: $footer-min-height;
        background-color: $footer-bg-color;
        color: $footer-font-color;
        a{
            color: $footer-font-color;
        }
        @include flex-container(tablet, wrap);
        @include respond-above(tablet){
            flex-direction: row;
        }
    }
}

