/*------------------------------------*\
 Paragraph : Car
\*------------------------------------*/

.paragraphs-items-field-cars {
  display: flex;
  justify-content: center;

  .carousel-on-mobile {
    > .slick-list {
      height: auto !important;
    }
  }

  /** Responsive **/
  @include respond-above(tablet) {
    .carousel-on-mobile {
      display: flex;
      gap: 8rem;
      .entity-paragraphs-item {
        flex: 1;
      }
    }

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      content: 'VS';
      width: 100px;
      height: 100px;
      margin-top: 5%;
      border-radius: 100%;
      font-family: $font-family-base;
      font-weight: 200;
      font-size: 3.6rem;
      color: #777;
      line-height: 1;
      border: 1px solid #777;
    }
  }
}

.paragraphs-item-car-paragraph {
  &:nth-child(odd) {
    .paragraph--cars {
      .field-name-field-link-button {
        a {
          background-color: #000;
          border-color: #000;
        }
      }
    }
  }
  .paragraph--cars {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    > img {
      height: 180px;
      object-fit: contain;
    }

    h3 {
      text-align: center;
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 4rem;
    }

    .field-collection-view {
      padding: 0;
      margin: 0;
      border: none;
      display: flex;
    }

    .field-collection-item-field-specifications {
      display: flex;
      font-size: 1.8rem;
      flex-direction: column;

      label {
        color: #777;
        padding-right: 1rem;
        font-weight: 600;
        &::after {
          content: ':';
        }
      }

      label,
      span {
        font-size: 1.6rem;
        flex: 1;
      }

      /** Responsive **/
      @include respond-above(desktop) {
        flex-direction: row;
        label {
          flex: 0 0 24rem;
        }
        label,
        span {
          font-size: 1.8rem;
        }
      }
    }

    .field-name-field-link-button {
      margin-top: auto;
      padding-top: 4rem;
      display: flex;
      justify-content: center;
      width: 100%;

      a {
        @extend .btn;
        @extend .btn-primary;
        font-weight: normal;
        padding: 1.2rem 2rem;
        color: #fff;
        max-width: 100%;
        white-space: normal;
        font-size: 14px;
      }

      /** Responsive **/
      @include respond-above(tablet) {
        a {
          min-width: 40rem;
          padding: 1.2rem 4rem;
          font-size: 20px;
        }
      }
    }

    .field-collection-container {
      border: none;
      margin-bottom: 0;
    }

    .field-collection-view-links {
      font-size: 12px;
    }

    /** Responsive **/
    @include respond-above(tablet) {
      padding-bottom: 8rem;
      > img {
        height: 320px;
      }
    }
  }
}
