#block-tb-megamenu-main-menu ul.tb-megamenu-nav li {
  &:first-child,
  &:nth-child(2){

    .tb-megamenu-row{
      //background-color: green;
    
      .tb-megamenu-column-inner{
        //background-color: orange;
        height: 495px;
        align-content: center;
        width: 100%;
      }

    }
  }


  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6){
    .mega-dropdown-inner {
      height: 495px;
      overflow: hidden;
      
    }
    /** global wrapper **/
    .tb-megamenu-row {
      display: flex;
      align-items: flex-start;
      height: 100%;

      .mega-col-nav.span2 {
        height: 100%;
        img {
          width: auto;
          height: 100%;
          min-height: 495px;
          object-fit: cover;
        }
      }
    }

    /** overwrite padding and margin **/
    .mega-dropdown-inner,
    .mega-inner {
      padding: 0;
    }

    .tb-block,
    p {
      margin: 0;
    }

    /** content container **/
    .meganav-horz-tile {
      .view-content {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-evenly;

        // each content
        .views-row {
          flex-basis: 20%;
          flex-shrink: 1;

          .node {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
        }

        // content image
        .field-name-field-hero-image {
          a {
            padding: 0;
          }
          img {
            width: 100%;
            object-fit: cover;
            height: 240px;
          }
        }

        // content title
        .field-name-title {
          flex: 0 1 100%;
          margin-bottom: 20px;

          h2 {
            font-size: 40px;
            color: white;
            text-align: center;
          }
        }

        // content button
        .field-name-node-link {
          a {
            font-size: 12.5px;
            color: #fff;
            font-weight: 600;
            padding: 10px 0;
            width: 140px;
          }
        }
      }
    }
  }
}
