.used-cars-search-result-page {
  display: grid !important;
  gap: 2em;
  @include respond-above(desktop) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @include respond-between(mobile, desktop) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5em;
  }
}

.used-cars-result-item {
  &.listing {
    @include wrapper(relative, 100%);
    padding-bottom: 3.5em;

    @include flex-container(column, wrap, flex-start, stretch, center);
    flex: 0 0 auto;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.18);

    figure,
    figure a,
    figure img,
    .listing-info,
    h2,
    h3 {
      @include wrapper(relative, 100%);
    }

    img {
      width: 100%;
      //height: em(212) !important;
      //object-position: center center;
      //@include object-fit(cover);
    }

    h2,
    h3 {
      padding: 0;
      margin: 0;
      text-align: center;
    }

    .c-info {
      font-size: em(1); //22px
      font-weight: normal;
      margin-bottom: 15px;
      color: $black;
      text-transform: uppercase;
      a {
        font-size: 22px;
        color: $black;
      }
    }

    .c-variant {
      font-size: 20px;
      font-weight: normal;
      color: #b5b5b5;
      text-transform: uppercase;
      margin-bottom: 1em;
    }

    .listing-info {
      padding: 1.2em;
      @include flex-container(column, wrap, center, center, center);
    }

    .btn-wrapper {
      font-size: em(20);
      bottom: 0;
      left: 0;
      margin-bottom: 1em;
      text-align: center;
      @include wrapper(absolute, 100%);

      .btn {
        font-size: em(12);
      }
    }
  }
}

/* Common Elements - Used and Prestige Cars*/
.fuel-milage {
  @include wrapper(relative, 100%);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    width: 100%;
    font-size: 15px;
  }
}

.c-price {
  font-size: 30px;
  font-weight: 600;
  color: $black;
  margin-bottom: 1em;
  > .original-price {
    text-decoration: line-through;
    font-size: 15px;
    font-weight: normal;
    color: gray;
  }
}
