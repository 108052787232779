.default-image-header{
    @include wrapper(relative, 100%, $nav-height);

    img{
        @include object-fit(cover);
        @include wrapper(relative, 100%, $nav-min-height);  
        min-height: $nav-min-height; 
        @include respond-above(tablet){
            @include wrapper(relative, 100%, $nav-height);  
        }
    }
}