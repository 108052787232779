.page-choose-vehicle{

    @include wrapper(relative, 100%);

    form{
        &#choose-vehicle-form{
            padding-bottom: $grid-gutter-width/2;
            text-align: center;

            > div{
                @include flex-container(column, wrap, flex-start, center);

                .btn{
                    &.form-submit{
                        @extend .btn-red;
                        display: none;
                    }
                }
            }
        }
    }

    .page-title{
        display: none;
    }

    .form-item-choose-vehicle{
        @include wrapper(relative, 100%);
        padding-bottom: $grid-gutter-width;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        text-align: center;
        
        .form-radios{
            position: relative;
            width: 100%;
            display: block;
            .form-radios{                
                position: relative;
                width: 100%;
                
                @include respond-above(desktop){
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 1rem;
                }

                @include respond-between(mobile, desktop){
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 1rem;
                }

                label.control-label{
                    @include flex-container(column, wrap, flex-start, stretch, stretch);
                    @include myanimated;

                    width: 100%;
                    margin-left: 0;

                    position: relative;                  
                    border: 5px solid transparent;

                    //:------------------------------------------------------------------------------
                    // @include respond-above(desktop){
                    //     display: grid;
                    //     grid-template-columns: repeat(3, minmax(0, 1fr));
                    //     gap: 1rem;
                    //     // width: calc(33.3% - 40px);
                    //     // margin-left: 40px;
                    //     // &:first-child,
                    //     // &:nth-child(3n+1){
                    //     //     margin-left: 0;
                    //     // }
                    // }
                    //:------------------------------------------------------------------------------
                    // @include respond-between(mobile, desktop){
                    //     display: grid;
                    //     grid-template-columns: repeat(2, minmax(0, 1fr));
                    //     gap: 1rem;
                    //     // width: calc(50% - 40px);
                    //     // margin-left: 40px;
                    //     // &:first-child,
                    //     // &:nth-child(2n+1){
                    //     //     margin-left: 0;
                    //     // }
                    // }
                    //:------------------------------------------------------------------------------
                    
                    //&.selected,
                    //&:hover{                      
                        //border: 5px solid rgba(228, 26, 26, 0.3);
                    //}

                    &.selected,
                    &:hover{

                        border: 5px solid $yellow-0;

                        h2{
                            color: $primary-color;
                        }

                        .field-name-title{
                            .field-item{
                                &::after{
                                    background-color: $black;
                                    border-color: 1px solid $black;
                                }
                            }
                        }

                    }
                    
                    width: 100%;
                    cursor: pointer;

    
                    h2{
                        @include wrapper(relative, 100%);
                        @include myanimated;
                        margin: 0;
                        padding: 30px $grid-gutter-width/2;
                        font-size: 24px;
                        font-weight: 600;
                        color: $black;
                        text-transform: uppercase;
                        text-align: center;
                    }


                    .field-name-title{
                        .field-item{
                            &::after{
                                content: "Select";
                                position: relative;
                                width: 222px;
                                z-index: 10;
                                font-weight: normal;
                                margin-bottom: $grid-gutter-width/2;
                                @extend .btn-red;
                                display: inline-block;
                            }
                        }
                    }

                    img{
                        @include wrapper(relative, 100%, 300px);
                        @include object-fit(contain);
                        object-position: center center;
                    }

                    .form-radio{
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 50;
                        opacity: 0;
                    }

                    .node-car{
                        .header-buttons{
                            display: none;
                        }
                    }


                }
            }
        }
    }


}