.view-nav-menu-pages{
    align-items: stretch;
    height: 100%;       
    padding-top: $grid-gutter-width/2;
    @include wrapper(relative, 100%);  
    padding-right: $grid-gutter-width;

    > .field-items,
    .view-content{

        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, stretch);
        height: 100%;
                
        > .field-item,
        .views-row{
        height: 100%;
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, flex-start, stretch);
        
            @include wrapper(relative, 25%);    
            &:first-child,
            &:nth-child(4n+1),
            &.views-row-first{ 
                padding-left: 0;                                  
            }
            
            &:nth-child(4n),
            &.views-row-last{
                padding-right: 0;                        
            }
        }



    }
}