/*------------------------------------*\
  Testimonials
\*------------------------------------*/
.node-staff-testimonial{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include myanimated;

    .field-name-field-thumbnail,
    .field-name-field-image{   
        padding-right: 4rem;
        padding-left: 4rem;
        z-index: 5;    
        margin-bottom: -160px;  

        img{
            border-radius: 100%;
            margin: 0 auto;
            max-width: 100%;
            width: auto;
            height: auto;
            @include object-fit(cover);
        }
    }

    .staff-testimonial-content{
        position: relative;
        display: flex;
        flex-direction: column;           
        background-color: #FFF;
        height: 100%;
        padding: 15rem 2rem 2rem 2rem;
        width: 100%;
        
        h2{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 3rem;
            margin-bottom: 3rem !important;
            font-size: 2.5rem !important;
            text-align: center;
            color: #000;
            &::before{
                top: 0;
                left: 0;
                margin-bottom: 1rem;
                margin-right: 0;
                content: url('../images/staff-quote.svg');
            }
        }

        .field-name-body{
            color: #595959;
            padding-left: 0;
            padding-right: 0;
            p{
                color: inherit;
                font-weight: 300 !important;
            }
        }   
    }


    /** Responsive **/

    @include respond-above(md){
        .staff-testimonial-content{
            h2{
                flex-direction: row;
                &::before{
                    margin-bottom: 0;
                    margin-right: 1rem;
                } 
            }
        }
    }

    @include respond-above(lg){

        &.node-teaser{
            flex-direction: row;
            align-items: center;
            .field-name-field-thumbnail,
            .field-name-field-image{
                display: flex;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
                margin-right: -150px;
                margin-bottom: auto;

            }
            .staff-testimonial-content{
                flex: 1;
                padding: 2rem 4rem 3rem 20rem;
                height: auto;
                width: auto;
                h2{
                    color:#181818;
                    text-align: start;
                    padding-left: 6rem;
                    margin-top: 2rem;
                }
            }
        }

    }
 
    @include respond-above(max){

        .field-name-field-thumbnail,
        .field-name-field-image{
            min-height: 280px;
            img{
                width: 274px;
                height: 274px;
            }
        }

        .staff-testimonial-content{
            padding-right: 4rem;
            padding-left: 4rem;
        }
    }


}

