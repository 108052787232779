/*------------------------------------*\
Field : Extra Heading
\*------------------------------------*/
.field-name-field-extra-heading {
  h2 {
    font-size: em(20);
    margin: 0;
    padding: 0 0 $grid-gutter-width/2 0;
    color: $heading-color;
    @include respond-above(mobile) {
      font-size: em(35);
    }
  }

  //@extend .section-fluid;
}
