.greyform{
    // Block CSS
    .koda-header-container{
        @include wrapper(relative, 100%);
        padding: 0 0 15px 0; 
        @include respond-above(wide){
            padding: 0 0 $grid-gutter-width/2 0; 
        }
        h2{
            @include wrapper(relative, 100%);   
            padding-left: 30px;
            font-size: em(35);
            margin-top: 0;
            @include respond-above(wide){
                border-left: 4px solid #C7C7C7;
                font-size: 60px;
            }
        }
    }
    .webform-client-form{
        @include wrapper(relative, 100%);
        margin: 0;
        background: url('../images/enguire-now-grey.jpg') 0 0 no-repeat;
        background-size: cover; 
        padding: $grid-gutter-width 30px;
        .form-group{
            &:not(.webform-component-markup){
                border-bottom: 1px solid $white;
            }
            @include wrapper(relative, 100%);        
            @include flex-container(row, wrap, flex-start, center, center);
            .control-label{
                @include flex-container(row, wrap);
                flex: 1;
                margin-bottom: 0;
                color: $white;
                font-weight: normal;
                @include respond-above(mobile){
                    width: auto;
                    flex: 0 0 auto;
                }
            }
            .form-control{
                position: relative;
                width: 100%;
                @include respond-above(mobile){
                    @include flex-container(column, wrap);
                    flex: auto;
                    width: 70%;
                }
                &.form-textarea{
                    width: 100%;
                }
                
            }
        }
        .form-control{
            border-radius: 0px;
            background-color: transparent;
            border: none;
            color: $white;
            box-shadow: none;
            padding: 0 8px;
            font-size: 45px;
            height: auto;
            font-weight: normal;      
        }
        .form-actions{
            @include wrapper(relative, 100%);
            @include flex-container(row, wrap, flex-end);
            margin-top: $grid-gutter-width;

            .form-submit{
                @include myanimated;
                background-color: $primary-color;
                @include border-radius(53px);
                text-transform: uppercase;
                padding: 0.2em 2.5em;
                font-size: 22px;
                border: none;
                color: $white;
                &:hover{
                    background-color: $black;
                }
            }
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $white;
            font-size: 20px;
            opacity: 1; /* Firefox */
        }
          
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $white;
            font-size: 20px;
        }
          
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: $white;
            font-size: 20px;
        }

        .resizable-textarea .grippie{
            border: none;
            background: none;
        }
        fieldset{
            position: relative;
            background: transparent;
            border: none;
            border-radius: 0;

            legend{
                @include wrapper(relative, 100%);
                padding: 0;
                margin: 0 0 $grid-gutter-width 0;
                border: none;
                border-radius: 0;
                background: transparent;

                span{
                    @include wrapper(relative, 100%);
                    margin: 0;
                    padding: 0;
                    color: $white;
                    font-weight: 600;
                }
            }
        }




    }

}