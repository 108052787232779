.lease-offers-tile{
    @include wrapper(relative, 100%);
    padding-bottom: $grid-gutter-width*2;

    @include respond-above(mobile){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    &.koda-multicol.koda-multi-cols-3-column > .koda-paragraph-inner-wrapper > .koda-inner-column-paragraphs > .paragraphs-items > .column{
        margin-left: 0 !important;

        @include respond-between(mobile, desktop){
            width: 50% !important;
        }
       
        @include respond-above(desktop){
            width: 33.3% !important;          
        }

        @include respond-above(mobile){
            &:nth-child(even) {                    
                .group-paragraph-info{
                    &::before{
                        content: "";
                        @include wrapper(absolute, 100%, 100%);
                        background: linear-gradient(0deg, #700909 8.51%, rgba(239, 44, 44, 0.49) 65.51%);
                    }
                }
            }
            &:nth-child(odd) {                    
                .group-paragraph-info{
                    &::before{
                        content: "";
                        @include wrapper(absolute, 100%, 100%);
                        background: linear-gradient(0deg, #000000 25.52%, rgba(0, 0, 0, 0) 86.77%);
                    }
                }
            }
        }

        
      
    }
    
    .entity-paragraphs-item{
        position: relative;
        width: 100%;      
    }

   
    .field-name-field-hero-media{
        position: absolute !important;
        width: 100%;
        height: 347px;
        top: 0;
        left: 0;
        img{
            @include wrapper(relative, 100%, 380px);
            @include object-fit(cover);
        }
    }

    .koda-header-wrapper{
        @include wrapper(relative, 100%);
        margin-bottom: $grid-gutter-width;
    }

    > .koda-paragraph-inner-wrapper .column{

        .group-paragraph-info{

            @include respond-below(mobile){
                position: relative;
                &::after{
                    content: "";
                    @include wrapper(absolute, 100%, 100%);
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
            
            @include flex-container(column, wrap, center, center, center);
            position: relative;
            width: 100%;
            height: 380px !important;
            
            .field-name-field-title,
            .field-name-field-sub-title{
                @include myanimated(400ms);
                h2, h3{
                    @include wrapper(relative, 100%);
                    text-align: center;
                    margin: 0;
                    padding: 0 $grid-gutter-width;
                    font-size: 1.2em;
                    color: $white;
                    z-index: 10;

                    @include respond-above(desktop){
                        font-size: 1.2em;
                    }                 
                }
            }

            .field-name-field-desc{
                @include myanimated(400ms);
                @include wrapper(relative, 100%);
                text-align: center;
                margin: 0;
                padding: 0 $grid-gutter-width;
                font-size: 25px;
                color: $white;
                z-index: 10;

                h2, h3, h4{
                    margin: 0;
                }
                p{
                    font-weight: 600;
                    font-size: 25px;
                    margin-bottom: 0;
                }
            }

            &:hover{
                .field-name-paragraph-link-url,
                .field-name-field-link{
                    opacity: 1;
                }
                .field-name-field-title,
                .field-name-field-sub-title,
                .field-name-field-desc{
                    opacity: 0;
                }
            }

            .field-name-paragraph-link-url,
            .field-name-field-link{
                position: absolute !important;
                width: 100%;
                height: 100%;
                top:0;
                left: 0;
                z-index: 10;
                opacity: 0;
                @include myanimated(400ms);

                .field-items,
                .field-item,
                .field-item a{
                    @include wrapper(absolute, 100%, 100%);
                    top: 0;
                    left: 0;
                }
                a{
                    @include flex-container(column, wrap, center, center, center);
                    padding: 30px 35px;
                    font-size: 2.1em;
                    font-weight: 600;
                    line-height: 1.1em;
                    color: $white;
                    text-align: center;
                    @include respond-above(desktop){
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                    @include respond-between(mobile,desktop){
                        font-size: 1.5em;
                    }
                    background: linear-gradient(0deg, #000000 25.52%, rgba(0, 0, 0, 0.56) 86.77%);
                    &:after{
                        content: "Enquire Now";
                        font-size: 22px;
                        line-height: 24px;
                        font-weight: 600;
                        background-color: $primary-color;
                        padding: 10px 30px;
                        margin-top: 20px;
                        text-transform: uppercase;
                        @include border-radius(44px);
                    }
                }
            }



        }

    }


    


}


/*
    > .koda-paragraph-inner-wrapper .column{
        width: 100% !important;
        margin-left: 0 !important;

        @include respond-between(mobile, desktop){
            width: 50% !important;
        }
        @include respond-above(desktop){
            width: 33.3% !important;
        }

        .entity-paragraphs-item{
            position: relative;
            width: 100%;
            background-color: green;

            &:nth-child(even) {                    
                .group-paragraph-info{
                    &::before{
                        content: "";
                        @include wrapper(absolute, 100%, 100%);
                        background: linear-gradient(0deg, #700909 8.51%, rgba(239, 44, 44, 0.49) 65.51%);
                    }
                }
            }
            &:nth-child(odd) {                    
                .group-paragraph-info{
                    &::before{
                        content: "";
                        @include wrapper(absolute, 100%, 100%);
                        background: linear-gradient(0deg, #000000 25.52%, rgba(0, 0, 0, 0) 86.77%);
                    }
                }
            }
    
            .field-name-field-hero-media{
                position: absolute !important;
                width: 100%;
                height: 347px;
                top: 0;
                left: 0;
                img{
                    @include wrapper(relative, 100%, 347px);
                }
            }
    
            .group-paragraph-info{
                @include wrapper(relative, 100%, 347px);
                @include flex-container(column, wrap, center, center, center);
                
                .field-name-field-title,
                .field-name-field-sub-title{
                    @include myanimated(400ms);
                    h2, h3{
                        @include wrapper(relative, 100%);
                        text-align: center;
                        margin: 0;
                        padding: 0 $grid-gutter-width;
                        font-size: 1.2em;
                        color: $white;
                        @include respond-above(desktop){
                            font-size: 1.2em;
                        }                 
                    }
                }
    
                // Hover
                &:hover{
                    .field-name-field-link{
                        //opacity: 1;
                    }
                    .field-name-field-title,
                    .field-name-field-sub-title,
                    .field-name-field-desc{
                        //opacity: 0;
                    }
                }
    
                .field-name-field-desc{
                    @include myanimated(400ms);
                    @include wrapper(relative, 100%);
                    text-align: center;
                    margin: 0;
                    padding: 0 $grid-gutter-width;
                    font-size: 25px;
                    color: $white;
                    h2, h3, h4{
                        margin: 0;
                    }
                    p{
                        font-weight: 600;
                        font-size: 25px;
                        margin-bottom: 0;
                    }
                }
    
                .field-name-field-link{
                    position: absolute !important;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    z-index: 10;
                    opacity: 0;
                    display: none;
                    @include myanimated(400ms);

                    .field-items,
                    .field-item,
                    .field-item a{
                        @include wrapper(absolute, 100%, 100%);
                        top: 0;
                        left: 0;
                    }
                    a{
                        @include flex-container(column, wrap, center, center, center);
                        padding: 30px 35px;
                        font-size: 2.1em;
                        font-weight: 600;
                        line-height: 1.1em;
                        color: $white;
                        text-align: center;
                        @include respond-above(desktop){
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                        @include respond-between(mobile,desktop){
                            font-size: 1.5em;
                        }
                        background: linear-gradient(0deg, #000000 25.52%, rgba(0, 0, 0, 0.56) 86.77%);
                        &:after{
                            content: "Enquire Now";
                            font-size: 22px;
                            line-height: 24px;
                            font-weight: 600;
                            background-color: $primary-color;
                            padding: 10px 30px;
                            margin-top: 20px;
                            text-transform: uppercase;
                            @include border-radius(44px);
                        }
                    }
                }
    
            }
    


        } // end

    }
    */