@import './redform';
@import './greyform';
@import './book-test-drive';
@import './topimg-webform';

//:---------------------------------------------------------------------------------------------------------------------
.validation-enquiry {
  @include wrapper(relative, 100%);
  @include respond-above(mobile) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
}
//:---------------------------------------------------------------------------------------------------------------------
.form--two-col {
  margin-bottom: 0;
  .panel-body {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 100%;
    }
    @include respond-above(md) {
      > .form-item {
        width: calc(50% - 30px);
        &:nth-child(2n) {
          margin-left: $grid-gutter-width/2;
        }
        &:first-child,
        &:nth-child(3n) {
          margin-right: $grid-gutter-width/2;
        }
      }
    }
  }
}

//:---------------------------------------------------------------------------------------------------------------------
.enquire-block-left {
  .captcha {
    padding-left: 0;
    padding-right: 0;
  }
}

//:---------------------------------------------------------------------------------------------------------------------
.captcha {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  .g-recaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
  }
  @include respond-below(tablet) {
    padding-left: 0;
    padding-right: 0;
    .g-recaptcha {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100%;
    }
  }
}

//:---------------------------------------------------------------------------------------------------------------------
.webform-component--opt-in {
  border-bottom: none !important;
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 0;
  a {
    color: #fff;
    text-decoration: underline;
    margin-left: 6px;
  }
}
.webform-component--opt-in-markup {
  font-size: 12px;
  color: #fff;
  font-style: italic;
  a {
    color: #fff;
    text-decoration: underline;
  }
}
