.view-koda-home-slider{

    &::before{
        content: "";
        @include wrapper(absolute, 100%, 190px);
        top: 0;
        left: 0;
        z-index: 10;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 14.24%);
    }   

    .view-content{
        @include wrapper(relative, 100%);
        .slick{
            .slide__content{
               .field-content{
                    @include wrapper(relative, 100%);                   
               } 
            }
        }
    }

    @extend .slick-arrow-style2;
    @extend .slick-dots-gray;
    //.slick-dots{}

}