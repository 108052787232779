.node-type-agent-page{

    @extend .stretch-fullwidth-content; // Stretch this content type to default fullwidth .global
    /*
    .region-topimg{
        .paragraphs-items{
            >.field-name-field-image-desc-p-image{
                .field-items,
                .field-item{
                    img{

                    }
                }
            }
        }
    }
    */

    .koda-multicol.koda-paragraphs-fluid-container > .koda-paragraph-inner-wrapper{
        @include respond-above(wide){
            padding-left: 0;
            padding-right: 0;
        }
    }

    .header-buttons{
        @include respond-below(tablet){
            margin: 0 auto 15px auto;
        }
    }

    .agent-info-wrapper{   
        @include respond-above(tablet){
            padding-bottom: $grid-gutter-width*2;
        }
        padding-bottom: $grid-gutter-width;
        .field-name-field-title{          
            @include respond-above(desktop){
                width: 80%;
            }
        }

        .par-inner-layer{
            justify-content: flex-start !important;
            h2{
                margin: 0;
            }
        }


        .field-name-field-title{
            h2{
                @include respond-below(desktop){
                    padding-top: 15px !important;
                }
            }
        }

        .field-name-field-desc{

            a{
                position: relative;
                
        
                &.phone{
                    display: inline-flex;
                    align-items: center;
                    padding-right: em(15);
                    &::before{
                        content: url('../images/icons/icon-agent-phone.svg');
                        margin-right: em(4);
                    }
                }
        
                &.wechat{
                    display: inline-flex;
                    align-items: center;
                    padding-right: em(15);
                    &::before{
                        content: url('../images/icons/icon-agent-chat.svg');
                        margin-right: em(4);
                    }
                }

                &.email{
                    display: inline-flex;
                    align-items: center;
                    padding-right: em(15);
                    &::before{
                        content: url('../images/icons/icon-agent-email.svg');
                        margin-right: em(4);
                    }
                }

            }

        }
    

    }




    //Custom CSS
    .agent-info{

        color: $black;
        h2{
            @extend .title-line;
            margin-bottom: $grid-gutter-width/2;
        }

        .group-paragraph-info{
            @include wrapper(relative, 100%);
            @include respond-above(desktop){
                padding-left: 60px;
            } 
            
            .field-name-field-desc{
                margin-top: 30px;
            }
            
        }

    }
    // End.

}