@import './paragraph--type--buttons';
@import './paragraph--type-content-paragraph';
@import './page-layout/page-layout--1212';

.paragraph{
    h1, h2, h3, h4, h5, h6{
        margin-top: 0;
    }
}

.flex-align-right{
    @include flex-container(row, wrap, flex-end, stretch, center);
}
.flex-align-left{
    @include flex-container(row, wrap, flex-start, stretch, center);
}
.flex-align-center{
    @include flex-container(row, wrap, center, stretch, center);
}

