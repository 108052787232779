.custom-header-block{

    &.koda-multi-cols-2-column{

        .koda-inner-column-paragraphs{
            .paragraphs-items{
                > .column{
                    @include respond-above(tablet){
                        width: auto !important;
                        margin-left: 0 !important;
                        flex: 1;
    
                        &:not(:first-child){
                            flex: 0 auto;
                        }
                    }
                    
                }
            }
        }

        

    }
       
}