.hero-subtitle{

    .field-name-field-title{
        @include wrapper(absolute, 100%);
        bottom: 0;
        left: 0;
        z-index: 50;
        margin-bottom: em(45);
        text-align: center;

        @include respond-above(tablet){
            margin-bottom: em(80);
        }

        h2{
            position: relative;
            font-size: em(18);
            font-weight: normal;
            color: $white;
            margin: 0 auto;
            text-align: center;
            @include text-shadow;
            @extend .fluid-container;

            @include respond-above(tablet){
                font-size: em(35);
            }
        }
    }


}