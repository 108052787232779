//@import './paragraphs-items-field-header-paragraphs-full';

@import './paragraphs-item-lucas-sale';
@import './paragraphs-item-car-paragraph';

//:-------------------------------------------------------------------------------------------------------------------------

.two-col-img-text {
  .par-img {
    @include respond-between(mobile, wide) {
      padding: 0 $grid-gutter-width/2;
    }
  }
  .par-content {
    @include respond-between(mobile, wide) {
      padding: 0 $grid-gutter-width/2;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
