.book-a-test-drive-form{

    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;

    @include respond-above(desktop){
        padding-top: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width*2;
    }

    .koda-header-wrapper{
        h2.global-title{
            font-size: em(35);
            margin-top: 0;
            color: $black;
            margin-bottom: $grid-gutter-width/2; 
            text-align: center;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;

            @include respond-above(desktop){
                margin-bottom: $grid-gutter-width; 
                text-align: left;
            }

            @include respond-above(wide){
                border-left: 2px solid $primary-color;
                font-size: 60px;
                
            }
        }
    }

    .koda-paragraph-inner-wrapper{
        @include respond-below(tablet){
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .book-test-drive{
        .form-type-webform-time{

            .webform-container-inline{
                @include respond-below(tablet){
                    flex: 0 0 100%;
                    width: 100%;
                }
            }

            #edit-submitted-form-wrapper-form-region-preferred-time-ampm{
                @include respond-below(desktop){
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }

            .form-item-submitted-form-wrapper-form-region-preferred-time-ampm{
                @include respond-below(desktop){
                    flex-direction: column !important;
                    justify-content: flex-start;
                }
                label{
                    width: 100%;
                    text-transform: capitalize;
                    padding: 0;
                    margin: 0;
                }
                input{
                    margin: 0 8px 0 0;
                    position: relative;
                }
            }
           
        }

        .webform-client-form{
            .form-actions{
                padding: 0 !important;
                @include respond-below(desktop){
                    width: 100% !important;                
                    text-align: right;                 
                }
            }
        }
    }

    .koda-inner-column-paragraphs{

        background: url('../images/enquire-now-bg.png') 0 0 no-repeat $primary-color;
        background-size: cover;

        .redform{
            .webform-client-form{
                background: none;
                @include respond-below(tablet){
                    padding-top: 0;
                }
            }
        }

        .column{

            &:first-child{
                width: 100% !important;
                margin-left: 0 !important;
                order: 1;
            }

            &:nth-child(2){
                width: 100% !important;
                margin-left: 0 !important;
                order: 0;
            }

            @include respond-above(tablet){
                &:first-child{
                    width: 60% !important;
                    order: 0;
                }
                &:nth-child(2){
                    width: 40% !important;
                    order: 1;
                }
            }
            
            .entity-paragraphs-item{
                 display: flex;   
            }

            .koda-paragraph-outer-wrapper{
                justify-content: center;
            }
        }

        .view-book-a-test{
            padding: $grid-gutter-width/2 $grid-gutter-width/2 0 $grid-gutter-width/2;
            color: $white;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            @include respond-above(wide){
                padding: 0 40px 0 0;
            }
            @include respond-between(mobile, tablet){
                padding: $grid-gutter-width $grid-gutter-width/2 0 $grid-gutter-width/2;
            }

            .view-filters{              
                display: flex; 
                width: 100%;
                order: 1;

                form{
                    position: relative;
                    width: 100%;

                    .panel-default{
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }

                    .form-type-select{
                        @include flex-container(row, nowrap, flex-start, center, center);
                        margin-top: em(20);

                        option{
                            color: $black;
                        }

                        @include respond-below(desktop){
                            option, label{
                                width: 100%;
                                flex: 0 0 100%;
                            }
                            flex-wrap: wrap;
                            label{
                                margin-bottom: $grid-gutter-width/2;
                            }
                        }
                    }

                    .control-label{
                        font-weight: normal;
                        display: flex;
                        flex: 0 0 em(150);
                        width: em(150);
                        &::after{
                            content: ":";
                        }
                    }

                    .form-control.form-select{
                        display: flex;
                        flex: auto;
                        width: 1% !important;
                        background-color: transparent;                      
                        color: $white;
                        box-shadow: none;
                        padding: em(10) 0;
                        height: auto;
                        font-weight: normal;  
                        border-top: none;
                        border-left: none;
                        border-right: none; 
                        border-bottom: 1px solid $white;
                    }

                    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $white;
                        font-size: 20px;
                        opacity: 1; /* Firefox */
                    }
                      
                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: $white;
                        font-size: 20px;
                    }
                      
                    ::-ms-input-placeholder { /* Microsoft Edge */
                        color: $white;
                        font-size: 20px;
                    }
            
                    .resizable-textarea .grippie{
                        border: none;
                        background: none;
                    }

                }
            }

            .view-content{
                order: 0;
                display: flex;
                width: 100%;

                .views-row{
                    width: 100%;
                }
            }

            .views-exposed-widget{
                padding: 0;
            }

            .views-exposed-form{
                .views-exposed-widget{
                    width: 100%;
                    .form-select{
                        border-radius: 0;
                    }
                }
            }

        }

    }

}