// Elements used likes fonts and etc

@mixin font(
  $family: $font-family-theme,
  $size: $font-size-base,
  $weight: normal,
  $letterspacing: normal
) {
  font-family: #{$font-family-theme}, sans-serif;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: $letterspacing;
}

@mixin object-fit($fit: fill, $position: null) {
  object-fit: $fit;
  @if $position {
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

// Wrapper
@mixin wrapper($position: relative, $width: auto, $height: auto) {
  position: $position;
  width: $width;
  height: $height;
}

@mixin readmore($clr: $th-blue-03, $hover: $th-blue-base) {
  @include wrapper(relative, auto);
  text-transform: capitalize;
  color: $clr;

  &:hover {
    color: $hover;

    &::before {
      color: $th-yellow;
    }
  }

  &::before {
    content: '\e5cc';
    font-family: $font-family-icon;
    color: $clr;
    font-size: 20px;
    top: 5px;
    left: -4px;
    @include myanimated;
    @include wrapper(relative, auto);
  }
}

// Apply to anchor wrap with image for zoom effects
@mixin imgZoomHover {
  @include myanimated;
  overflow: hidden;
  position: relative;
  display: block;

  img {
    @include myanimated(500ms);
  }
  &:hover {
    img {
      @include scale(1.1);
    }
  }
}

@mixin animateEffect($anim: fadein, $time: 500ms) {
  -webkit-animation: $anim $time ease-in-out; /* Chrome, Safari, Opera */
  animation: $anim $time ease-in-out;
  -ms-animation: $anim $time ease-in-out;
}

@mixin textGradientEffect() {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 38%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 38%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 38%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

@mixin textGradientEffectDown() {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

@mixin textGradientEffectUp() {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+80&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.52) 80%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.52) 80%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.52) 80%,
    rgba(0, 0, 0, 0.65) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

@mixin imageGradientCover() {
  &::before {
    content: '';
    z-index: 5;
    @include wrapper(absolute, 100%, 100%);
    @include textGradientEffect;
  }
}

@mixin fa-icon(
  $icon: '\f00d',
  $clr: $primary-color,
  $fsize: inherit,
  $fweight: inherit,
  $pos: relative,
  $fw: 25px,
  $fh: 25px
) {
  &::before {
    display: inline;
    content: $icon;
    margin-right: 8px;
    color: $clr;
    font-family: $font-family-icon-fa;
    font-size: $fsize;
    font-weight: $fweight;
    @include wrapper($pos, $fw, $fh);
  }
}

@mixin user-select($text: text) {
  -webkit-user-select: $text; /* Safari 3.1+ */
  -moz-user-select: $text; /* Firefox 2+ */
  -ms-user-select: $text; /* IE 10+ */
  user-select: $text; /* Standard syntax */
}

@mixin slickArrows() {
  .slick__arrow {
    @include wrapper(absolute, 100%);
    margin-top: 0;
    top: 45%;

    .slick-arrow {
      position: relative;
      background-color: transparent;
      border: none;
      display: block;
      padding: 0;
      font-size: 1px;
      color: transparent;
      @include flex-container(row, wrap, space-between);
      @include wrapper(absolute, 60px, 80px);
      @include respond-above(normal) {
        width: 80px;
      }

      &.slick-prev {
        left: 0;
        &::before {
          content: '\e5cb';
        }
      }

      &.slick-next {
        right: 0;
        &::before {
          right: 0;
          content: '\e5cc';
        }
      }

      &.slick-prev,
      &.slick-next {
        &:hover {
          &::before {
            color: $slider-nav-color-hover;
          }
        }
        &::before {
          top: 0;
          left: 0;
          text-align: center;
          font-weight: normal;
          font-size: 80px;
          line-height: 80px;
          color: gray;
          font-family: $font-family-icon-mi;
          @include wrapper(absolute, 100%, 100%);
          @include respond-below(normal) {
            font-size: 60px;
            text-align: center;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
  Extend Background 100%
\*------------------------------------*/
@mixin extendbg($bgcolor, $bordercolor: $bgcolor) {
  background-color: $bgcolor;
  border-top: 1px solid $bordercolor;
  border-bottom: 1px solid $bordercolor;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: calc(100% + 2px);
    top: -1px;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    background: $bgcolor;
    z-index: -1;
    visibility: visible;
    border-top: 1px solid $bordercolor !important;
    border-bottom: 1px solid $bordercolor !important;
  }
}

@mixin extendtopborder($bordercolor) {
  border-top: 1px solid $bordercolor;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: calc(100% + 2px);
    top: -1px;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    z-index: -1;
    visibility: visible;
    border-top: 1px solid $bordercolor !important;
  }
}

@mixin extendbg($bgcolor, $bordercolor: $bgcolor) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: calc(100% + 2px);
    top: -1px;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    background: $bgcolor;
    z-index: -1;
    visibility: visible;
  }

  .field--name-field-paragraphs,
  .field {
    z-index: 5;
  }
}
