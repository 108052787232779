/*------------------------------------*\
  Top Region
\*------------------------------------*/
.region-bottomregion{
    @include myanimated;
    position: fixed;
    bottom: 0;
    height: auto;
    opacity: 1;
    background-color: #000;
    padding: 1rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 50;

    @include respond-below(md){
        padding-left: 10rem;
        padding-right: 2rem;
    }
    @include respond-above(md){
        padding-left: 10rem;
        padding-right: 10rem;
    }

    > .block{
        position: relative;
        text-align:center;
        color: #FFF;
        font-size: 1.2rem;
        width: 100%;
        .koda-block-container{
            width: 100%;
            strong{
              color: $primary-color;  
            }
            .alert-image{
                height: 50px;
                margin: -30px 1rem 0 0;
            }
            > p{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                text-align: left;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        /** Responsive **/
        @include respond-above(md){
            .koda-block-container{      
                font-size: 1.6rem;  
                > p{
                    text-align: center;
                    justify-content: center;
                }

                .alert-image{
                    height: 50px;
                    margin: -30px 1rem 0 0;
                }

            }
        }
        
    }
}

body.scrolled{
    .region-topregion{
        @include myanimated;
        position: fixed;
        opacity: 0;
        height: 1px;
    }
}