.page-choose-color{

    form{
        &#choose-color-form{
            padding-bottom: $grid-gutter-width/2;
            text-align: center;

            > div{
                @include flex-container(column, wrap, flex-start, center);

                .btn{
                    &.form-submit{
                        margin-top: 60px;
                        @extend .btn-red;
                    }
                }
            }
        }
    }

    .page-title{
        display: none;
    }

    // Hide Elements
    .form-item-choose-color,
    .field-name-field-option-color-code,
    .field-name-field-option-color-name{
        display: none;
    }

}
