.latest-deal-carousel{

    @include wrapper(relative, 100%);
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    background: linear-gradient(0deg, #171717 0%, #4D4D4D 100%);
    min-height: $deal-banner-height;
    @include respond-above(wide){
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;    
    }

    .koda-block-container{
        @include wrapper(relative, 100%);
        .koda-header-container{
            @include wrapper(relative, 100%);
            //margin-top: $nav-height;
            margin-top: 140px;

            .block-title{
                @include wrapper(relative, 100%);
                text-align: center;
                color: $white;
            }
        }
    }


    //:--------------------------------------------------------------------------------------------------------

}




.latest-deal-carousel-view{

    //:--------------------------------------------------------------------------------------------------------
    // Custom for this
    .view-content{
        @include wrapper(relative, 100%);
        @include flex-container(row, wrap, flex-start, stretch, flex-start);
        margin-top: $grid-gutter-width/2;

        .node-deal-model.node-teaser.view-mode-teaser{
            padding-bottom: $grid-gutter-width;
            align-content: flex-end;
            
        }

        .field-name-title{
            @include myanimated;
            position: absolute;
            bottom: 20px;
            left: 0;
            
            h2{
                margin: 0;
                a{
                    font-size: em(14);                   
                    padding: 10px $grid-gutter-width/2;
                    @include text-shadow;
                    @include myanimated;
                                     
                }
                
            }
        }

    }
    //:--------------------------------------------------------------------------------------------------------

}