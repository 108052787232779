// Road Show Style Format

.roadshow-date {
  position: relative;
  margin: 30px auto;

  .group-paragraph-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    &::after {
      content: '';
      border-bottom: 2px solid $primary-color;
      width: 85px;
      margin-top: 30px;
    }
  }

  h2,
  h3 {
    align-items: center;
    text-align: center;
    font-size: 40px;
    padding: 0;
    margin: 0;

    @include respond-below(lg) {
      font-size: 25px !important;
    }
  }
}

.roadshow-content {
  position: relative;
  width: 100%;

  .koda-paragraph-outer-wrapper {
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
    padding: 40px 30px 20px 30px;
    margin: 40px 0;
    position: relative;
    width: 100%;
  }

  h2 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  a {
    &:link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  ul {
    > li {
      margin-bottom: 10px;
    }
  }

  @include respond-below(xl) {
    padding: 0 1rem !important;
  }

  @include respond-below(lg) {
    h2 {
      font-size: 25px !important;
      padding: 10px;
    }
  }

  @include respond-above(md) {
    .bullet_col {
      width: 50%;
      float: left;
      padding: 20px;
    }
  }
}

.roadshow-book-button {
  .field-name-paragraph-link-url {
    .field-link {
      @extend .btn-enquire;
      background: transparent;
      border: none;
      border-radius: 0;
      
      width: auto;
      &:hover {
        background: transparent !important;
      }
      @include respond-above(md) {
        padding-right: 75px;
      }
    }
  }
}

.roadshow-footer {
  margin-bottom: 4rem;
  h5 {
    font-size: 30px !important;
  }

  @include respond-below(lg) {
    padding: 0 1rem;
    margin-bottom: 2rem;
    h5 {
      font-size: 20px !important;
    }
  }
}

.roadshow-book-test-drive {
  &.redform {
    .webform-client-form {
      .form-control {
        font-size: 20px;
      }
      .form-actions {
        margin-top: 10px !important;
      }
    }
    margin-top: 3em;

    @include respond-above(sm) {
      .webform-component--first-name,
      .webform-component--last-name,
      .webform-component--email-address,
      .webform-component--phone-number,
      .webform-component--mitsubishi-model-interested-in,
      .webform-component--how-did-you-hear-about-this-event {
        margin-right: 35px;
        width: 46% !important;
        float: left;
      }
    }

    .webform-component--mitsubishi-model-interested-in {
      .form-select {
        option {
          color: #000;
        }
      }
    }

    label {
      margin-right: 1em;
    }
  }
}
