.anchor-block{
    text-align: center;
    .field-name-field-desc{
        p{
            margin: 0;
            font-size: 0;
        }
    }

    .field-name-field-desc{
        @include wrapper(absolute, 100%);
        left: 0;
        top:0;
        font-size: 0;
    }

    .field-name-paragraph-link-url{
        @include wrapper(absolute, 100%);
        left: 0;
        top:0;
        font-size: 0px;
        z-index: 50;
        text-align: center;
        margin-top: -40px;

        @include respond-above(desktop){
            margin-top: -60px;
        }
            
        .field-link{
            position: relative;
            font-size: 0px;

            &:hover{
                &::before{
                    border-color: $primary-color transparent transparent transparent;
                    line-height: 0;
                    _border-color: $primary-color #000000 #000000 #000000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                } 
            }

            &::before{
                content: "";
                @include myanimated;
                width: 50px;
                height: 50px;
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 10px;
                @include respond-above(mobile){
                    border-width: 20px 20px 0 20px;
                }
                line-height: 0;
                border-color: rgba(255, 255, 255, 0.35) transparent transparent transparent;                
                _border-color: rgba(255, 255, 255, 0.35) #000000 #000000 #000000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            }
        }
    }


    &.anchor-grey{
        .field-name-paragraph-link-url{
            .field-link{
                &::before{                    
                    border-color: rgba(51, 51, 51, 0.5) transparent transparent transparent;
                    _border-color: rgba(51, 51, 51, 0.5) #000000 #000000 #000000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                }

                &:hover{
                    &::before{
                        border-color: $primary-color transparent transparent transparent;
                        line-height: 0;
                        _border-color: $primary-color #000000 #000000 #000000;
                        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                    } 
                }

            } 
        }
    }

}


.trusted-mitsubishi-dealer-block{
    @include respond-below(mobile){
        .field-name-paragraph-link-url{
            display: none;
        }
    }
}