/**
Top Image Webform
**/

.topimg-webform {
  .koda-header-container {
    padding: 0 !important;

    h2 {
      border: none;
      color: #fff;
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 15px;
      padding: 0;
    }
  }

  .webform-component-markup {
    p {
      color: #fff !important;
      font-size: 18px;
    }
  }

  .webform-client-form {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    input,
    .form-control {
      font-size: 18px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .form-actions {
      margin-top: 1rem;
      justify-content: flex-start;
      .btn {
        font-size: 18px;
        text-transform: none;
        &:hover {
          background-color: #fff !important;
          color: $primary-color !important;
        }
      }
    }
  }

  @include respond-above(tablet) {
    max-width: 50%;
    margin-left: auto;
  }
}
