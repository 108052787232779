.hp-team{
    position: relative;

    @include respond-above(desktop){
        .koda-paragraph-inner-wrapper{
            display: flex;
            height: 100% !important;
            justify-content: center;
            align-items: stretch;
        }
    }

    //@extend .screenh100;
    
    .koda-multi-cols-3-column{
        
        > .koda-paragraph-inner-wrapper{
            > .koda-inner-column-paragraphs{
                > .paragraphs-items{
                    > .column{
                        margin-left: 0 !important;

                        @include respond-above(wide){
                            width: 33.3% !important;    
                        }
                        @include respond-between(mobile, wide){
                            width: 50% !important;    
                        }

                    }
                }
            }
        }
    }

    &.koda-multi-cols-2-column{
        > .koda-paragraph-inner-wrapper{
            > .koda-inner-column-paragraphs{
                > .paragraphs-items{
                    > .column{

                        width: 100% !important;
                        margin-left: 0 !important;

                        @include respond-above(desktop){
                            width: 50% !important;                           
                        }
                    
                        .entity-paragraphs-item{

                            @include respond-above(mobile){
                                display: flex;
                                flex-direction: column;
                                flex-wrap: wrap;
                            }

                        }
                                                                                            
                        &:first-child{

                            @extend .screenh100;

                            .entity-paragraphs-item{
                                display: flex;
                                align-items: center;
                                justify-content: center; 
                                @include respond-below(wide){
                                    padding-top: $grid-gutter-width;
                                }                      
                            } 
                            
                            @include respond-below(wide){
                                padding-left: $grid-gutter-width;
                                padding-right: $grid-gutter-width;
                                padding-bottom: $grid-gutter-width;
                            }

                            padding-right: $grid-gutter-width;

                            @include respond-above(wide){
                                @include flex-container(column, wrap, flex-start, center, flex-end);                    
                                > .entity-paragraphs-item{
                                    width: 75%;
                                }
                            }
                        
                            @include respond-below(mobile){
                                padding-left: $grid-gutter-width/2;
                                padding-right: $grid-gutter-width/2;
                            }

                            h2{
                                //font-size: 10vw;  
                                font-size: em(35);
                                text-align: center;
                                @include respond-above(desktop){
                                    font-size: 4vw;  
                                } 
                                @include respond-above(mobile){
                                    text-align: left;
                                }
                            }

                            h3{
                                //font-size: 6vw;
                                font-size: em(25);
                                text-align: center;
                                @include respond-above(desktop){
                                    font-size: 2vw;                                   
                                }
                                @include respond-above(mobile){
                                    text-align: left;
                                }
                                
                            }

                            h2, h3{
                                @include wrapper(relative, 100%);
                                margin: 0 0 10px 0;
                                padding: 0;
                            }

                            .field-name-field-desc{
                                font-size: em(14);
                                @include respond-between(tablet,wide){                                    
                                    p{
                                        font-size: inherit;
                                    }                                   
                                }
                                @include respond-below(mobile){
                                    font-size: em(12);
                                }

                                margin-top: $grid-gutter-width/2;
                                padding-top: $grid-gutter-width/2;
                                &::before{
                                    content: "";
                                    background-color: $primary-color;
                                    @include wrapper(absolute, 89px, 1px);
                                    top: 0;
                                    left: 0;

                                    @include respond-below(mobile){
                                        left: 25%;
                                        right: 25%;
                                        width: 50%;
                                    }

                                }
                            }

                        }



                        &:nth-child(2){
                            @extend .screenh100;
                            > .entity-paragraphs-item{
                                
                                > .koda-paragraph-inner-wrapper{
                                    width: 100%;
                                    > .koda-inner-column-paragraphs{
                                        width: 100%;
                                        //background-color: yellow;
                                    
                                        @include respond-below(tablet){
                                            height: 100%;
                                            height: 100vh;
                                            min-height: 640px;
                                            padding-top: 50px;
                                            padding-bottom: 50px;
                                            height: calc(var(--vh, 1vh) * 100);    
                                        }

                                        > .paragraphs-items{
                                            @include respond-below(tablet){
                                                //background-color: yellowgreen;
                                                align-content: flex-start;
                                            }
                                        }

                                    }
                                }
                            }

                            .entity-paragraphs-item{
                                @include respond-above(mobile){
                                    justify-content: flex-end;
                                }
                            }
                        }

                        
                    }
                }       
            }
        }
        

    }


    

}