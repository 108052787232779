.hp-featured{

    .paragraphs-item-koda-basic-paragraph{
        .block-title,
        h2{
            @include wrapper(relative, 100%);
            text-align: center;
            font-size: 9vw;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;   
            margin-top: 80px;  
            @include respond-above(wide){
                font-size: 4vw;
                padding-left: 15%;
                padding-right: 15%;
                margin-top: em(24);
            }
            @include respond-between(tablet, wide){
                font-size: em(35);
                margin-top: em(24);
            }
        }

    }

    &.koda-multicol.koda-multi-cols-2-column{

        .koda-paragraph-outer-wrapper{
            height: 100%;
            align-items: stretch;
        }

        .koda-paragraph-inner-wrapper{
            height: 100%;
            display: flex;
            .koda-inner-column-paragraphs > .paragraphs-items .column{

                @include respond-above(tablet){
                    width: 50%;
                    margin-left: 0 !important;
                }
                @extend .screenh100;
                .group-paragraph-info{
                    height: 100% !important;
                }             
                .field-name-paragraph-link-url,
                .field-name-field-link{
                    @include wrapper(absolute, 100%);
                    bottom: 0;
                    margin-bottom: 75px;

                    .field-items,
                    .field-item{
                        @include wrapper(relative, 100%);
                        text-align: center;
                        .field-link{
                            @extend .btn-red;
                        }
                    }
                }

                // Carousel
                .hp-carmodel-carousel{
                    .node-car{
                        &.view-mode-teaser{
                            //margin-top: $grid-gutter-width;

                            .field-name-node-link{
            
                                .field-items,
                                .field-item{
                                    @include wrapper(relative, 100%);
                                    text-align: center;
                                    a{
                                        @extend .btn-red;
                                    }
                                }

                            }
                        }
                    }
                }

                .hp-featured-right-section{
                    .anchor-taget-btn{
                        @include respond-above(tablet){
                            display: none;
                        }
                    }
                }


            }
        }
    } 

}