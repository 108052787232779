.newcars-tile-info{
    @include respond-above(mobile){
        padding-top: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width*2;
    }
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;

    .text50-img50{
        @include respond-above(mobile){
            .par-img{
                padding-left: $grid-gutter-width;         
            }
            .par-content{
                padding-right: $grid-gutter-width;   
            }     
        }       
    }

    .img50-text50{
        @include respond-above(mobile){
            .par-img{
                padding-right: $grid-gutter-width;         
            }
            .par-content{
                padding-left: $grid-gutter-width;   
            }   
        }     
    }

    .two-col-img-text{
        @include respond-below(mobile){
            padding-left: 0 !important;
            padding-right: 0 !important;

            .par-img{
                order: 1;
                margin-bottom: 10px; 
            }
            .par-content{
                order: 0;
            }

            .koda-paragraph-inner-wrapper{
                padding: $grid-gutter-width/2; 

                h2{
                    margin-top: 0;
                }
            }

        }
    }

    .koda-paragraph-outer-wrapper{

        &.wrap-left{

            .koda-paragraph-inner-wrapper{
                @include respond-above(mobile){
                    padding-left: $grid-gutter-width*2;
                }
            }
        }

        &.wrap-right{

            .koda-paragraph-inner-wrapper{
                @include respond-above(mobile){
                    padding-right: $grid-gutter-width*2;
                }
            }
    
        }

        .koda-paragraph-inner-wrapper{
            h2{       
                color: $heading-color !important; 
                @extend .title-line;
                margin-bottom: 25px;
                @include respond-below(desktop){
                    font-size: rem-calc(120) !important;        
                }
            }
        }

        

    }

}