.owl-carousel{
    @include wrapper(relative, 100%);
    @include flex-container(column, wrap, center, stretch, center);
    height: 100%;
    
    
    &.owl-theme{
        @include wrapper(relative, 100%);
        height: 100%;
        display: flex !important;
        flex-wrap: wrap;
        flex-direction: row;

        .owl-wrapper-outer{
            order: 2;              
        }

        .owl-controls{
            order: 1;
            flex-direction: row;
            @include wrapper(relative, 100%);              
        }
    
        .owl-buttons{
            @include wrapper(absolute, 100%, 100%);    
            //@include wrapper(relative, 100%);  
            top: 0;
            left: 0;
            margin-top: 120px; 
            z-index: 80;   
    
            div{
                @include wrapper(absolute, 53px, 53px);
                background: none !important;
                border-radius: 0;
                color: transparent;

                &.disabled{
                    opacity: 0.1;
                    &:hover{
                        opacity: 0.1;  
                    }
                }

                &::before{
                    @include wrapper(absolute, 100%, 100%);
                    top: 0;
                    left: 0;
                }
            }
    
            .owl-prev{
                left: 0;
                &::before{                  
                    content: url('../images/slick-arrow-left.png');
                }
            }
    
            .owl-next{
                right: 0;
                &::before{
                    content: url('../images/slick-arrow-right.png');
                }
            }
        }


    }

    

    
    
    
}
