/*------------------------------------*\
  Finance
\*------------------------------------*/

.m-assured-future-value{
    position: relative;
    padding: 2rem;
    @include extendbg(#fafafa);
    h2{
        color: #000;
        font-size: 50px;
        text-align: center;
        margin-bottom: 2rem;
    }
    h5{
        font-weight: bold;
        line-height: 1.2;
    }
    .field-item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .m-assured-future-value{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin: 0 auto;
        flex-direction: column;
        > a{
            min-width:270px;
            text-align: center;
            justify-content: center;
        }
    }
    br{
        display: none;
    }
    // Responsive
    @include respond-above(lg){
        .m-assured-future-value{
            flex-direction: row;
        }
        br{
            display: block;
        }
    }
}