.node-deal-range{

    //:---------------------------------------------------------------------------------------------------------
    &.view-mode-node_paragraph_teaser{

        @include wrapper(relative, 100%, 100%);
        @include flex-container(column, wrap, flex-start, stretch, flex-start);
        @include respond-above(mobile){
            flex-direction: row;
            background-color: rgba(234, 234, 234, 0.5);
        }

        margin-bottom: 30px;

        .field-name-field-range-deal-hero-image{
            @include wrapper(relative, 100%, 100%);
            @include flex-container(column, wrap);

            @include respond-above(mobile){
                //width: 546px;
                width: 40%;
                padding: 0;
            }

            padding: 0 $grid-gutter-width/2;

            .field-items,
            .field-item{
                @include wrapper(relative, 100%);
            }

            img{
                //@include wrapper(relative, 100%, 362px);
                @include wrapper(relative, 100%);
                @include object-fit(cover);
                object-position: center center;
            }
        }

        h2, h3, h4, h5{
            color: $black;
            a{
                color: $black;
                text-decoration: none;
                &:link,
                &:visited,
                &:active{
                    text-decoration: none;
                }
                &:hover{
                    color: $primary-color;
                }
            }
        }


        h2{
            font-size: em(28);
            font-weight: 600;
            margin-top: 0;
            @include respond-above(tablet){
                font-size: em(35);
            }
        }

        h3{
            font-size: em(25);
            font-weight: 300;
        }

        h4{
            font-size: em(22);
            font-weight: 300;
        }

        h5{
            font-size: em(18);
            font-weight: 300;
        }

        .field-name-body{
            line-height: 1.6em;
            p{
                line-height: inherit;
            }
        }


        .field-name-node-link{
            margin-top: 15px;

            .field-item{
                text-align: center;
                @include respond-above(mobile){
                    text-align: left;
                }
            }

            a{
                @extend .btn-dark;
            }
        }


    }
    //:---------------------------------------------------------------------------------------------------------
    &.view-mode-full{

        h3, h4, h5{
            color: $black;
            a{
                color: $black;
                text-decoration: none;
                &:link,
                &:visited,
                &:active{
                    text-decoration: none;
                }
                &:hover{
                    color: $primary-color;
                }
            }
        }

        h3{
            font-size: em(28);
            font-weight: 600;
        }

        h4{
            font-size: em(22);
            font-weight: 300;
        }

        h5{
            font-size: em(18);
            font-weight: 300;
        }

        .page-title{
            margin-left: 0;
            @include respond-above(desktop){
                width: 80%;
            }
        }

        .field-name-body{
            margin-bottom: $grid-gutter-width;
        }

    }
    //:---------------------------------------------------------------------------------------------------------

}
