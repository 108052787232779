.footer-social-icons {

  a {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    overflow: hidden;
    text-align: center;
    background-color: $white;
    @include border-radius(100%);

    &:hover{
      background-color: $primary-color;
      &:before{
        color: $white;
      }
    }


    &:before {
      content: "";
      display: block;
      color: #202020;
      @include wrapper(absolute, 100%, 100%);
      text-align: center;
      top:0;
      left: 0;
      line-height: 33px;
      font-size: 18px;
      font-family: $font-family-icon-fa;
    }
  }

  > a:not(:first-child) {
    margin-left: 7px;
  }

  .facebook-icon:before {
      content:"\f09a";
  }

  .twitter-icon:before {
      content:"\f099";
  }

  .youtube-icon:before {
    content:"\f167";
  }

  .linkedin-icon:before {
      content:"\f0e1";
  }

  .instagram-icon:before {
      content:"\f16d";
  }

  .sharelinks-icon:before {
      content:"\f1e0";
  }

  .world-icon:before {
      content:"\f09a";
  }
}
