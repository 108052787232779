.block-contact-staff{

    .slick-dots{
        li{
            padding: 0 em(10) !important;
        }
    }

    .koda-block-container{
        h2{         
            font-weight: 600;
            margin: 0 0 em(20) 0;
            padding: 0;
            color: $black;
            line-height: 1.8em;
            letter-spacing: 0.02em;
            word-break: break-word;
            font-size: em(20) !important;
            @include respond-above(mobile){
                font-size: 50px !important;
            }
        }
    }

    
    ul.staff-list{
        margin-left: 0;
        padding: 0;
        width: 100%;
        list-style: none;

        li{
            position: relative;
            margin: 0;
            padding-bottom: $grid-gutter-width/2;

            @include respond-above(tablet){
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: stretch;
                width: 33.3%;
                padding-right: em(20);
            }

            h3,
            strong{
                font-size: em(15);
                font-weight: 600;
                margin: 0;
                padding: 0;
                line-height: 1.6em;
                letter-spacing: 0.02em;
                word-break: break-word;
            }

            a{
                @include wrapper( relative, 100%);
                display: block;
                letter-spacing: 0.02em;
                word-break: break-word;
                font-size: em(15);
                line-height: 1.6em;
                font-weight: normal;

                &:hover{
                    text-decoration: underline;
                }

                &.phone{
                    color: $text-color;
                }

            }

        }

    }


}