.paragraph--type-content-paragraph{

    .content-group{
        @include flex-container(column, wrap);
    }
    .content-image{
        @include flex-container(column, wrap);
    }

    &.swap-left-right{
        .content-group{           
            order: 1;
        }
        .content-image{
            order: 0;
        }
    }

}
