.used-cars-buttons{

    .test-driven{
        h2{         
            @include respond-above(desktop){     
                font-size: 27px;
            }
        }
    }

    h2{
        @include wrapper(relative, 100%);
        font-size: 16px;
        margin: 0 !important; 
        text-align: center;

        @include respond-between(mobile, desktop){      
            font-size: 20px;  
        }

        @include respond-above(desktop){     
            font-size: 39px;
            margin: 0 0 10px 0 !important;
        }
    }

    h3{
        @include wrapper(relative, 100%);
        font-size: 12px;
        font-weight: normal;
        margin: 0 !important;
        text-align: center;

        @include respond-between(mobile, desktop){      
            font-size: 14px;
        }

        @include respond-above(desktop){   
            font-size: 17px;
        }
    }

    .koda-inner-column-paragraphs{

        @include wrapper(absolute, 100%);
        top:0;
        left: 0;
        margin-top: -450px;  
        z-index: 50;
        text-align: center;
        justify-content: center;

        @include respond-above(tablet){
            margin-top: -500px;  
        }

        > .paragraphs-items-field-paragraphs{            
            @include respond-above(wide){
                width: 65% !important;
            }
        }

    }


    .column{

        @include respond-below(tablet){
            width: 50% !important;
            margin-left: 0 !important;
            margin-bottom: $grid-gutter-width/2;
        }

        .entity-paragraphs-item{

            .koda-paragraph-outer-wrapper{

                align-content: center;

                .koda-paragraph-inner-wrapper{
                    
                    @include border-radius(100%);
                    @include myanimated;
                    display: flex;
                    align-items: center;
                    position: relative;
                    justify-content: center;
                    padding: 20px;
                    border: 6px solid rgba(255, 255, 255, 0.3);
                    background: rgba(0, 0, 0, 0.4);

                    width: 120px !important;
                    height: 120px !important;
                
                    @include respond-between(mobile, desktop){        
                        width: 150px !important;
                        height: 150px !important;
                    }     

                    @include respond-above(desktop){        
                        width: 196px !important;
                        height: 196px !important;
    
                    }

                    &:hover{
                        background-color: rgba(222, 26, 34, 0.9);
                        color: $white;
                        h2, h3{
                            color: $white !important; 
                        }
                    }

                }

            }

        }

    }

}