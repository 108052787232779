.range-price{
    display: block;
    position: relative;
    color: $black;
    width: 100%;

    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    span{
        @include wrapper(relative, 100%);
        display: block;
        text-align: center;
    }

    .price-wrapper{
        @include flex-container(row, wrap, center, center, center);
        label{
            font-size: em(30); 
            font-weight: 600;
            line-height: em(30); 
       }
   
        h3{
            position: relative;
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: em(30);
            line-height: em(30); 
            display: block;
           
            &::before{
                content: "$";
                margin-right: 4px;
            }
        }
    }

    

}