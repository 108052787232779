.page--clean-car-roadshow,
.page--triton-launch {
  // Hero Modification

  h2,
  h3,
  h4,
  h5 {
    color: #181818;
    line-height: 1.4;
  }

  h5 {
    font-size: 30px;
  }

  .hero-heading {
    @include respond-below(xxl) {
      .koda-block-container {
        h2 {
          font-size: 80px;
        }
      }
    }
    @include respond-below(lg) {
      .koda-block-container {
        margin-bottom: 30vw;
        h2 {
          font-size: 40px;
        }
      }
    }

    @include respond-below(md) {
      .koda-block-container {
        margin-bottom: 50vw;
      }
    }
  }

  #block-fieldblock-node-page-default-field-header-paragraphs {
    width: 100%;
    z-index: 50;
    bottom: 0;
    position: absolute;

    @include respond-above(lg) {
      top: 0;
      bottom: auto;
      margin-top: 100px;
      .koda-paragraph-outer-wrapper.wrap-center {
        align-content: flex-end;
      }
    }
  }

  .book-button {
    @extend .roadshow-book-button;
  }

  .view-hero-image-layout {
    .views-field-field-hero-image {
      .Screen {
        img {
          height: 40vh;
          @include respond-above(sm) {
            height: 80vh;
          }
          @include respond-above(xl) {
            height: 140vh;
          }
        }
      }
    }
  }

  #contentblock .content-col {
    min-height: 60vh;
  }

  .redform {
    .webform-client-form {
      .form-control {
        font-size: 20px;
      }
      .form-actions {
        margin-top: 10px !important;
      }
    }
    margin-top: 3em;

    @include respond-above(sm) {
      .webform-component--first-name,
      .webform-component--last-name,
      .webform-component--email-address,
      .webform-component--phone-number {
        margin-right: 35px;
        width: 46% !important;
        float: left;
      }
    }
    label {
      margin-right: 1em;
    }
  }
}

// Page PHEV Competition Onsite
.page--phev-competition-onsite,
.page--phev-competition-online,
.page--phev-competition {
  #contentblock .content-col {
    min-height: 60vh;
  }

  .field-name-body {
    margin-top: 2em;
  }

  .competion-summary {
    margin: 2em 0;

    @include respond-below(xxl) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include respond-below(md) {
      font-size: 16px;
    }
  }
}
