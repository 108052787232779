.redform{
    // Block CSS
    .koda-header-container{
        @include wrapper(relative, 100%);
        padding: 0 0 15px 0; 
        @include respond-above(wide){
            padding: 0 0 $grid-gutter-width/2 0; 
        }
        h2{
            @include wrapper(relative, 100%);   
            padding-left: 30px;
            //margin: 0 0 em(15) 0;
            font-size: em(35);
            margin-top: 0;

            @include respond-above(wide){
                border-left: 4px solid $primary-color;
                font-size: 60px;
            }
        }
    }

    .webform-client-form{
        margin: 0;
        background: url('../images/enquire-now-bg.png') 0 0 no-repeat;
        background-size: cover;
        background-color: $primary-color;
        @include wrapper(relative, 100%);
        padding: $grid-gutter-width 30px;

        h3, h4, h5, h6{
            margin-bottom: 0;
            color: #FFF;
        }

        input{
            &[type="file"]{
                font-size: 20px;
                @extend .btn-primary;
            }
        }

        .form-item{
            &.webform-component-file{
                border: none !important;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .control-label{
                    margin-bottom: 1rem;
                }

                .form-managed-file.input-group{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    background-color: #FFF;

                    input{
                        &.form-control.form-file{
                            padding: 1rem;
                            font-size: 14px;
                            border: none !important;
                            background-color: #FFF;
                            color: #000;
                            flex: 0 0 auto;
                        }
                    }
                    
                    .input-group-btn{
                        flex: 1;
                        .btn-primary.btn{
                            width: 100%;
                            background-color: #292929;
                            margin: 0;
                        }
                    }
                }

                .help-block{
                    color: #FFF;
                    font-weight: 300;
                    font-size: 14px;
                }
            }
        }

        .webform-component{
            &--enquire-anchor-markup{
                > p{
                    margin-bottom: 0;
                }
            }
        }

        .form-group{
            &:not(.webform-component-markup){
                border-bottom: 1px solid $white;
            }
            @include wrapper(relative, 100%);        
            @include flex-container(row, wrap, flex-start, center, center);
            .control-label{
                @include flex-container(row, wrap);
                flex: 1;
                margin-bottom: 0;
                color: $white;
                font-weight: normal;

                @include respond-above(mobile){
                    width: auto;
                    flex: 0 0 auto;
                }
            }
            .form-control{
                position: relative;
                width: 100%;
                @include respond-above(mobile){
                    @include flex-container(column, wrap);
                    flex: auto;
                    width: 70%;
                }
                &.form-textarea{
                    width: 100%;
                }                
            }
        }

        .form-control{
            border-radius: 0px;
            background-color: transparent;
            border: none;
            color: $white;
            box-shadow: none;
            padding: 0;
            font-size: 45px;
            height: auto;
            font-weight: normal;      
        }

        .form-actions{
            @include wrapper(relative, 100%);
            @include flex-container(row, wrap, flex-end);
            margin-top: $grid-gutter-width;

            .form-submit{
                @include myanimated;
                background-color: #292929;
                @include border-radius(53px);
                text-transform: uppercase;
                padding: 0.2em 2.5em;
                //padding: 6px 45px;
                font-size: 22px;
                border: none;
                color: $white;
                &:hover{
                    background-color: $black;
                }
            }
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $white;
            font-size: 20px;
            opacity: 1; /* Firefox */
        }
          
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $white;
            font-size: 20px;
        }
          
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: $white;
            font-size: 20px;
        }

        .resizable-textarea .grippie{
            border: none;
            background: none;
        }

        fieldset{
            position: relative;
            background: transparent;
            border: none;
            border-radius: 0;

            legend{
                @include wrapper(relative, 100%);
                padding: 0;
                margin: 0 0 $grid-gutter-width 0;
                border: none;
                border-radius: 0;
                background: transparent;

                span{
                    @include wrapper(relative, 100%);
                    margin: 0;
                    padding: 0;
                    color: $white;
                    font-weight: 600;
                }
            }
        }



    }
}