.koda-tabs-layout{
    // PARAGRAPH
    .koda-tabs-paragraph{
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, center, center, center);

        // TABS
       
        .koda-nav-tabs{
            @include wrapper(relative, 100%);
            margin-bottom: $grid-gutter-width/2;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;

            @include flex-container(column, wrap, center, center, center);
            @include respond-above(tablet){
                flex-direction: row;
                width: auto;
            }

            @include respond-above(mobile){
                padding-left: 0;
                padding-right: 0;
            }

            > li{
               border: none;
               border-radius: 0; 
               font-size: 27px;
               color: $black;
               border: none;
               margin-bottom: 0;

               &.active,
               &:hover,
               &:focus{

                    background-color: transparent;
                    a{
                        background-color: transparent;
                    }
               }

               &.active{
                    a{                        
                        background-color: $primary-color;
                        color: $white;
                        @include respond-above(tablet){
                            background-color: transparent;
                            color: $black;
                            border-bottom: 10px solid $primary-color;
                        }
                    }
                }
               
               a{
                border-radius: 0;   
                border: none;
                color: $black;
                margin: 0;
                @include respond-above(tablet){
                    border-bottom: 10px solid transparent;
                }
               }

               
            }
        }
       
    }

}


.koda-tabs-layout-medium{
    // PARAGRAPH
    .koda-tabs-paragraph{
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap, center, center, center);

        // TABS
        .koda-nav-tabs{
            @include wrapper(relative, 100%);
            margin-bottom: $grid-gutter-width;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;

            @include flex-container(column, wrap, center, center, center);
            @include respond-above(tablet){
                flex-direction: row;
                width: auto;
            }
            
            @include respond-above(mobile){
                padding-left: 0;
                padding-right: 0;
            }

            > li{
               border: none;
               border-radius: 0; 
               font-size: 22px;
               color: $black;
               border: none;
               margin-bottom: 0;

               &.active,
               &:hover,
               &:focus{
                    background-color: transparent;
                    a{
                        background-color: transparent;
                    }
               }

               &.active{
                    a{
                        background-color: $primary-color;
                        color: $white;
                        @include respond-above(tablet){
                            color: $black;
                            background-color: transparent;
                            border-bottom: 5px solid $primary-color;
                        }
                    }
                }
               
               a{
                border-radius: 0;   
                border: none;
                color: $black;
                margin: 0;
                text-transform: uppercase;
                

                @include respond-above(tablet){
                    border-bottom: 5px solid transparent;
                }
               }

               

            }
        }
    }

}


.koda-tabs-paragraph{
    .koda-nav-tabs{
        @include respond-below(tablet){
            li{
                width: 100%;
                a{
                    text-align: center;
                }
            }
        }
    }
    .nav-tabs{
        @include respond-below(tablet){
            border-bottom: none;
        }
    }
}





