.quiz-title{

    margin-top: em(15);

    h1{
        font-size: em(25);
        @include wrapper(relative, 100%);
        text-align: center;
        margin-top: 0;
        color: $heading-color;
    }

    @include respond-above(wide){
        padding-left: 0;
        padding-right: 0;
        font-size: 45px;
    }

    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    
}