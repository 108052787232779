/*------------------------------------*\
  Careers Page : Basic Page
\*------------------------------------*/
.careers{
    position: relative; 
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    .paragraphs-items{
        > .column{
            margin-bottom: 4rem;
        }
    }
    h2,
    .global-title{
        width: 100%;
        font-size: 6.0rem;
        text-align: center;
        color: #000;
        margin-top: 0;
        margin-bottom: 2rem;
        word-break: break-word;
    }
    /** Content **/
    &__content{      
        .field-name-paragraph-link-url{     
            position: relative !important;  
            display: flex;
            justify-content: center;
            align-items: center;
            .field-link{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;
                font-weight: 600;
                width: 150px;
                margin: 0 auto;
                text-transform: uppercase;
                &::before{
                    @include myanimated;
                    border-radius: 100%;
                    border: 4px solid rgba(222, 41, 16, 0.3);
                    content: "";
                    width: 84px;
                    height: 84px;
                    margin-bottom: 8px;
                    background: url('../images/icons/icon-job-vacancies.svg') center center no-repeat;
                }
                &:hover{
                    &::before{
                        border-color: rgba(222, 41, 16, 0.8);
                    }
                }
            }
        }
    }
    /** Video  **/
    &__video{
        .media-youtube-video{
            position: relative;
            padding-bottom: 56%;
            iframe{
                position: absolute !important;
                width: 100% !important;
                height: 100% !important;
                top: 0;
                left: 0;    
            }
        }
    }
    /** Testimonials **/
    &__testimonials{
        padding: 8rem $grid-gutter-width / 2;
        .global-title{
            margin-bottom: 4rem !important;
            font-size: 4rem;
            word-break: break-word;
        }
        > .koda-paragraph-inner-wrapper{
            padding: 0 !important;
        }

        /*------------------------------------*\
        Staff View
        \*------------------------------------*/
        .view-staff-testimonials{

            .node-staff-testimonial{   
                
                .staff-testimonial-content{
                    padding-bottom: 4rem;
                }
                .field-name-body{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    > div{
                        margin-top: auto;
                        .rm-link{
                            &:link,
                            &:visited{
                                text-decoration: none;
                            }
                        }
                    }
                }

                height: auto;
                &.expanded{
                    height: 100%;
                } 

                @media all and (min-width: 880px){
                    height: 70rem;
                    &.expanded{
                        height: auto;
                    } 
                }
            }

            .slick{
                .slick-list{
                    display: flex;
                    .slick-track{
                        display: flex;
                        align-items: stretch;
                        height: auto;
                        > .slide.slick-slide{
                            display: flex;
                            padding: 0 2rem;    
                        }
                    }
                }
                &__arrow{
                    .slick-arrow{
                        width: auto;
                        height: auto;
                        &::before{
                            font-family: $font-family-icon-fa;
                            font-size: 7rem;
                            color: #FFF;
                        }
                        &.slick-prev{
                            &::before{
                                content: "\f104";
                            }
                            margin-left: -20px;
                        }
                        &.slick-next{
                            &::before{
                                content: "\f105";
                            }
                            margin-right: -20px;
                        }
                    }
                }
            // Slick    
            }
        }

    }

    /** Job Vacancies **/
    &__job-vacancies{
        padding: 4rem 0;
        .block-title{
            font-size: 4rem;
            width: 100%;
            margin-top: 0;
            margin-bottom: 2rem;
            text-align: center;
            word-break: break-word;
        }
        > .koda-paragraph-inner-wrapper{
            padding: 0 !important;
        }
    }

    /** Responsive **/
    @include respond-between(md, max){
        &__content{     
            .group-paragraph-info{
                display: flex;
                flex-wrap: wrap;
                .field-name-field-title{
                    flex: 0 0 100%;
                }
                .field-name-field-desc{
                    flex: 1;
                }
            }
        }
    }

    @include respond-between(lg, max){
        > .koda-paragraph-inner-wrapper{
            .koda-inner-column-paragraphs {
                padding: 0 3rem;
            }
        }
    }

    @include respond-above(lg){
        padding-left: 0;
        padding-right: 0;
        .paragraphs-items{
            > .column{
                margin-bottom: 8rem;
            }
        }
        h2,
        .global-title{
            margin-bottom: 4rem !important;
        }
        &__video{
            padding-left: 8rem;
            padding-right: 8rem;
        }

        &__testimonials{
            .global-title{
                font-size: 6rem;
            }
            .paragraphs-items{
                padding-left: 0;
                padding-right: 0;
                > .column .group-paragraph-info > .field > .field-items{
                    flex-direction: row;
                    > .field-item{
                        width: calc(33.3% - 1.5rem);
                    }
                }
            }
        }
        /** Job Vacancies **/
        &__job-vacancies{
            padding: 8rem 0;
            .block-title{
                font-size: 6rem;
                margin-bottom: 6rem;
            }
        }
    }

    @include respond-above(max){

        &__content{      
            .field-name-paragraph-link-url{      
                position: absolute !important;
                right: 0;
                top: 0;
                margin-right: -10%;
            }
        }

        &__testimonials{
            .paragraphs-items{
                padding-left: 0;
                padding-right: 0;
                > .column .group-paragraph-info > .field > .field-items{
                    gap: 5rem;
                }
            }
        }
    }

}

/*------------------------------------*\
  Careers : Content Type
\*------------------------------------*/
.careers{
    /*------------------------------------*\
      Full
    \*------------------------------------*/
    &__full{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        color: #323232;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;

        .field-name-title{
            width: 100%;
            h1{
                width: 100%;
                text-align: center;
                color: #000;
                margin-top: 0;
                margin-bottom: 4rem;  
                text-align: left;
            }
        }

        .field-name-body{
            p,
            li{
                font-weight: 300;
                line-height: 1.6;
            }
        }

        .field-name-field-career-main-image{
            width: 100%;
            margin-bottom: 4rem;
            img{
                width: 100%;
            }
        }

        .field-name-field-video{
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 4rem;
            .media-youtube-video{
                position: relative;
                padding-bottom: 56%;
                iframe{
                    position: absolute !important;
                    width: 100% !important;
                    height: 100% !important;
                    top: 0;
                    left: 0;    
                }
            }
        }

        /** Apply Form **/
        form{
            &.webform-client-form{
                margin-top: $grid-gutter-width / 2;
                margin-bottom: $grid-gutter-width / 2;
                padding:$grid-gutter-width / 2;

                .form-actions{
                    margin-top: 0 !important;
                    justify-content: center;
                }

                div{
                    &[id*="submitted-attach"]{
                        margin-bottom: 0;
                        .form-item{
                            margin-bottom: 0; 
                        }
                    }
                }
                .alert{
                    font-size: 14px;
                }

            }   
        }

        &--testimonials{
            // Sidebar
            position: relative;
            width: 100%;
            padding-top: 4rem;
            background: linear-gradient(0deg, #171717 0%, #4D4D4D 100%);
            h2{
                margin-top: 0;
                font-size: 4rem; 
                text-align: center;
                color: #FFF;
                margin-bottom: 4rem;
            }
            .staff-testimonial-content{
                width: calc(100% - 2rem);
                margin-left: 1rem;
                margin-bottom: 1rem;
                padding-left: 2rem;
                padding-right: 2rem;
                .field-name-body{
                    font-size: 18px; 
                }
                a.rm-link{
                    font-size: 18px;
                }
            }
        }

        &--reference{
            margin-bottom: 4rem;
            > .field{
                &:not(:first-child){
                    margin-top: 2rem;
                }
                .field-label{
                    font-weight: 600;
                    width: 18rem;
                }
            }
        }
        
        &--sidebar{
            .field{
                ul{
                    margin: 0 0 4rem 0;
                    padding: 0;
                    li{
                        display: flex;
                        &::before{
                            content:"\f105";
                            font-family: $font-family-icon-fa;
                            margin-right: 2rem;
                            font-weight: 600;
                        }
                        > a{
                           color: #DE1A22;
                           font-weight: 600; 
                           &:hover{
                               color: #000;
                           }
                           &:visited,
                           &:active{
                               text-decoration: none;
                           }
                        }
                        &:first-child{
                            border-top: 1px dashed #D3D3D3;
                        }
                        border-bottom: 1px dashed #D3D3D3;
                        padding: 1rem 0;
                        list-style: none;
                    }
                }

                &.field-name-field-image{
                    .field-items{
                        position: relative;
                        .field-item{
                            margin-top: 4rem;
                            @include imgZoomHover;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }

            }
        }

        /** Responsive Full**/
        @include respond-above(md){
            flex-direction: row;
            .field-name-title{
                h1{
                    font-size: 7.2rem;
                }
            }
            &--content{
                flex: 1;
                padding-right: 8rem;
            }
            
            &--sidebar{
                width: 30rem;
            }
        }

        /** Responsive **/
        @include respond-above(lg){

            &--sidebar{
                width: 40rem;
            }

            form.webform-client-form{

                padding-left: 40px;
                padding-right: 40px;

                > div{
                    display: flex;
                    flex-wrap: wrap;
                }
    
                div{
                    &[id*="submitted-attach"]{
                        padding-bottom: 0;
                        max-width: calc(50% - 30px);
                    }
    
                    &.form-actions{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: auto !important;
                        margin-left: auto;
                    }
                }
            }

        }
        @include respond-above(max){
            padding-left: 0;
            padding-right: 0;
        }

    }


    /*------------------------------------*\
      Teaser
    \*------------------------------------*/
    &__teaser{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;

        .field-name-field-thumbnail{
            .field-items,
            .field-item{
                height: 100%;
            }
            @include imgZoomHover;
            img{
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &--description{
            width: 100%;
            background-color: rgba(234, 234, 234, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $grid-gutter-width / 2;

            h3{
                color: #000;
                font-size: 3rem;
                a{
                    color: inherit;
                }
            }
            .field-name-body{
                padding: 0 !important;
                width: 100%;
            }
            p{
                font-weight: 300;
                margin-bottom: 2rem;
            }
        }

        /** Responsive Teaser **/
        @include respond-between(md, lg){
            .field-name-field-thumbnail{
                width: 40%;
            }
            &--description{
                width: 60%;
            }
        }
        @include respond-above(md){
            &--description{
               padding: 0;
             
               h3{
                font-size: 4.5rem;  
               }
               flex: 1;
               padding: 3rem 6rem;
            }
            .btn-dark{
                margin-left: 0;
            }
        }
        @include respond-above(max){
            padding-left: 0;
            padding-right: 0;
        }
    }

}

/*------------------------------------*\
    View
\*------------------------------------*/
.view-job-vacancies{
    .view-content{
        .views-row{
            &:not(:first-child){
                margin-top: 4rem;
            }
        }
    }
}