.banner-heading-paragraph {
  @include wrapper(relative, 100%);
  //overflow: hidden;

  .par-content.group-right {
    .par-inner-layer {
      position: relative;
      z-index: 100;
    }
    .field-name-field-hero-media {
      padding-bottom: $grid-gutter-width/2;
    }
    @extend .img-gradient-cover;
  }

  .two-col-img-text {
    &.img100-text100,
    &.text50-img100,
    &.img100-text50 {
      .field-name-field-title {
        h2 {
          margin-top: 0;
          margin-bottom: 15px;
          padding: 0;
          line-height: 1em;
          @include respond-above(wide) {
            font-size: 5em;
          }
        }
      }

      .field-name-field-sub-title {
        h3 {
          font-size: 30px;
          margin-bottom: 15px;
          @include respond-above(wide) {
            font-size: 3em;
          }
        }
      }

      .par-content.group-right {
        color: $white;
      }

      .par-img.group-left {
        @include wrapper(relative, 100%);
        margin: 0;
        padding: 0;
        flex: auto;

        .field-items,
        .field-item {
          @include wrapper(relative, 100%);
          margin: 0;
          padding: 0;
        }

        img {
          position: relative;
          width: 100% !important;
          height: $slider-img-max-height;
          min-height: $slider-img-min-height;
          @include object-fit(cover);
          object-position: center center;
        }
      }

      .par-inner-layer {
        > .paragraphs-items {
          @include wrapper(relative, 100%, 100%);

          //padding-top: 90px;
          //padding-bottom: 80px;

          @include respond-above(desktop) {
            padding-top: 80px;
            padding-bottom: 80px;
          }

          > .field-name-field-desc-basic-paragraph {
            @include wrapper(relative, 100%, 100%);

            > .field-items {
              @include wrapper(relative, 100%, 100%);
              > .field-item {
                @include wrapper(relative, 100%, 100%);

                > .paragraphs-item-koda-basic-paragraph {
                  @include wrapper(relative, 100%, 100%);
                }

                > .paragraphs-item-koda-basic-paragraph
                  > .koda-paragraph-outer-wrapper {
                  @include wrapper(relative, 100%, 100%);
                  justify-content: center;

                  > .koda-paragraph-inner-wrapper {
                    @include respond-below(tablet) {
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      padding: 4rem 0;

                      .field-name-field-hero-media,
                      .group-paragraph-info {
                        margin-top: auto;
                      }

                      > .group-paragraph-info {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .wrap-center {
      .group-paragraph-info {
        text-align: center;
      }
    }
  }

  // Option : Slider Position
  .content-top {
    .koda-paragraph-outer-wrapper {
      justify-content: flex-start !important;
    }
  }

  .content-middle,
  .content-center {
    .koda-paragraph-outer-wrapper {
      justify-content: center !important;
    }
  }

  .content-bottom {
    .koda-paragraph-outer-wrapper {
      justify-content: flex-end !important;
    }
  }

  .par-content {
    .group-paragraph-info {
      color: $white;
      @include text-shadow;
    }
  }

  .group-paragraph-info {
    h2,
    h3 {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .field-name-paragraph-link-url {
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
    .field-link {
      @extend .btn-red;

      @include respond-below(tablet) {
        font-size: 14px !important;
      }
    }
  }
}
