/*------------------------------------*\
  Accessory Page
\*------------------------------------*/
.view-accessory {
    padding-top: $grid-gutter-width;
    @extend .fluid-container;
    > .view-content {
        display: grid;
        > .views-row {
            .cboxElement {
                &:focus,
                &:active {
                    outline: none;
                    color: inherit;
                    text-decoration: none;
                }
                @include imgZoomHover;
            }
        }
    }

    .car-inlin-acc {
        &-icon-img {
            overflow: hidden;
            img {
                @include wrapper(relative, 100%, 24.3rem);
                @include object-fit(cover);
            }
        }
        &-popup-info {
            padding: 2rem 0;
        }
        &-icon-title {
            > h2 {
                margin: 0;
                width: 100%;
                font-size: 2.4rem;
                line-height: 1.4em;
                color: $black;
                text-align: center;
                font-weight: 400;
                text-decoration: none;
                outline: none;
            }
        }
        &-icon-price {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0.5rem 0;
            font-weight: normal;
            font-size: 2.4rem;
            color: #000;
        }
    }

    // Responsive
    @include respond-above(md) {
        > .view-content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2rem 4rem;
        }
    }
    @include respond-above(xl) {
        > .view-content {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 3rem 6rem;
        }
    }
}
