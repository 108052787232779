.node-page{
    &.view-mode-menu_teaser{
        @extend .nav-menu_teaser; 
    }

    // Full
    &.view-mode-full{
       .field-name-body{
           @include respond-below(wide){
                padding-left: $grid-gutter-width/2;
                padding-right: $grid-gutter-width/2;
           }
       } 

       .paragraphs-items-field-paragraphs{
            .koda-tabs-paragraph{
                .koda-nav-tabs{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
       }

       .entity-paragraphs-item{
           .field-name-field-title{
               h2{
                  @include respond-below(tablet){
                      font-size: em(30);
                  }
               }
           }
       }

    } 



}

