.view-hero-image-layout{
    @include wrapper(relative, 100%);
    .views-field-field-hero-image{
        @include wrapper(relative, 100%);

        .field-content{
            @include wrapper(relative, 100%);
            
        }

        img{
            @include wrapper(relative, 100%);
            @include object-fit(cover);
            object-position: center center;         
        }

        .Screen{
            @include wrapper(relative, 100%);
            @extend .img-gradient-cover;
            img{
                height: $slider-img-max-height;
                min-height: $slider-img-min-height;              
            }
        }

        .Medium{
            @include wrapper(relative, 100%);
            @extend .img-gradient-cover;
            img{
                height: $slider-img-min-height;
                @include respond-above(tablet){
                    height: $slider-img-medium-height;
                    min-height: $slider-img-min-height;
                }
            }
        }


    }
}