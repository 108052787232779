.region-header-content{
    @include wrapper(relative, 100%);
    @include respond-below(wide){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    @include flex-container(column, wrap, space-between, stretch, center);
    @include respond-above(mobile){
        flex-direction: row;
    }


     //:-------------------------------------------------------------------------------------------------------------------------

    .block-easy-breadcrumb{
        @include flex-container(row, wrap);
        flex: auto;
        width: auto;
    }

}