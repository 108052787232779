.sidebar{

    .block-title{
        text-align: left !important;
    }
    .block-sharethis .sharethis-wrapper{
        justify-content: flex-start;
    }


}