.faq-accordion {
  @include respond-between(mobile, wide) {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
}

.koda-accordion {
  h4 {
    margin: 0;
    @include respond-below(tablet) {
      font-size: em(18);
    }
  }

  a.accordion-header {
    color: $black;
    font-weight: 600;
    padding: 11px 0;
    display: flex;
    align-items: center;
    font-size: 2.4rem;

    &::after {
      color: $brand-primary;
      font-weight: normal;
      margin-left: auto;
      margin-right: 18px;
    }

    &:not(.collapsed) {
      background-color: transparent;
    }

    &:focus {
      text-decoration: none;
    }
  }

  .panel-collapse {
    background-color: transparent;
  }
}
