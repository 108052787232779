.region-footer-top{
    @include wrapper(relative, 100%);
    @include flex-container(column, wrap);
    background-color: rgba(50, 50, 50, 0.8);
    
    .block-block{
        .koda-block-container{
            @extend .container-fluid;
            p{
                margin-bottom: 0;
            }
        }
    }


    
    .footer-contact{
        @include wrapper(relative, 100%);

        .koda-block-container{

            @extend .fluid-container;
            padding-top: 30px;
            padding-bottom: 30px;

            .webform-client-form{
                @include wrapper(relative, 100%);

                > div{

                    @include wrapper(relative, 100%);
                    @include flex-container(column, wrap, flex-start, stretch, center);
                    @include respond-above(desktop){
                        flex-direction: row;
                    }

                    .form-item{
                        @include flex-container(column, wrap, center, stretch, flex-start);
                        flex: 1;
                        margin-bottom: 0;
                        @include respond-above(wide){
                            padding-left: $grid-gutter-width/2;
                            padding-right: $grid-gutter-width/2;
                        }
                        @include respond-between(tablet, wide){
                            padding-left: 20px;
                            padding-right: 20px;
                        }

                        p{
                            margin-bottom: 0;
                        }

                        .form-control{
                            @include border-radius(0);
                            background: transparent;
                            box-shadow: none;
                            border: none;
                            color: $white;
                            border-bottom: 1px solid $gray-10;

                            @include placeholder-color($gray-light-11);
                        }

                    }

                    .webform-component-markup{
                        text-align: center;
                        margin-bottom: 20px;

                        p{
                            @include wrapper(relative, 100%);
                        }

                        @include respond-above(desktop){
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                    
                    .form-actions{
                        
                        margin-bottom: 0;
                        text-align: center;

                        .btn{
                            background-color: $gray-7;
                            color: $white;
                            font-size: 18px;
                            text-transform: uppercase;
                            border: none;
                            padding-left: 30px;
                            padding-right: 30px;
                            width: 100%;
                            margin-top: 20px;

                            @include respond-above(desktop){                               
                                margin-top: 0;
                            }

                        }
                    }
                   
                }

            }

        }    
    }

}

