@import './hamburger';
@import './logo';

header{
    &.header{
        @include myanimated;
        @include wrapper(fixed, 100%);
        @include flex-container(column, wrap, center, center, center);
        z-index: 99;
        padding: 0;
        height: $nav-min-height;
        @include respond-above(tablet){
            height: $nav-height;
        }
        @include respond-below(tablet){
            background-color: $black;
        }
        .header_wrapper{
            @include wrapper(relative, 100%);
        }
        
    }

    &.collapsed{      
        background-color: $black;
        @include respond-above(tablet){
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

}

body{
    &.path-frontpage{
        header{
            &.header{
                @include wrapper(fixed, 100%);
            }
        }
    }
}