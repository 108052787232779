/*------------------------------------*\
    Page : Service
\*------------------------------------*/

.page {
  &--service {
    color: #545454;

    // Top Image - Style
    .topimg {
      .view-hero-image-layout {
        .views-field-field-hero-image {
          .Screen {
            @include respond-above(desktop) {
              img {
                min-height: 1080px;
              }
            }
            @include respond-below(desktop) {
              img {
                min-height: 667px;
              }
            }
          }
        }
      }

      .views-field-field-hero-image {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          background: rgba(0, 0, 0, 0.6);
        }
      }

      #block-fieldblock-node-page-default-field-header-paragraphs {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 50;

        .koda-block-container {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .paragraphs-item-koda-basic-paragraph,
          .paragraphs-items,
          .banner-heading-paragraph,
          .koda-paragraph-outer-wrapper,
          .koda-paragraph-inner-wrapper,
          .group-paragraph-info {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .group-paragraph-info {
            justify-content: center;
            align-items: center;
            color: #fff;
            margin: 0 auto;
            text-align: center;

            > .field-name-field-title,
            > .show-more-anchor {
              margin-top: auto;
            }

            > .field-name-field-title {
              padding-left: 1rem;
              padding-right: 1rem;
              margin-bottom: 2rem;
              h2 {
                font-size: 30px;
                font-weight: 600;
                margin: 0;
              }
            }

            > .field-name-field-desc {
              .field-item {
                display: flex;
                gap: 2rem;
                flex-direction: row;
              }
            }

            @include respond-below(mobile) {
              > .field-name-field-title {
                max-width: 70%;
              }
              > .field-name-field-desc {
                .field-item {
                  flex-direction: column;
                }
              }
            }

            @include respond-between(mobile, tablet) {
              max-width: 80%;
              > .field-name-field-title {
                margin-bottom: 6rem;
                h2 {
                  font-size: 35px;
                }
              }
            }

            @include respond-above(tablet) {
              max-width: 80%;
              > .field-name-field-title {
                margin-bottom: 6rem;
                h2 {
                  font-size: 65px;
                }
              }
            }

            @include respond-above(desktop) {
              max-width: 70%;
              > .field-name-field-title {
                h2 {
                  font-size: 96px;
                }
              }

              > .field-name-field-desc {
                .field-item {
                  gap: 3rem;
                }
              }
            }
          }
        }
      }

      .topimg--link {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        border-radius: 16px;
        text-decoration: none;
        color: #fff;
        font-size: 45px;
        font-weight: 500;
        min-width: 464px;
        min-height: 320px;

        &::before {
          content: 'Book a Service';
          font-size: inherit;
          order: 0;
          padding-bottom: 120px;
          margin-bottom: 20px;
          background: url('../images/icon-marker.svg') center bottom no-repeat;
          background-size: auto 94px;
        }

        &:hover {
          border-color: #de1a22;
        }

        @include respond-below(desktop) {
          font-size: 28px;
          min-width: auto;
          min-height: auto;
          &::before {
            padding-bottom: 60px;
            margin-bottom: 20px;
            font-size: inherit;
            background-size: auto 40px;
          }
        }

        @include respond-below(tablet) {
          font-size: 16px;
        }
      }
    }

    // MultiCol Paragraph- Modification
    .koda-multicol {
      .koda-header-wrapper {
        h2,
        h3 {
          font-size: 3.5rem;
          margin-bottom: 4rem;
        }

        @include respond-above(desktop) {
          h2,
          h3 {
            font-size: 6rem;
          }
        }
      }
    }

    // Fields
    .default-content {
      color: #545454;
      .field-name-field-title {
        h2 {
          color: #000;
          margin-bottom: 40px;
          &::after {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
