/*------------------------------------*\
  Testimonials - Component
\*------------------------------------*/
.testimonial-para-block {
    
    @extend .screenh100;
    text-align: center;
    .koda-paragraph-inner-wrapper {
        @extend .fluid-container;
        padding-top: 50px;
        padding-bottom: $grid-gutter-width;
        @include respond-above(tablet) {
            padding-top: $grid-gutter-width;
        }
    }
    .group-paragraph-info {
        @include wrapper(relative, 100%);
        .field-name-field-title,
        .field-name-field-title h2 {
            @include wrapper(relative, 100%);
        }
        .field-name-field-title h2 {
            margin: 0 0 em(8) 0;
            font-size: em(30);
            @include respond-above(desktop) {
                font-size: em(65);
            }
        }
        .field-name-field-desc {
            @include wrapper(relative, 100%);
            overflow: hidden;
            z-index: 10;
        }
    }
}

//:---------------------------------------------------------------------------------------------------------------------
.lucas-gtestimonials {
    position: relative;
    display: block;
    width: 80vw;
    z-index: 10;
    @include respond-above(desktop) {
        width: 100%;
    }
    //@include wrapper(relative, 100%);
}

//:---------------------------------------------------------------------------------------------------------------------
.google-testimonials {
    @extend .screenh100;
    @include wrapper(relative, 100%);
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    //display: none;
    .koda-block-container {
        position: relative;
        @include respond-above(tablet) {
            @include flex-container(column, wrap, center, center, center);
        }
    }
    text-align: center;
    a {
        z-index: 10 !important;
    }
    .block-title {
        @include wrapper(relative, 100%);
        text-align: center;
        color: $black;
    }
}