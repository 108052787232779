.banner-image-header{

    &.entity-paragraphs-item{
        position: absolute !important;
        width: 100%;
        z-index: 10;
        bottom: 0;
    }

    > .koda-paragraph-outer-wrapper{
        @include wrapper(relative, 100%);
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        > .koda-paragraph-inner-wrapper{
            position: relative;
            margin-bottom: 60px;
            @extend .container-fluid;
            @extend .cover-element;

            img{
                width: auto !important;
                margin: 0 auto;
            }

        }


    }

    

}