.compare-cars{
    position: relative;
    background: linear-gradient(360deg, rgba(196, 196, 196, 0.25) 2.86%, rgba(196, 196, 196, 0) 103.83%);

    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;

    @include respond-above(mobile){
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    > .koda-paragraph-inner-wrapper{

        .koda-header-wrapper {
            margin-bottom: $grid-gutter-width;
        }

    }

    .column{
        &:first-child{
            .views-exposed-form{
                .views-exposed-widget{
                    @include respond-below(tablet){
                        padding-right: em(10);
                        padding-left: 0;
                        .form-item-nid.form-type-select.form-group{
                            &::after{
                                right: 1em;
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(2){
            .views-exposed-form{
                .views-exposed-widget{
                    @include respond-below(tablet){
                        padding-left: em(10);
                        padding-right: 0;
                        .form-item-nid.form-type-select.form-group{
                            &::after{
                                right: 0;
                            }
                        }
                    }
                }
            } 
        }
    }



    
    @include respond-above(mobile){
        padding-bottom: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;

        .tab-content{
            padding-top: 60px;
        }

    }

    .paragraphs-items{

        @include flex-container(column, wrap, flex-start, stretch, flex-start);
        @include respond-above(mobile){
            flex-direction: row;
        }
        height: 100%;

        > .column{

            @include flex-container(column, wrap, center, stretch, center);
            height: 100%;
            //width: 100%;
            width: 50% !important;
            margin: 0 !important;
            
            @include respond-above(desktop){
                   
                &:not(:first-child){
                    border-left: 1px solid $gray-light-12;
                }    
            }

            @include respond-above(desktop){
                padding: 0 $grid-gutter-width;
            }

                       
            .field-collection-view{
                margin: 0;
                padding: 0;
            }

            .field-name-body,
            .field-name-title{
                h2{
                    color: $black-2;
                }
            }
           
            .field-name-title{
                @include wrapper(relative, 100%);
                margin-top: $grid-gutter-width/2;
                margin-bottom: $grid-gutter-width/2;

                h2{
                    @include wrapper(relative, 100%);
                    text-align: center;
                    margin: 0;
                    font-weight: 600;
                    font-size: 14px;
                    color: $black-2;
                    @include respond-above(mobile){
                        font-size: 35px;
                    }
                }
            }

            .entity-paragraphs-item{
                @include flex-container(column, wrap, center, stretch, center);
                height: 100%;
            }

            .field-name-field-car-range-image{
                img{
                    width: 100%;  
                    height: 210px;                
                    @include object-fit(contain);
                    @include respond-above(mobile){
                        height: 450px;
                    }
                }
            }


        }
    }
    
}