.region-footer{

    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: $grid-gutter-width;

    @include wrapper(relative, 100%);
    @include flex-container(column, wrap, center);
    @include respond-above(mobile){
        flex-direction: row;
        justify-content: space-between;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    a{
        color: $primary-color !important;
        &:hover{
            color: $white !important;
            text-decoration: underline;
        }
    }

    h4,
    h2.block-title {
        color: $white;
        font-size: 18px;
        margin-bottom: 20px;
    }

    p{
        margin-bottom: 0;
        @include flex-container(column, wrap, center);
        @include respond-above(mobile){
            flex-direction: row;
        }
        @include respond-below(mobile){
            text-align: center;
            a{
                @include wrapper(relative, 100%);
                text-align: center;
            }
        }
    }

    section{
        @include flex-container(row, wrap, center);       
        @include respond-below(mobile){
            margin-top: $grid-gutter-width/2;
        }
        flex: 1;

        &.footer-tc{
            @include respond-above(mobile){
                justify-content: flex-start !important;
            }
        }
        &.footer-powered-by{
            @include respond-above(mobile){
                justify-content: flex-end !important;
            }
        }
        &.footer-full{
            flex: 0 0 100%;
            width: 100%;
        }
        
    }

    

}