.page-here-is-your-perfect-mitsubishi-match {
  .page-title {
    .page-header {
      text-align: center;
      @include respond-above(tablet) {
        font-size: 45px;
        line-height: 1.4em;
      }
      &:after {
        display: none;
      }
    }
    margin-bottom: 0;
  }

  form {
    @include wrapper(relative, 100%);
    margin-top: $grid-gutter-width/2;

    > div {
      @include wrapper(relative, 100%);
      @include flex-container(column, wrap, flex-start, center);

      .btn {
        &.form-submit {
          @extend .btn-red;
          outline: none;
          min-width: 222px;
          margin-top: $grid-gutter-width;
        }
      }
    }
  }

  .lucas-quiz-summary {
    @include wrapper(relative, 100%);
    margin-top: $grid-gutter-width;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include respond-above(desktop) {
      padding-left: 0;
      padding-right: 0;
    }

    .match_1 {
      .field-name-field-home-page-slider-icon {
        @include wrapper(relative, 100%);
        .field-items,
        .field-item {
          @include wrapper(relative, 100%);
          @include flex-container(column, wrap, center, flex-start, center);
        }
        img {
          @include wrapper(relative, 100%);
          @include respond-above(desktop) {
            //height: 822px;
            @include object-fit(contain);
            object-position: center center;
            height: 30em;
          }
        }
      }
      @include respond-above(tablet) {
        margin-bottom: $grid-gutter-width;
      }
    }

    .match_2,
    .match_3 {
      @include flex-container(column, wrap, flex-start, center, center);
      @include respond-above(tablet) {
        img {
          height: 500px;
          width: 100%;
          object-fit: contain;
        }
        margin-bottom: $grid-gutter-width;
      }
    }

    h2 {
      font-size: 25px;
      font-weight: 400;
      color: $black-2;
      margin: 0;
      text-align: center;
      @include wrapper(relative, 100%);
      @include respond-above(tablet) {
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 40px;
      }
    }
  }
}
