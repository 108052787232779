.scroll-button-block{
    @include wrapper(absolute, 100%);
    @include flex-container(column, wrap, center, center, center);

    .block-content{
        text-align: center;
        @include wrapper(relative, 100%, 20px);
        @include flex-container(column, wrap, center, center, center);
    }
    
    a{          
        @include wrapper(relative, 66px, 66px);
        @include border-radius(100%);
        display: block;
        z-index: 10;
        margin-top: -20px;
        background: url('../images/icons/kz-icon-arrow-down.svg') center center no-repeat $primary-color;
        &:hover{
            background-color: $gray-0;
        }
          
    }
}