.page-used-cars {
  &.page-used-cars-search {
    #contentblock {
      > .container-fluid {
        width: 100%;
      }
    }
    #koda-lucas-used-cars-search-initial {
      margin-top: $grid-gutter-width/2;
      @include wrapper(relative, 100%);
      @include respond-below(mobile) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }
      > div {
        @include wrapper(relative, 100%);
        @include flex-container(column, wrap);
        @include respond-above(tablet) {
          flex-direction: row;
        }
        @include respond-below(tablet) {
          overflow-x: hidden;
        }
      }
      //:----------------------------------------------------------------------------------------------------------------------

      #used-cars-search-filter-wrapper {
        position: relative;
        width: 100%;

        @include myanimated;

        @include respond-above(wide) {
          width: 25%;
        }
        @include respond-between(tablet, wide) {
          width: 30%;
        }
        @include respond-above(tablet) {
          padding-left: em(20);
          padding-right: em(20);
        }
        // Mobile Scroll
        @include respond-below(tablet) {
          &.open {
            right: 0;
            overflow-y: scroll;
          }

          position: fixed;
          top: 0;
          right: 100%;
          padding: em(80) em(30);
          height: 100vh;
          z-index: 10;
          background-color: $white;

          .trigger {
            @include myanimated;
            position: fixed;
            z-index: 20;
            width: 30px;
            height: 100vh;
            left: 0;
            top: 0;
            cursor: pointer;
            background: url('../images/icons/VFS-label.png') center center
              no-repeat rgba(141, 141, 141, 0.2);
            -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
          }
        }

        .btn {
          margin-top: em(20);
          width: 100%;
          text-align: center;
          padding-top: em(8);
          padding-bottom: em(8);
        }
        .panel {
          border: none;
          margin-bottom: 0;
          border-radius: 0px;
          box-shadow: none;
        }
        //form-item
        .form-item {
          position: relative;
          width: 100%;
          display: flex;
          &.form-type-select {
            .form-control {
              background: url('../images/icon-caret.png') center right no-repeat
                transparent;
            }
          }
          .form-control {
            position: relative;
            font-size: em(12);
            border-radius: 0;
            box-shadow: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #d0d0d0;
            background: transparent;
            width: 75%;
            cursor: pointer;
            &.form-select {
              option {
                padding-left: 1rem;
                padding-right: 1rem;
              }
            }
          }
          .chosen-container-single .chosen-single {
            border-radius: 0;
            border: none;
            box-shadow: none;
            background: none !important;
            padding-left: 1rem;
            padding-right: 1rem;
            width: 100%;
            > div {
              > b {
                display: none;
              }
            }
          }
          .chosen-container {
            position: relative;
            border: none;
            box-shadow: none;
            padding: 0;
            display: flex !important;
            align-items: center;
            border-bottom: 1px solid #d0d0d0;
          }
          .control-label {
            font-size: em(12);
            font-weight: normal;
            display: flex;
            align-items: center;
            flex: 0 0 25%;
            margin-bottom: 0;
          }
        }
      }
      //:----------------------------------------------------------------------------------------------------------------------
      #used-cars-search-result-wrapper {
        position: relative;
        #edit-next {
          @extend .btn-red;
          margin-top: $grid-gutter-width;
          margin-bottom: $grid-gutter-width;
          position: relative;
          @include respond-below(tablet) {
            width: calc(100% - 2em);
            margin-left: 1em;
            margin-right: 1em;
          }
        }
        @include respond-above(wide) {
          width: 75%;
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
        }
        @include respond-between(tablet, wide) {
          width: 70%;
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
        }
        @include respond-between(mobile, tablet) {
          padding-left: em(20);
          padding-right: em(20);
        }
        #used-cars-search-result {
          @include wrapper(relative, 100%);
          .used-cars-search-result-page {
            @include flex-container(row, wrap, flex-start, stretch, center);
            position: relative;
            @include respond-below(tablet) {
              margin-top: 2em;
            }
          }
        }
      }
      //:----------------------------------------------------------------------------------------------------------------------
      #used-cars-search-next-wrapper {
        @include wrapper(relative, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-above(tablet) {
          align-items: flex-end;
          justify-content: flex-end;
        }
        .btn {
          @extend .btn-red;
        }
        #used-cars-search-next {
          display: flex;
          align-items: center;
          justify-content: center;
          @include respond-above(wide) {
            width: 75%;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
          }
          @include respond-between(tablet, wide) {
            width: 70%;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
          }
        }
      }
    }
  }
  //:----------------------------------------------------------------------------------------------------------------------
  .used-car-info-content {
    @include respond-above(mobile) {
      margin-top: $grid-gutter-width;
    }
    @include wrapper(relative, 100%);
    @include respond-between(mobile, wide) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
  .used-car-carousel-tiles {
    @include flex-container(row, wrap);
    .ca-left,
    .ca-right {
      @include wrapper(relative, 100%);
    }
    .ca-left {
      @include respond-above(desktop) {
        width: 66.7%;
        padding-left: 0;
        padding-right: em(20);
      }
    }
    .ca-right {
      @include respond-above(desktop) {
        width: 33.3%;
      }
    }
  }
  .sliderUC {
    @include wrapper(relative, 100%);
    margin-top: em(4);

    .slide {
      img {
        @include wrapper(relative, 100%, auto);
        @include object-fit(cover);
        @include respond-above(desktop) {
          height: 543px;
        }
      }
      @include wrapper(relative, 100%);
    }
    .slick-dots-container {
      position: relative;
      width: 100%;
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
    .slick-dots {
      @include respond-above(desktop) {
        display: none !important;
      }
      //background-color: orange;
      display: flex !important;
      flex-wrap: nowrap;
      overflow-x: scroll;
      align-items: center;
      margin: 2.5em 1.5em;
      padding: 0 0 2em 0;
      width: auto;
      li {
        max-width: 0 8px;
        &.slick-active {
          button {
            background-color: $primary-color !important;
          }
        }
        button {
          @include border-radius(100%);
        }
      }
    }
    .slick-arrow {
      position: absolute;
      float: left;
      height: 100%;
      width: 30%;
      z-index: 50;
      top: 0;
      border: 0;
      font-size: 0px;
      color: transparent;
      display: flex !important;
      align-items: center;
      text-align: center;
      justify-content: center;
      align-content: center;
      background-color: transparent;
      &::before {
        font-family: $font-family-icon-fa;
        color: $white;
        font-size: 80px;
        width: 100%;
        @include myanimated;
      }
      &:hover {
        &::before {
          color: $primary-color;
        }
      }
      &.slick-prev {
        &::before {
          content: '\f104';
        }
        left: 0;
        padding-left: $grid-gutter-width/2;
        text-align: left;
      }
      &.slick-next {
        &::before {
          content: '\f105';
        }
        right: 0;
        padding-right: $grid-gutter-width/2;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
  .sliderThumbNav {
    @include wrapper(relative, 100%, 550px);
    @include flex-container(row, wrap);
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    @include respond-below(desktop) {
      display: none;
    }
    > li {
      @include flex-container(column, wrap);
      position: relative;
      list-style: none;
      margin: 0;
      width: 33.3%;
      flex: 0 0 33.3%;
      padding: em(4);
      overflow: hidden;
      cursor: pointer;
      @include myanimated;
      &:hover {
        background-color: $primary-color;
        img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
      img {
        @include myanimated;
        @include wrapper(relative, 100%, 100px);
        @include object-fit(cover);
      }
    }
  }
  .uc-spec-info {
    @include wrapper(relative, 100%);
    @include respond-above(mobile) {
      padding-bottom: em(40);
    }
    .ucs-left {
      @include respond-above(desktop) {
        padding-right: $grid-gutter-width;
        .used-car-spec-list {
          border-right: 1px solid #d6d6d6;
        }
      }
    }
  }
  .uc-title {
    @include flex-container(row, wrap, space-between, flex-end, flex-end);
    .header-buttons {
      @include respond-below(tablet) {
        display: none;
      }
      @include respond-above(tablet) {
        margin-top: 20px;
        .adthis-wrapper {
          position: absolute;
          right: 0;
          z-index: 10;
          bottom: 0;
          margin-bottom: -100px;
        }
      }
      @include flex-container(column, wrap, center);
    }
    h1 {
      &.block-title {
        font-size: em(40);
        @include respond-above(wide) {
          font-size: em(70);
        }
        @include respond-between(tablet, wide) {
          font-size: em(50);
        }
      }
    }
    .block-title {
      display: flex;
      flex: 0 0 100%;
      @include respond-above(tablet) {
        display: flex;
        flex: 1;
      }
      @include respond-below(mobile) {
        font-size: em(30);
        margin-bottom: em(10);
        width: 100%;
      }
    }
  }
  .header-buttons {
    &.enq-mobile {
      @include respond-above(tablet) {
        display: none;
      }
    }
    margin-bottom: 0;
    @include flex-container(column, wrap, center, center, center);
  }
  .adthis-wrapper {
    @include wrapper(relative, 200px, 100px);
  }
  .used-car-spec-list {
    @include wrapper(relative, 100%);
    margin: 0;
    padding: 0;
    li {
      @include wrapper(relative, 100%);
      @include flex-container(row, wrap);
      margin: 0 0 em(12) 0;
      padding: 0;
      list-style: none;
      font-size: rem(20);
      color: $black;
      label {
        @include flex-container(row, wrap);
        @include wrapper(relative, 50%);
        @include respond-above(mobile) {
          width: 200px;
        }
      }
      h5 {
        margin: 0;
        padding: 0;
        flex: 0 0 auto;
        font-size: em(20);
        color: $primary-color;

        .original-price {
          text-decoration: line-through;
          font-size: 15px;
          color: gray;
        }
      }
      p {
        display: flex;
        flex: 1;
        margin: 0;
      }
      img.rate-stars {
        height: 25px;
      }
    }
  }
  .uc-spec-info,
  .uc-title-wrapper,
  .uc-spec-content {
    @include wrapper(relative, 100%);
    @include respond-below(mobile) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
  .uc-spec-content {
    font-size: rem(20);
    h3 {
      font-size: em(20);
      color: $black;
    }
    .demo-to-your-door {
      margin-bottom: 3rem;
    }
  }
  //:----------------------------------------------------------------------------------------------------------------------
}

.ucars-listing-status {
  display: flex;
  width: 100%;
  margin-top: 2px;
  padding: 1.2rem;
  justify-content: center;
  > label,
  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: normal;
    margin: 0 4px;
    line-height: 1.3;
    text-transform: uppercase;
    @include myanimated;
    @include border-radius(4px);
  }
  > label {
    background-color: $primary-color;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    padding: 2px 1rem;
  }
  > a {
    padding: 4px 1rem;
    font-size: 12px;
    background-color: #000;
    color: #fff;
    &:hover {
      background-color: lighten(#000, 25%);
      color: #fff;
    }
  }
  @include respond-above(sm) {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    width: calc(100% - 2.5rem);
    padding: 2rem;
    top: 0;
    margin-top: 0;
    left: 0;
    z-index: 55;
    > label,
    > a {
      line-height: 1.5;
    }
    > a {
      font-size: 16px;
      padding: 6px 20px;
      @include border-radius(53px);
      &:hover {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
      }
    }
    > label {
      margin-right: auto;
      font-size: 25px;
      padding: 0 20px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
      @include border-radius(4px);
    }
  }
}
