// Global CSS
.user-logged-in {
  .koda-fullwidth {
    nav.tabs {
      @extend .container-fluid;
      margin-top: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width/2;
    }
    .alert {
      margin: $grid-gutter-width/2;
    }
  }
}

.alert {
  font-size: 18px;
}

.entity-paragraphs-item {
  .block-easy-breadcrumb {
    @include respond-between(tablet, wide) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
}

.block-easy-breadcrumb {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 14px;
  @include respond-below(mobile) {
    display: none !important;
  }
}

#contentblock {
  //padding-top: $v-spacing*2;
  .content-col {
    min-height: 80vh;
  }
}

.content-wrap {
  @include wrapper(relative, 100%);
  float: left;
}

//:-------------------------------------------------------------------------------------------------------------------------
.img-gradient-cover {
  &::before {
    content: '';
    @include wrapper(absolute, 100%, 190px);
    top: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 14.24%
    );
  }
  &::after {
    content: '';
    @include wrapper(absolute, 100%, 285px);
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.content-wrapper {
  @include flex-container(column, wrap, flex-start, stretch, center);
  @include respond-above(mobile) {
    width: auto;
    flex: 1;
  }
  padding: $grid-gutter-width/2;
  position: relative;
  @include respond-above(tablet) {
    padding: 40px;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.panel-body {
  padding: 0;
}

//:-------------------------------------------------------------------------------------------------------------------------
.page-title {
  position: relative;
  margin: 0 auto 20px auto;
  @include respond-below(wide) {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-header {
    @include wrapper(relative, 100%);
    font-size: 40px;
    @extend .title-line;
    @include respond-above(mobile) {
      font-size: 60px;
    }
    @include respond-below(mobile) {
      &::after {
        display: none !important;
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.page-extra-title {
  position: relative;
  margin: 0 auto;
  @include respond-below(wide) {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
  h2,
  .page-header {
    @include wrapper(relative, 100%);
    font-size: em(30);
    @extend .title-line;
    @include respond-above(mobile) {
      font-size: em(50);
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.title-line {
  &:after {
    content: '';
    display: block;
    position: relative;
    width: 223px;
    height: 4px;
    margin-top: 10px;
    background-color: $primary-color;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
// Make Page Type Flex - Row , use only if needed
.page-type-flex {
  .region-content {
    @include flex-container(column, wrap);
    @include respond-above(mobile) {
      position: relative;
      @include flex-container(row, wrap, center, flex-start, center);
    }
    .block-system {
      position: relative;
      width: 100%;
      display: block;
      //flex: 0 0 100%;
    }
    padding-bottom: $grid-gutter-width;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.stretch-fullwidth-content {
  .content {
    > .container-fluid,
    > .container,
    .content-col {
      @include wrapper(relative, 100%);
    }
  }
  .config {
    margin-top: $grid-gutter-width/2;
    @extend .fluid-container;
  }
  .block-koda-node-clone {
    @extend .fluid-container;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.content {
  > .container-fluid,
  > .container,
  .content-col {
    //@include wrapper(relative, 100%);
    @include respond-above(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.node {
  .field-group-htabs-wrapper {
    @extend .custom-htabs;
    @extend .section-fluid;
    overflow-x: hidden;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.block-basic-content {
  @include wrapper(relative, 100%);
  margin-bottom: $grid-gutter-width;
  float: left;
  .koda-block-container {
    @include wrapper(relative, 100%);
    .koda-header-container {
      @include wrapper(relative, 100%);
      .block-title {
        font-size: 40px;
      }
    }
  }
  p {
    font-size: 20px;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------

.inner-section-content {
  @extend .section-fluid;
  padding-top: 3rem;
  padding-bottom: 3rem;

  > .koda-paragraph-outer-wrapper {
    .group-paragraph-info {
      > .field-name-field-title {
        > h2 {
          margin-top: 0;
          margin-bottom: 2rem;
          display: flex;
          flex-direction: column;
          color: #000;
          &::after {
            content: '';
            width: 85px;
            height: 1px;
            margin-top: 2rem;
            border-bottom: 1px solid $primary-color;
          }
        }
      }
    }
  }

  /** Responsive **/

  @include respond-above(tablet) {
    &.extended {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    &:not(.extended) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    > .koda-paragraph-outer-wrapper {
      .group-paragraph-info {
        > .field-name-field-title {
          > h2 {
            font-size: 6rem;
          }
        }
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.section-fluid {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  @include respond-above(wide) {
    padding-left: 0;
    padding-right: 0;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.fluid-container {
  @include respond-above(wide) {
    padding-left: 0;
    padding-right: 0;
    width: $screen-xl !important;
  }
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
}

//:-------------------------------------------------------------------------------------------------------------------------
.enquire-btn {
  @include respond-above(mobile) {
    float: right;
  }
  margin: 0 auto;
  color: $primary-color;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  @include flex-container(column, wrap, center, center, center);
  &::before {
    content: '';
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    background: url('../images/icons/icon-mail.svg') center center no-repeat;
    border: 4px solid rgba(222, 41, 16, 0.3);
    @include border-radius(100%);
    @include myanimated;
  }
  &:hover {
    &::before {
      border: 4px solid rgba(222, 41, 16, 0.9);
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.anchor {
  font-size: 0px;
  position: absolute;
  top: 0;
  left: 0;
  //height: 10px;
  //width: 10px;
  //background-color: green;
  display: inline-block;
  z-index: 10;
}

.tab-anchor {
  .anchor {
    top: -250px;
    @include respond-above(mobile) {
      top: -220px;
    }
  }
}

.hp-meet-the-team {
  .koda-paragraph-outer-wrapper {
    .koda-paragraph-inner-wrapper {
      @include respond-below(mobile) {
        display: flex;
        flex-direction: column;
      }
    }
    .anchor-link-target-wrapper {
      @include respond-below(desktop) {
        display: block;
      }
      display: none;
      order: 1;
      height: 20px;
      top: auto;
      bottom: -8vh;
      .anchor-taget-btn {
        margin-top: 0;
        a {
          &:link,
          &:active,
          &:visited {
            &::after {
              border-color: rgba(255, 255, 255, 0.35) transparent transparent
                transparent;
              //_border-color: rgba(255, 255, 255, 0.35) #000000 #000000 #000000;
              //_filter: progid: DXImageTransform.Microsoft.Chroma(color='#000000');
            }
          }
        }
      }
    }
    .group-paragraph-info {
      order: 0;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.build-select-nav {
  @include wrapper(relative, 100%);
  //@include flex-container(column,wrap,center,center,center);
  @include respond-above(mobile) {
    flex-direction: row;
  }
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $grid-gutter-width/2;
  padding-left: 18px;
  padding-right: 18px;

  label {
    font-size: 12px;
    color: $black;
    font-weight: normal;
    padding: 10px;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include respond-above(md) {
      justify-content: center;
    }
    @include respond-between(sm, md) {
      > li {
        width: 25%;
        margin-left: 0;
        margin-right: 0;
      }
    }
    @include respond-below(sm) {
      > li {
        width: 33.3%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    > li {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      float: none;
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
      padding-bottom: 40px;
      margin-bottom: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .color-name {
        position: absolute;
        padding: 0 !important;
        top: 50px;
        left: 0;
        width: 100%;
        opacity: 0;
        font-size: 14px !important;
        @include myanimated;
      }

      &:hover {
        .color-name {
          opacity: 1;
        }
      }

      &.active {
        > a {
          span {
            &::before {
              @include wrapper(absolute, 100%, 100%);
              top: 0;
              left: 0;
              content: '';
              background: url('../images/icons/icon-check-w.svg') center center
                no-repeat;
            }
          }
        }

        .color-name {
          opacity: 1;
        }
      }

      > a {
        position: relative;
        width: 45px;
        height: 45px;
        padding: 0;
        background-color: transparent;
        @include border-radius(100%);
        overflow: hidden;
        span {
          @include wrapper(relative, 45px, 45px);
          @include flex-container(row, wrap, center, center, center);
          color: transparent;
        }
      }
    }
  }
}

.build-select-content {
  @include wrapper(relative, 100%);
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  .tab-pane {
    text-align: center;
    img {
      margin: 0 auto;
    }
    h3 {
      font-size: 30px;
      color: #272727;
      font-weight: normal;
      margin-top: $grid-gutter-width;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.block-full {
  @include wrapper(relative, 100%);
}

//:-------------------------------------------------------------------------------------------------------------------------
.center-content {
  text-align: center;
  * {
    margin-left: auto;
    margin-right: auto;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.with-list-check {
  // Can be applied to blocks
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 15px;
      padding-left: 60px;
      &::before {
        content: '';
        @include wrapper(absolute, 35px, 30px);
        top: 0;
        left: 0;
        background: url('../images/icons/icon-check-g.svg') 0 0 no-repeat;
      }
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.with-title-line {
  h2 {
    @extend .title-line;
    margin-bottom: 25px;
    //margin-bottom: em(12);
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
// Covers the element with 100% absolute
.cover-element {
  &::before {
    content: '';
    @include wrapper(absolute, 100%, 100%);
    z-index: 10;
    bottom: 0;
    left: 0;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.border-line-top {
  border-top: 1px solid $gray-light-11;
}

.border-bottom-top {
  border-bottom: 1px solid $gray-light-11;
}

//:-------------------------------------------------------------------------------------------------------------------------
.enquire-anchor {
  position: absolute;
  margin-top: -300px; // So that it will have space on top
  top: 0;
  left: 0;
  height: 2px;
  width: 2px;
  display: block;
  font-size: 0;
  color: transparent;
}

//:-------------------------------------------------------------------------------------------------------------------------
.book-a-service {
  iframe {
    @include wrapper(relative, 100%);
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
//:-------------------------------------------------------------------------------------------------------------------------
.content-center {
  * {
    text-align: center;
  }
}

.basic-content {
  * {
    @include wrapper(relative, 100%);
  }
  h2 {
    font-size: 100px;
    margin-top: 0;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.demo-to-your-door {
  @include wrapper(relative, 100%);
  .koda-block-container {
    @include wrapper(relative, 100%);
    @include flex-container(column, wrap, center, center, center);
    @include respond-above(desktop) {
      flex-direction: row;
    }
    .cleft,
    .cright {
      width: 100%;
    }
    .cleft {
      @include respond-above(desktop) {
        padding-right: em(40);
        width: 40%;
        img {
          float: right;
          margin-bottom: 0 !important;
        }
      }
      img {
        margin: 0 auto em(30) auto;
      }
    }
    .cright {
      @include respond-above(desktop) {
        width: 60%;
      }
    }
  }
  background-color: #f7f7f7;
  color: $black;
  padding: em(30) em(20);
  p {
    margin: 0;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.screenh100 {
  height: 100%;
  height: 100vh;
  min-height: 640px;
  @include respond-below(tablet) {
    height: calc(var(--vh, 1vh) * 100);
  }
  @include respond-above(mobile) {
    min-height: 768px; //Smallest desktop screen
  }
}

.vert-h100 {
  @extend .screenh100;
  @include flex-container(wrap, center, center, center);
}

//:-------------------------------------------------------------------------------------------------------------------------
.front {
  &:not(.logged-in) {
    .config {
      display: none;
    }
  }
  .topimg {
    // Remove basic page default image
    background-image: none;
    min-height: auto;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.group-desc-extra {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .ui-accordion-header {
    display: flex;
    order: 1;
    border-radius: 0;
    padding: 0 !important;
    background: none;
    border: none;
    margin-bottom: 15px;
    color: $primary-color;
    span {
      display: none;
    }
    &.field-group-format-toggler {
      a {
        color: $primary-color !important;
        font-weight: 600;
        font-size: 20px;
      }
    }
    a {
      @extend .notransition;
    }
    &.ui-state-active {
      @extend .notransition;
      a {
        font-size: 0px !important;
        width: 0px !important;
        color: transparent;
        &::before {
          content: 'Show Less';
          font-size: 20px;
          color: $primary-color;
          font-weight: 600;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
  .ui-state-default {
    background: none;
  }
  .ui-accordion-content {
    padding: 0 !important;
  }
  .field-name-field-description-extra {
    color: inherit;
    * {
      color: inherit !important;
    }
  }
  .ui-widget-content {
    display: flex;
    order: 0;
    background: none;
    border: none;
    color: inherit !important;
    font-size: inherit;
    font-family: $font-family-base !important;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

//:-------------------------------------------------------------------------------------------------------------------------
// mobile 640, tablet 768, desktop 1060
.mobile-only {
  // Above 640 hide
  @include respond-above(mobile) {
    display: none;
  }
}

.tablet-only {
  // Below 768 hide
  @include respond-below(mobile) {
    display: none;
  }
  // Above 1060 hide
  @include respond-above(desktop) {
    display: none;
  }
}

.desktop-only {
  // Below 1060 hide
  @include respond-below(desktop) {
    display: none;
  }
}

.wide-only {
  // Below 1480 hide
  @include respond-below(wide) {
    display: none;
  }
}

.tablet-desktop-wide-only {
  // Below 768 hide
  @include respond-below(mobile) {
    display: none;
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.terms-cond {
  @extend .fluid-container;
  h2 {
    @include wrapper(relative, 100%);
    font-size: em(35);
    margin-top: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/2;
    color: $black;
    @include respond-above(wide) {
      font-size: 60px;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
// Thank you page
.page-node-468 {
  .config {
    .messages.status {
      display: none;
    }
  }
  .field-name-body {
    padding-top: $grid-gutter-width;
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $black;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.footer-tc {
  &::after {
    @include respond-above(mobile) {
      content: '|';
      color: $white;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

//:-------------------------------------------------------------------------------------------------------------------------
.modal-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(222, 26, 34, 0.9);
  color: $white;
  border: none;
  font-weight: normal;
  z-index: 50;
  padding-top: 15px;
  padding-bottom: 15px;
  label {
    display: flex;
    font-weight: normal;
    margin-bottom: 0;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    a {
      color: $white;
      &:hover {
        .fa {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
        }
      }
    }
    .fa {
      color: $white;
      margin: 0 4px;
      @include myanimated;
      &::before {
        font-size: 40px !important;
      }
      &.fa-edge {
        &::before {
          content: url('../images/icons/icon-ms-edge.svg');
        }
      }
    }
  }
  .close {
    display: flex;
    float: none;
    font-weight: normal;
    right: -4px;
    top: 2px;
    span {
      font-weight: 200;
    }
  }
}
//:---------------------------------------------------------------------------------------------------------------------
#colorbox {
  @include respond-below(mobile) {
    width: 100% !important;
    left: 0 !important;
    #cboxWrapper,
    #cboxLoadedContent {
      width: 90% !important;
      margin-left: 5%;
      margin-right: 5%;
    }
    #cboxContent {
      width: 92% !important;
      //width: 92% !important;
    }
  }
  #cboxCurrent {
    bottom: 2px;
    font-size: 14px;
  }
}

//:---------------------------------------------------------------------------------------------------------------------
.color-selection {
  line-height: none;
  display: flex;
  > li {
    width: 50px;
    height: 50px;
    margin-left: 2px;
  }
}
//:---------------------------------------------------------------------------------------------------------------------
//: Global CSS Overwrite

.font-color-white {
  color: #fff !important;
  * {
    color: #fff !important;
  }
}

.font-color-red {
  color: $primary-color !important;
  * {
    color: $primary-color !important;
  }
}

.font-color-black {
  color: #000 !important;
  * {
    color: #000 !important;
  }
}
//:---------------------------------------------------------------------------------------------------------------------
//: This gives padding to parag using fluid container
.fluid-padding {
  @include respond-between(sm, max) {
    padding-left: 2rem;
    padding-right: 2rem;
    > .koda-paragraph-inner-wrapper {
      padding: 0 !important;
    }
  }
}
//:---------------------------------------------------------------------------------------------------------------------

.has-readmore {
  overflow-y: hidden;
  transition: height 0.4s linear;
  height: 100%;
}

//:---------------------------------------------------------------------------------------------------------------------

// Global Paragraph Fix

.paragraphs-item-koda-image-desc {
  &.two-col-img-text {
    @include respond-above(tablet) {
      &.img70-text50,
      &.text50-img70 {
        .par-img.group-left {
          .field-name-field-image-desc-p-image {
            display: flex;
            align-items: center;
            height: 690px; // Set default height
          }
        }
      }
    }
  }
}

//:---------------------------------------------------------------------------------------------------------------------

// Paragraph as Button

.paragraph-as-button {
  .field-name-paragraph-link-url {
    .field-item {
      display: flex;
      width: 100%;
      margin: 2rem 0;
    }
    .field-link {
      @extend .btn-red;
      justify-content: center;
    }
  }
}
