.container-fluid {
  @include respond-above(wide) {
    width: $screen-xl;
  }
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.fullwidth-content {
  .container-fluid {
    @include respond-above(wide) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.content {
  .content-col {
    min-height: 50vh;
    @include respond-below(tablet) {
      padding-left: 0;
      padding-right: 0;
      //width: 100vw;
    }
  }
}
