section{
    &.is-panelized{

        .main-container{
            &.container-fluid{
                width: 100%;
            }
        }

        .region-content{
            padding-top: 0;
            padding-bottom: 0;
        }

    }
}