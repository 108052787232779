.view-car-accessory-popup {
  //@extend .slick-dots-gray; // Slick dots
  @include wrapper(relative, 100%);
  display: block;

  @include respond-between(mobile, wide) {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  .field-content,
  .views-field {
    @include wrapper(relative, 100%);
  }

  .view-content {
    .views-row {
      margin-bottom: em(40) !important;
    }

    .slick-list {
      @include wrapper(relative, 100%);
      display: block;
      .slick-track {
        @include wrapper(relative, 100%);
        display: block;
      }
    }
  }

  .car-inlin-acc-icon-img {
    @include wrapper(relative, 100%);
    img {
      @include wrapper(relative, 100%, 400px);
      @include object-fit(cover);
    }
  }

  .cboxElement {
    @include imgZoomHover;
  }

  .views-field-field-accessory-image {
    .car-inlin-acc-popup-info {
      @include wrapper(absolute, 100%, 100%);
      @include flex-container(column, wrap, flex-end, center, center);
      z-index: 10;
      top: 0;
      left: 0;
      padding: $grid-gutter-width/2;

      &::before {
        content: '';
        @include wrapper(absolute, 100%, 50%);
        bottom: 0;
        left: 0;
        z-index: 5;
        background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
      }
      //background-color: $black-a2;

      .car-inlin-acc-icon-title {
        @include wrapper(relative, 100%);
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: $white;
        z-index: 10;
        //margin-bottom: $grid-gutter-width/2;
        //padding: 0 $grid-gutter-width/2;

        h2 {
          @include wrapper(relative, 100%);
          @include text-shadow;
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          color: $white;
          margin: 0;
          padding: 0;
        }
      }
      .car-inlin-acc-icon-price {
        color: #fff;
        font-size: 2.5rem;
        z-index: 10;
        h4 {
          margin: 4px 0 0 0;
          font-weight: normal;
        }
      }
    }
  }
  .view-footer {
    text-align: center;
    margin-bottom: $grid-gutter-width + 20px;

    a {
      margin: 0 auto;
    }
  }
}
