/* Global Styles */

html {
  scroll-behavior: smooth;
  @include wrapper(relative, 100%);
  height: 100%;
  body {
    @include wrapper(relative, 100%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: 100%;
    font-display: swap;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      color: inherit;
      //color: $heading-color;
      font-weight: $headings-font-family;
      font-display: swap;
    }
    h1,
    h2 {
      &.block-title {
        color: $heading-color;
        font-size: rem(40);
        @include respond-above(tablet) {
          font-size: rem(60);
        }
      }
    }
    .global-title {
      color: $heading-color;
      //font-size: 50px;
      font-size: rem(40);
      text-align: center;
      margin: 0;
      @include respond-above(tablet) {
        font-size: 100px;
      }
      @include respond-below(tablet) {
        font-size: rem-calc(50);
        word-break: break-word;
      }
    }
    // Inside the content only
    h1,
    h2 {
      //font-size: 60px;
      color: inherit;
      font-weight: 600;
      @include respond-above(tablet) {
        font-size: 85px;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 35px;
      margin-top: 0;
      //color: inherit;
      @include respond-above(tablet) {
        font-size: 45px;
      }
    }
    a {
      color: $brand-primary;
      @include myanimated;
      &:active,
      &:visited,
      &:hover {
        text-decoration: none;
      }
    }
    img {
      max-width: 100%;
      @extend .img-responsive;
      &.img-right {
        margin-left: $grid-gutter-width/2;
      }
      &.img-left {
        margin-right: $grid-gutter-width/2;
      }
    }
    a,
    li,
    span,
    div,
    button,
    input,
    select,
    textarea {
      &:focus {
        outline: none;
      }
    }
    select {
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    .page-header {
      margin: 0;
      padding: 0;
      border: none;
      color: $heading-color;
    }

    @include respond-below(tablet) {
      .field-item {
        p,
        ul li,
        ol li {
          font-size: 1.6rem;
        }
      }
      .sidebar {
        a,
        span {
          font-size: 1.6rem;
        }
      }
    }
  }
}
