//:---------------------------------------------------------------------------------------------------------------------

.hp-carmodel-carousel{

    .view-content{
        .slick{
            @extend .slick-arrow-style;
            .slick__arrow{
                .slick-arrow{                    
                    @include respond-above(tablet){
                        width: 100px;
                    }
                }
            }
        }
    }

    .slick-slide{
        height: 100%;
    }

    .slide__content{
        @include flex-container(column, wrap, center, stretch, center);
        height: 100%;
    }

    .node-car{
        &.view-mode-teaser{
            position: relative;
            height: 100%;
            flex: 1;
            @include flex-container(column, wrap, flex-start, stretch, center);

            .block-title{
                background-color: $black-2;
            }

            .field-name-field-home-page-slider-icon,
            .field-type-image{
                @include wrapper(relative, 100%);
                padding: 0 $grid-gutter-width;

                .field-items,
                .field-item{
                    @include wrapper(relative, 100%);
                }
                
                @include wrapper(relative, 100%);
                @include flex-container(column, wrap, flex-start, stretch, center);
                
                img{
                    @include object-fit(contain);
                    position: relative;
                    width: 100%;
                    max-width: 100%;
                    height: 30vh;
                    margin: 0 auto;
                    @include respond-above(desktop){
                        height: 40vh;
                    }
                   
                }

            }

            .field-name-title{
                @include wrapper(relative, 100%);
                margin-top: $grid-gutter-width/2;

                .field-items,
                .field-item{
                    @include wrapper(relative, 100%);
                }

                h2{
                    margin: 0 !important;
                    //font-size: 40px;
                    font-size: 3.8vh;
                    text-align: center;
                    color: $black-2;
                   
                    @include wrapper(relative, 100%);

                    a{
                        color: $black-2;
                        &:hover{
                            color: $primary-color;
                        }
                    }
            
                }
            }


            .field-type-text-with-summary{
                position: relative;
                width: 100%;
                color: $black;
                
                margin-top: $grid-gutter-width/2;
                padding-left: $grid-gutter-width;
                padding-right: $grid-gutter-width;

                p{
                    font-size: 0.8em;
                    //font-size: 1.8vh;
                    @include respond-below(tablet){
                        font-size: 0.6em;
                    }
                }

                @include respond-above(wide){
                    padding-left: 18%;
                    padding-right: 18%; 
                } 
            }
     

            .field-name-node-link{
                @include wrapper(relative, 100%);

                .field-items,
                .field-item{
                    @include wrapper(relative, 100%);
                    text-align: center;
                }

                .field-item{
                    padding: 10px 0;
                }

                a{
                    text-align: center;
                    margin: 0 auto;
                    @extend .btn-red;
                }
            }

           
        }
    }
}

//:---------------------------------------------------------------------------------------------------------------------