.paragraph{

    &.paragraph--type-buttons,
    &.paragraph--type--buttons{ 

        border-spacing: 2px;
        border-style: solid;
        @include myanimated;
        @include flex-container(column, wrap, center, center, center);

        &:hover{
            @include myanimated;
        }

        &.btn-primary{
            background-color: $primary-color;         
            a{
                color: $white;
            }
            &:hover{
                border-color: $link-hover-color;
                background-color: $link-hover-color;
                color: $primary-color;
            }
        }

        &.btn-outline-primary{
            border-color: $primary-color;
            color: $primary-color;  
            a{
                color: $primary-color;  
            }         
        }
    
        &.btn-outline-default{
            border-color: $white;
            color: $white;
            &:hover{
                border-color: $primary-color;
                background-color: $primary-color;
                color: $primary-color;
            }
        }
    
        &.btn-default,
        &.btn-primary,
        &.btn-outline-primary,
        &.btn-secondary,
        &.btn-outline-primary{
    
            @extend .btn;
            border-width: 2px;
            border-style: solid;  
            
            a{
                color: inherit;
               
            }
    
            &.w-arrow{           
                a{
                    &:after{
                        content: url("../images/icon-btn-arrow.svg");
                        margin-left: 15px;
                        display: inline-block;
                        min-width: 34px;
                    }
                }
            }
            &.w-arrow-2{           
                a{
                    &:after{
                        content: "-";
                        @include wrapper(relative,10px,10px);
                        font-family: $font-family-icon-fa;
                    }
                }
            }
        }
    }
    

}
